import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { expirienceListReducer, expirienceDetailsReducer, expirienceCreateReducer, expirienceDeleteReducer, expirienceUpdateReducer } from './reducers/expirienceReducers';
import { educationListReducer, educationDetailsReducer, educationCreateReducer, educationDeleteReducer, educationUpdateReducer } from './reducers/educationReducers';
import { articleListReducers, articleDetailsReducers, articleDeleteReducer, articleCreateReducer, articleUpdateReducer } from './reducers/articleReducers'
import { recipeListReducers, recipeDetailsReducers, recipeDeleteReducer, recipeCreateReducer, recipeUpdateReducer, recipeCategoryReducer } from './reducers/recipeReducers'
import { holisticListReducers, holisticDetailsReducers, holisticDeleteReducer, holisticCreateReducer, holisticUpdateReducer } from './reducers/holisticReducers'
import { serviceListReducers, serviceDetailsReducers, serviceDeleteReducer, serviceCreateReducer, serviceUpdateReducer } from './reducers/serviceReducers'
import { contactListReducer, contactDetailsReducer, contactCreateReducer, contactDeleteReducer, contactUpdateReducer } from './reducers/contactReducers';
import { filePDFListReducers, filePDFDetailsReducers, filePDFDeleteReducer, filePDFCreateReducer, filePDFUpdateReducer } from './reducers/filePDFReducers'
import { subscribeListReducer, subscribeDetailsReducer, subscribeCreateReducer, subscribeDeleteReducer, subscribeUpdateReducer } from './reducers/subscribeReducers';
import { userLoginReducer, userRegisterReducer, userDetailsReducer, userUpdateProfileReducer, userListReducer, userDeleteReducer, userUpdateReducer } from './reducers/userReducers'
import { foroListReducers, foroDetailsReducers, foroDetailCreateReducer, foroCreateReducer, foroDeleteReducer, foroUpdateReducer } from './reducers/foroReducers';
import { profileListReducer, profileDetailsReducer, profileCreateReducer, profileDeleteReducer, profileUpdateReducer, profileListMyReducer } from './reducers/profileReducers';
import { cartReducer } from './reducers/cartReducers';
import { productListReducer, productDetailsReducer, productDeleteReducer, productCreateReducer, productUpdateReducer, productReviewCreateReducer, productTopRatedReducer, productCategoryReducer, reviewListReducer, productsAllListReducer } from './reducers/productReducers'
import { orderCreateReducer, orderDetailsReducer, orderPayReducer, orderListMyReducer , orderListReducer, orderDeliverReducer} from './reducers/orderReducers'
import { discountListReducer, discountDetailsReducer, discountCreateReducer, discountDeleteReducer, discountUpdateReducer } from './reducers/discountReducers';
import { podcastListReducers, podcastDetailsReducers, podcastCreateReducer, podcastDeleteReducer, podcastUpdateReducer } from './reducers/podcastReducers';


const reducer = combineReducers({
    expirienceList: expirienceListReducer,
    expirienceDetails: expirienceDetailsReducer,
    expirienceDelete: expirienceDeleteReducer,
    expirienceCreate: expirienceCreateReducer,
    expirienceUpdate: expirienceUpdateReducer,
    educationList: educationListReducer,
    educationDetails: educationDetailsReducer,
    educationDelete: educationDeleteReducer,
    educationCreate: educationCreateReducer,
    educationUpdate: educationUpdateReducer,
    articleList: articleListReducers,
    articleDetails: articleDetailsReducers,
    articleDelete: articleDeleteReducer,
    articleCreate: articleCreateReducer,
    articleUpdate: articleUpdateReducer,
    recipeList: recipeListReducers,
    recipeDetails: recipeDetailsReducers,
    recipeDelete: recipeDeleteReducer,
    recipeCreate: recipeCreateReducer,
    recipeUpdate: recipeUpdateReducer,
    recipeCategory: recipeCategoryReducer,
    holisticList: holisticListReducers,
    holisticDetails: holisticDetailsReducers,
    holisticDelete: holisticDeleteReducer,
    holisticCreate: holisticCreateReducer,
    holisticUpdate: holisticUpdateReducer,
    serviceList: serviceListReducers,
    serviceDetails: serviceDetailsReducers,
    serviceDelete: serviceDeleteReducer,
    serviceCreate: serviceCreateReducer,
    serviceUpdate: serviceUpdateReducer,
    filePDFeList: filePDFListReducers,
    filePDFeDetails: filePDFDetailsReducers,
    filePDFeDelete: filePDFDeleteReducer,
    filePDFeCreate: filePDFCreateReducer,
    filePDFeUpdate: filePDFUpdateReducer,          
    contactList: contactListReducer,
    contactDetails: contactDetailsReducer,
    contactDelete: contactDeleteReducer,
    contactCreate: contactCreateReducer,
    contactUpdate: contactUpdateReducer,
    subscribeList: subscribeListReducer,
    subscribeDetails: subscribeDetailsReducer,
    subscribeDelete: subscribeDeleteReducer,
    subscribeCreate: subscribeCreateReducer,
    subscribeUpdate: subscribeUpdateReducer,    
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    foroList: foroListReducers,
    foroDetails: foroDetailsReducers,
    foroDetailCreate: foroDetailCreateReducer,
    foroDelete: foroDeleteReducer,
    foroCreate: foroCreateReducer,
    foroUpdate: foroUpdateReducer,
    profileList: profileListReducer,    
    profileDetails: profileDetailsReducer,
    profileDelete: profileDeleteReducer,
    profileCreate: profileCreateReducer,
    profileUpdate: profileUpdateReducer,
    profileListMy: profileListMyReducer,
    podcastList: podcastListReducers,
    podcastDetails: podcastDetailsReducers,
    podcastDelete: podcastDeleteReducer,
    podcastCreate: podcastCreateReducer,
    podcastUpdate: podcastUpdateReducer,    
    productList: productListReducer,
    productAll: productsAllListReducer,
    productDetails: productDetailsReducer,
    productDelete: productDeleteReducer,
    productCreate: productCreateReducer,
    productUpdate: productUpdateReducer,
    productReviewCreate: productReviewCreateReducer,
    productTopRated: productTopRatedReducer,
    productCategory: productCategoryReducer,
    reviewsList: reviewListReducer,
    cart: cartReducer,
    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderDeliver: orderDeliverReducer,
    orderListMy: orderListMyReducer,
    orderList: orderListReducer,
    discountList: discountListReducer,
    discountDetails: discountDetailsReducer,
    discountDelete: discountDeleteReducer,
    discountCreate: discountCreateReducer,
    discountUpdate: discountUpdateReducer,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
        JSON.parse(localStorage.getItem('userInfo')) : null

const cartItemsFromStorage = localStorage.getItem('cartItems') ?
        JSON.parse(localStorage.getItem('cartItems')) : []

const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ?
        JSON.parse(localStorage.getItem('shippingAddress')) : {}
        
const invoiceDataFromStorage = localStorage.getItem('invoiceData') ?
        JSON.parse(localStorage.getItem('invoiceData')) : {}

const initialState = {
    cart: { 
        cartItems: cartItemsFromStorage,
        shippingAddress: shippingAddressFromStorage,
        invoiceData: invoiceDataFromStorage
    },    
    userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store