import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Col } from 'react-bootstrap';
import FormContainer from '../components/FormContainer'
import { savePaymentMethod } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps';

function PaymentScreen() {

  const cart = useSelector(state => state.cart)
  const { shippingAddress } = cart
  
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [paymentMethod, setPaymentMethod] = useState('PayPal')
  const [mpagotMethod, setMpagoMethod] = useState('MercadoPago')

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
  }, [])

  if(!shippingAddress.address){
    navigate('/shipping')
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(savePaymentMethod(paymentMethod))
    navigate('/placeorder')
  }

  return (
    <FormContainer>
        <CheckoutSteps step1 step2 step3 step4/>
        <p className='mt-2 fw-bolder fs-3'>Seleccione Método de Pago</p>
        <Form onSubmit={submitHandler}>
            <Form.Group>
                  <Col>
                    <Form.Check
                        type='radio'
                        label=<span><b>PayPal o Tarjeta de Crédito</b></span>
                        id='paypal'
                        name='paymentMethod'
                        checked
                        onChange={(e) => setPaymentMethod(e.target.value)}
                        className='my-3 fs-4'
                    >
                    
                    </Form.Check>

                </Col>
            </Form.Group>

            <Button type='submit' variant='primary' className='btn-md my-2 bg-success fs-4' style={{fontFamily: "Quicksand"}}>Continuar</Button>
        </Form>
    
    </FormContainer>
  )
}

export default PaymentScreen