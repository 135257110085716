import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { listProducts } from '../actions/productActions'; 
import { useNavigate, useLocation } from 'react-router-dom'

function SearchBoxProductsCG() {

  let navigate = useNavigate()
  let location = useLocation()
  const [keyword, setKeyword] = useState('')
  const dispatch = useDispatch()

  const submitHandler = (e) => {
      e.preventDefault()
      if(keyword){
        navigate(`/shopCG/?keyword=${keyword}`)
      }else{
        navigate(location.pathname)
      }
  }

  return (
    <Form onSubmit={submitHandler} className='d-flex align-items-center' inline>
         <Form.Control
            type='text'
            name='q'
            placeholder='Nombre Producto'
            onChange={(e) => setKeyword(e.target.value)}
            className='mr-sm-2 ml-sm-5 mx-1 fw-bold'
            
        >
        
        </Form.Control>
          <Button
                type='submit'
                variant='outline-primary'
                className='btn-sm px-2 py-2 btn-space'
                style={{fontFamily: "Quicksand"}}
            > 
            BUSCAR
          </Button>
    </Form>
  )
}

export default SearchBoxProductsCG