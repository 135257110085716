import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';

function AboutScreen() {

 window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  return (
    <div class='container-fluid'>
      <section className='p-1 text-center text-sm-start my-2'>
          <Row className='align-items-center justify-content-around text-center'>
            <Col lg={6} className='d-flex justify-content-center align-items-center mt-2'>
              <h1 className='colortextabouth1'>Acerca de este sitio</h1>
            </Col>
          </Row>
          <hr className='text-info'/>
          <Row >
            <Col lg={6} className='d-flex justify-content-start align-items-center'>
              <h3 className='colortextabouth1'>Bienvenid@s</h3>
            </Col>
          </Row>
          <hr className='text-info'/>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='colortextabout text-initial fs-3 fw-bolder'>Este sitio lo diseñé con la intención de exponer lo que ha sido mi vida a lo largo de los años 
                   en el ámbito laboral, personal, experiencias, servicios que ofrezco, proyectos y algunas cosas más como un blog y un foro. <br />
                <br />
                Porqué este sitio?
                La razón principal. <br /> 
                <span className='fs-3 text-success fw-bolder'>Mostrar mi experiencia profesional.</span> <br />
                <br />
                En que te puedo ayudar a que tu empresa, a ti, a algún pariente, a algún amigo, quiera o necesite un servicio de tecnologías de información o de sistemas de
                información, un desarollo de un sitio web con llave en mano o quizás un consejo o soporte en cualquiera de estas áreas. <br />
                <br />
                Por otro lado, este sitio también lo hice para que me conozcas. Que a través de mis secciones puedas descubrir lo que he hecho, qué me gusta hacer, hacia donde voy
                y que participes conmigo en las secciones que he creado para ti. <br />
                <br /> 
                Los invito a todos, con mucho gusto, a que se den un tour por este sitio y lo visiten, y compartan conmigo sus <Link to='/contact' className='mt-2 colortextabout'>comentarios</Link> y porque no también sus experiencias.</p>
            </Col>
          </Row>
          <hr className='text-info'/>                   
          </section>
          <section className='text-dark-50 p-1 text-center text-sm-start my-2'>
          <Row className='align-items-center justify-content-around text-center'>
            <Col lg={6} className='d-flex justify-content-center align-items-center mt-2'>
              <h1 className='mb-3'>Acerca de mi</h1>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='colortextabout text-initial fs-3 fw-bolder'>
                Cuento con mas de 30 años de experiencia en el área de sistemas y he trabajado en diversas empresas tanto en el área de tecnologías de 
                información así como en el área de sistemas de información.   
                <br />
                <br />
                Soy Licenciado en Software y mis ultimos 5 años de carrera profesional los he dedicado a 3 áreas fundamentales en mi vida. La primera y la que más me apasiona, el desarrollo
                web, la segunda y que también considero muy importante, la inteligencia de negocios en las empresas y la tercera, que al igual me 
                apasiona poder difundir mis conocimientos y experiencias a través de cursos presenciales y en línea.
                <br />
                <br />
                En 2016, hice una especialización en Datawarehouse e Inteligencia de Negocios en la Universidad de Colorado, Denver y tengo mucha experiencia en análisis de datos.
                <br />
                Actualmente, estoy combinando dos de mis pasiones. Estoy trabajando en un sitio web en donde aplico inteligencia de negocios.
                <br />
                <br />
                Espero encuentres interés por este sitio. Para mí fue un placer y orgullo haber creado este espacio.
                <br />
                <br />
                Los invito nuevamente a que se den un tour por este sitio y compartan conmigo sus <Link to='/contact' className='mt-2 colortextabout'>comentarios</Link> y porque no sus experiencias.</p>
              </Col>
          </Row>
          <hr className='text-info'/>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center my-2'>
              <h3 className='colortextabouth1'>Mis Aficiones o Pasatiempos</h3>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan mt-0'>
              <p className='colortextabout text-initial fs-4 fw-bolder'>Mis aficiones, sin orden de importancia.
                <ul>
                  <li>Leer. Principalmente tecnología, música y noticias. <i class="fas fa-book-reader"></i></li>
                  <li>Música. Rock, Rock Progresivo viejitas y actuales, Electrónica. Grupo preferido: <a href="https://porcupinetree.com/" target="_blank" className='text-dark'>Porcupine Tree</a> <i class="fa-solid fa-music"></i></li>
                  <li>Ejercicio. Hago por lo menos 30min. 5 veces a la semana. De esta rutina, <a href="https://www.youtube.com/watch?v=wk4-TDsTF_8&list=WL&index=53" target="_blank" className='text-dark'>3</a> ,veces cada día. <i class="fa-solid fa-dumbbell"></i></li>
                  <li>Redes Sociales. Facebook, youtube.</li>
                  <li>La cocina. Trato, pero no me doy el tiempo de hacerlo. <i class="fas fa-hamburger"></i></li>
                  <li>Me encantan mis 3 perros. Shinfu, Yoshi y Kumo. <i class="fa-solid fa-dog"></i><i class="fa-solid fa-dog"></i><i class="fa-solid fa-dog"></i></li>
                  <li>Me gusta mucho la NFL y seguir todos sus partidos. <i class="fa-solid fa-football"></i></li>
                  <li>No es por nada pero ahora con el mundial de Qatar, futbol soccer de otras galaxias. <i class="fa-solid fa-futbol"></i></li>
                  <li>Series: This is us, Bing Bang Theory, viejitas como los Monsters, Los Locos Adams, y algunas otras.</li>
                  <li>Películas: De acción, principalmente. Cine internacional: Españolas y Argentinas. <i class="fa-solid fa-film"></i></li>
                  <li>Parrilla: Me encanta meterme y preparar unos ricos cortes.</li>
              </ul>
            </p>
            </Col>
          </Row>         
          </section>
    </div>
  )
}

export default AboutScreen