import React from 'react'

function Rating({ value, text, color }) {
  return (
    <div className='rating my-3 d-flex justify-content-start align-items-center fs-3'>
        <span>
            <i style={{color}} className={
                value >= 1
                ? 'fas fa-star text-warning'
                : value >= 0.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }>

            </i>
        </span>

        <span>
            <i style={{color}} className={
                value >= 2
                ? 'fas fa-star text-warning'
                : value >= 1.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }>

            </i>
        </span>

        <span>
            <i style={{color}} className={
                value >= 3
                ? 'fas fa-star text-warning'
                : value >= 2.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }>

            </i>
        </span>

        <span>
            <i style={{color}} className={
                value >= 4
                ? 'fas fa-star text-warning'
                : value >= 3.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }>

            </i>
        </span>

        <span>
            <i style={{color}} className={
                value >= 5
                ? 'fas fa-star text-warning'
                : value >= 4.5
                ? 'fas fa-star-half-alt'
                : 'far fa-star'
            }>

            </i>
        </span>
        <span className = 'mx-2 text-muted fs-4'><b>{text && text}</b></span>
    </div>
  )
}

export default Rating