import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useParams, useNavigate } from 'react-router-dom';
import { listServiceDetails } from '../actions/serviceActions'
import { SERVICE_CREATE_DETAILS_RESET } from '../constants/serviceConstants'
import Moment from 'react-moment';
//import EditorToolbar, { modules, formats } from "./EditToolbar";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Player, ControlBar, ForwardControl, LoadingSpinner } from 'video-react'
import "video-react/dist/video-react.css"; // import css


function ServiceDetailScreen({ match }) {
 
    const { id } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const serviceDetails = useSelector(state => state.serviceDetails)
    const { error, loading, service } = serviceDetails

    let keyword = useLocation().search

    useEffect(() => {
        dispatch(listServiceDetails(id))

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }, [dispatch, id, keyword])


   return (
    <div className="section1 container">
        <Link className='btn btn-light mt-1 mb-4 mt-2 ' to='/servicecart'>Go Back</Link>
        {
            loading ? <Loader />
            : error ? <Message variant='danger'>{error}</Message>
            : (
            <div>
            <div class='row'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center'>
                    <h3 className='my-1' style={{color: '#421d74'}}><b>{service.name}</b></h3>
                </div>
            </div>
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                <div class="p-1 bd-highlight align-items-center"><p className='fs-6 fw-bold'><b><Moment format="DD/MM/YYYY hh:mm:ss">{service.createdAt}</Moment></b></p></div>
            </div>
            <div class="row container app">
                <div class="col-12 p-1 text-center d-flex justify-content-center align-items-center">
                    <ReactQuill 
                        theme="snow"
                        readOnly = 'true'
                        value={service.comment_rtf}
                        className='fontrtf'
                        style={{fontFamily: "Quicksand"}}
                    />
                </div>
            </div>
            {service.video_file && (
                <div class='row'>
                <div class='col-md text-center d-flex justify-content-start align-items-center' >
                <Card className='border border-success border-1 rounded w-50 mb-2' >
                <Card.Body>
                    <Player
                        fluid={true} 
                        playsInline
                        src={service.video_file}
                        className='d-flex justify-content-center align-items-center playerv'
                        >
                        <LoadingSpinner />
                        <ControlBar autoHide={false} >
                        <ForwardControl seconds={5} order={3.1} />
                        <ForwardControl seconds={10} order={3.2} />
                        </ControlBar>
                    </Player>
    
                </Card.Body>
              </Card>
              </div>
              </div>
            )}   

        </div>


          )
        }
    </div>
  )
}

export default ServiceDetailScreen