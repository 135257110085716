import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import image1 from '../assets/images/LOGOLALOLANATURAL.png'
import image5 from '../assets/images/LOGOSOKLORENA.png'

function NaturalScreen() {

 window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  return (
    <div class='container'>
      <section className='p-1 text-center text-sm-start'>
          <Row className='mt-4'>
            <Col lg={3}></Col>
            <Col lg={3}></Col>
            <Col lg={6} className='d-flex justify-content-center align-items-center'>
              <Link to='/recipePN' className='mt-1 fs-4 my-1 btn btn-success text-light' style={{color: '#421d74'}}>Recetas o Artículos Productos Naturales</Link>
            </Col>
          </Row>
          <Row >
            <Col lg={3} className='d-flex justify-content-center align-items-center'>
                <img class="img-fluid d-block "
                src={image1} style={{width: 220}}/>
            </Col>
            <Col lg={9} className='d-flex justify-content-center align-items-center mt-4'>
              <p className='text-center fw-bold fs-1 fst-italic text-center' style={{color: '#596488'}}><b>Lalola NATURAL</b></p>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-around text-center'>

        </Row>
          <hr className='text-info'/>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='text-initial fs-3 fw-bolder' style={{lineHeight: '1.5'}}>Hoy está en boga regresar a lo natural; en los alimentos, en el vestir, en maquillaje, en lo que nos ponemos en el cuerpo, en la alimentación de nuestros compañeros perrunos o gatunos; en lo que usamos para la limpieza de la casa. <br />
              <br /> Pero natural no solo es eso; también es caminar descalzo, tener una planta en casa, reciclar, reutilizar, silenciar por un momento todo ese ruido externo, conectarnos con nosotros. 
              <br />
              <br />
              Descubriremos juntos nuevas opciones de alimentos, para que tú vayas experimentando y descubras que es lo que más te gusta; sobre todo, escucha a tu cuerpo, él sabe lo que quiere cada día.
              <br />
              <br />Conoceremos la manera de nutrir nuestra piel y nuestro cabello. <br />
              <br />
              También iremos a lugares y experiencias nuevas. <br />
              <br /> 
              No tenemos que hacer grandes cambios, volvernos veganos o vivir en la copa de un árbol. Todos somos diferentes y este camino es individual; lo que es mejor para uno puede ser que para otro no lo sea. No hay que compararnos con nadie, lo único es escucharnos y ser mejor cada día.
              <br />
              <br />
              Bienvenido a este viaje.
              </p>
            </Col>
          </Row>
          <Row className='container-fluid'>
            <Col md={12} className="p-1 mb-4 d-flex justify-content-end align-items-center">
            <Link to='/bio' className='text-decoration-none text-success'>
                <img class="img-fluid d-block"
                src={image5} style={{width: 160}}/>
            </Link>                                   
            </Col>
        </Row>
                  
          </section>

    </div>
  )
}

export default NaturalScreen