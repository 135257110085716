import React, {useState, useEffect} from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import image1 from '../assets/images/LOGOSOKLORENA.png'
import image6 from '../assets/images/LoreFotoChica.jpg'
import { Player, ControlBar, ForwardControl, LoadingSpinner } from 'video-react'
import "video-react/dist/video-react.css"; // import css
import video1 from '../assets/videos/QUIEN SOY.mp4';

function BioScreen() {

 window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  return (
    <div>
        <section>
        <div class="container-lg text-center">
          <div class="row">
            <div class="col-md text-center mt-4">
            <Card className='border border-success border-1 rounded h-80 w-100' >
            <Card.Header>
                <p className='fw-bolder fs-3 text-center mt-1' style={{color: '#421d74'}}>Quien soy</p>
            </Card.Header>
            <Card.Body>
                <Player
                    fluid={true} 
                    playsInline
                    src={video1}
                    className='d-flex justify-content-center align-items-center playerv'
                    >
                    <LoadingSpinner />
                    <ControlBar autoHide={false} >
                    <ForwardControl seconds={5} order={3.1} />
                    <ForwardControl seconds={10} order={3.2} />
                    </ControlBar>
                </Player>

            </Card.Body>
          </Card>
            </div>
            <div class="col-md text-center mt-4 d-flex justify-content-center align-items-center">
              <span className='fw-bold fs-2 fst-italic' style={{color: '#596488'}}><strong className='font-monospace'>Hola, soy Lorena Madrigal Sosa</strong></span>
            </div>
            <div class="col-md mt-4 text-center ">
              <img class="img-fluid"
              src={image6} style={{width: 335}}/>

            </div>
          </div>
        </div>
        </section>
          <hr className='text-info'/>
          <section >
          <Row>
            <Col md={1}></Col>  
            <Col md={10} className='d-flex justify-content-center align-items-center colorspan'>
              <p className=' text-initial fs-3 fw-bolder mx-1' style={{lineHeight: '1.6'}}>Nací un 28 de abril de 1977. <br />
              <br />  
              Estudié la carrera de Administración y Finanzas en la Universidad Panamericana, donde también <br /> hice una Especialidad en Mercadotecnia Internacional. <br />
              <br />
              A lo largo de estos años he estudiado Diplomado en Nutrición en la UPAEP, varios <br /> Diplomados y Cursos de Gastronomía en el Colegio Superior de Gastronomía, <br /> Diplomado en Acupuntura, Diplomado en Biomagnetismo y una Certificación Hatha Yoga. <br />
              <br />
              Cursos, talleres y seminarios en el manejo de la energía. <br />
              <br />
              Me encanta leer y aprender nuevas cosas. <br />
              <br />
              Caminar descalza es una de las cosas que mas me gustan hacer, sentir el pasto o la <br /> tierra en mis dedos. El olor de la tierra mojada. <br />
              <br />
              Me encanta pasear a mis 3 pequeños ángeles peludos. <br />
              <br />
              El deporte que mas me gusta ver es la NFL. <br />
              <br />
              He viajado sola y es maravilloso. <br />
              <br />
              Una de las cosas que mas me gustan es mi sonrisa y que veo lo hermoso en todo. <br />
              <br />
              He llevado serenata, he regalado flores y compuesto canciones. <br />
              <br />
              Me he caído y perdido el rumbo, pero me he levantado y he vuelto a construir, <br /> reconstruir y moldear a Lorena. <br />
              <br />
              Lo demás lo conocerán a lo largo de los podcasts y de cada una de las secciones de este sitio. <br />
              <br />
              Con amor
              </p>
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row>
            <Col md={12} className="p-1 mb-3 mt-0 d-flex justify-content-end align-items-center">
              <Link to='/bio' className='text-decoration-none text-success'>
                  <img class="img-fluid d-block"
               src={image1} style={{width: 170}}/>
              </Link>                                   
            </Col>                        
          </Row>
                  
          </section>
          </div>
  )
}

export default BioScreen