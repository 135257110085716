import React from 'react';
import { Card, Image, Row, Col} from 'react-bootstrap';
import Rating from './Rating';
import { Link } from 'react-router-dom'
import NumberFormat from 'react-number-format';


function ProductShop({ product }) {
  return (
    <Col md={4} key={product._id} className='d-flex justify-content-center align-items-center my-1' >    
        <div class="card mb-4 border-3 product-wap rounded w-100 h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: '#F1F3EE', borderColor: '#421d74'}}>
        <div class="card rounded-0">
            <Link to={`/product/${product._id}`} className='d-flex justify-content-center align-items-center'>
                <Card.Img src={product.image1} class="card-img rounded-0 img-fluid w-100 h-100" alt='fotoP' resizeMode='contain' />
            </Link>    
        </div>
        <div class="card-body" >
                <Link to={`/product/${product._id}`} className="d-flex justify-content-center align-items-center text-center">
                    <strong><span style={{ color: '#59ab6e'}} className='fst-italic fs-3 fw-bold'><strong>{product.name}</strong></span></strong>
                </Link>
                <div class='row'>
                    <div className='col-sm-12 text-center mt-3'>
                      <span className='fw-bolder fs-4'><b>{product.description}</b></span>
                    </div>
                </div>
                <div class="row gy-1">
                    <div className='col-md-5 '>
                        <Card.Text className='my-4 d-flex justify-content-center align-items-center'>
                            <b className='text-center fs-4'>Descuento</b>
                        </Card.Text>
                        <Card.Text className='text-right my-4 text-danger d-flex justify-content-center align-items-center'>
                            {product.discount !== 0 ? <span className='fw-bolder fs-4'>- {product.discount} %</span> : ''}
                        </Card.Text>
                    </div>
                    <div className='col-md-7 '>
                        <Card.Text className='my-4 d-flex justify-content-center align-items-center'>
                            <b className='text-center fs-4'>Precio Regular</b>
                        </Card.Text>
                        <Card.Text className='text-right my-4 text-decoration-line-through text-danger d-flex justify-content-center align-items-center'>
                            {product.discount !== 0 ? <span><NumberFormat value={product.pricereg} displayType={'text'} thousandSeparator={true} prefix={'$'} className='fw-bolder fs-4'/> <b>MN</b></span> : ''}
                        </Card.Text>
                    </div>
                </div>
                <div class="row gy-3">
                    <div className='col-md-6'>
                        <Card.Text className='my-4 d-flex justify-content-center align-items-center'>
                            <b className='text-center fs-4'>Precio Descuento</b>
                        </Card.Text>
                        <Card.Text className='my-4 d-flex justify-content-center align-items-center'>
                            <span className='p-1 fw-bold fs-4'><NumberFormat value={Number((product.pricereg - ((product.pricereg / 100) * product.discount))).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/>MN</span>
                        </Card.Text>
                    </div>
                    <div className='col-md-6'>
                        <Card.Text className='my-3 d-flex justify-content-center align-items-center'>
                            {product.countInStock === 0 ? <span className='text-light bg-danger fw-bolder p-2 mt-1'>Agotado</span> : <span className="text-light bg-success p-2 mt-1 fw-bolder fs-4" >Disponible</span>}
                        </Card.Text>
                    </div>   
                </div>
                <Card.Text className='mt-2 d-flex justify-content-center align-items-center'>
                    <Rating value={product.rating} text={`${product.numReviews} reviews`} color={'#f8e825'}/>
                </Card.Text>   

        </div>
    </div>
</Col>

  )
}

export default ProductShop


