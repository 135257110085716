import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useParams, useNavigate } from 'react-router-dom';
import { listForoDetails, createForoDetail } from '../actions/foroActions'
import { FORO_CREATE_DETAILS_RESET } from '../constants/foroConstants'
import Moment from 'react-moment';

function ForoDetailScreen({ match }) {
    const [rating, setRating] = useState(5)
    const [comment, setComment] = useState('')    

    const { id } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const foroDetails = useSelector(state => state.foroDetails)
    const { error, loading, foro } = foroDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
      
    let keyword = useLocation().search

    const foroDetailCreate = useSelector(state => state.foroDetailCreate)
    const { error: errorForoReview, loading: loadingForoReview, success: successForoReview } = foroDetailCreate

    useEffect(() => {
        if(successForoReview){
            setRating(5)
            setComment('')
            dispatch({type: FORO_CREATE_DETAILS_RESET})
        }

        dispatch(listForoDetails(id))

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        
    }, [dispatch, id, successForoReview, keyword])

 
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createForoDetail(id, {
            rating,
            comment
        }))
    }

  return (
    <div className="section1 container">
        <Link className='btn btn-light my-3' to='/foro'>Go Back</Link>
        {
            loading ? <Loader />
            : error ? <Message variant='danger'>{error}</Message>
            : (
            <div className='container'>
            <div class='row mt-3'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center mb-1'>
                    <p className='my-2 fs-1' style={{color: '#421d74'}}><b>{foro.name}</b></p> 
                </div>
            </div>
            <Row>
                <Col lg={6} className='d-flex justify-content-center align-items-center my-3'>
                    <p className='fs-4'><b>Colaboración de: {foro.author}</b></p>
                </Col>
                <Col lg={6} className='d-flex justify-content-center align-items-center my-3'>
                    <p className='fs-4'><b><Moment format="DD/MM/YYYY hh:mm:ss">{foro.createdAt}</Moment></b></p>
                </Col>
            </Row>    
            <Row>
                <Col lg={2}></Col>
                <Col lg={8} className='d-flex justify-content-center align-items-center'>
                    <p className='my-1 text-center fs-4 fw-bolder'><b>{foro.comment}</b></p>
                </Col>
                <Col lg={2}></Col>
            </Row>
            <div class='row'>
                <Col lg={3}></Col>
                <Col lg={6} className='d-flex justify-content-center align-items-center mb-1'>
                    <p className='mb-3 my-3 fs-4 fw-bold text-center text-danger' style={{color: '#421d74'}}><strong>Que opinas? Quieres participar... por favor deja tus comentarios</strong></p>
                </Col>
                <Col lg={3}></Col>                
                <hr className='text-info'/>
            </div>
            

            <div class='row'>
                        <div class='d-flex flex-row bd-highlight justify-content-center align-items-center'>
                            <div class="col-lg-10 d-flex justify-content-center align-items-center">
                                {foro.reviews.length === 0 && <Message variant='info'><strong>No hay comentarios</strong></Message>}                        
                            </div>
                        </div>

                        {foro.reviews.map((review) => (
                        <div class='d-flex flex-row bd-highlight justify-content-center align-items-center'>
                             <div class="col-lg-6">
                                <div class="p-1 bd-highlight justify-content-center align-items-center"><p className='fs-6 fw-bold'><b>Por: {review.name}</b></p></div>
                                <div class="p-1 bd-highlight align-items-center mb-2"><p className='fs-4 fw-bolder' style={{color: '#421d74'}}>{review.comment}</p></div>
                                <div class="p-1 bd-highlight align-items-center"><p className='fs-6 fw-bold'><b><Moment format="DD/MM/YYYY hh:mm:ss">{review.createdAt}</Moment></b></p></div>
                                <strong><hr /></strong>
                            </div>
                        </div>
                        ))}
            </div>

            <Row>
                <Col lg={2}></Col>
                <Col lg={8} class='d-flex bd-highlight justify-content-center align-items-center'>
                    <ListGroup.Item>
                        <p className='text-info my-1 mb-3 fw-bold fs-3'>Escribe un comentario</p>

                        {loadingForoReview && <Loader />}
                        {successForoReview && <Message variant='success'>Comentario Enviado</Message>}
                        {errorForoReview && <Message variant='danger'>{errorForoReview}</Message>}

                        {userInfo ? (
                            <Form onSubmit={submitHandler} >
                                <Form.Group controlId='comment'>
                                    <Form.Control
                                        as='textarea'
                                        row='5'
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    >
                                    </Form.Control>                               
                                </Form.Group>
                                <Button
                                    disabled={loadingForoReview}
                                    type='submit'
                                    variant='success'
                                    className='btn-md my-2 text-light mt-3 fs-4'
                                >
                                    Enviar Comentario
                                </Button>
                            </Form>
                        ) : (
                            <Message variant='info'>
                                Por favor <Link to='/login'><strong>Ingresar</strong></Link> para escribir comentario
                            </Message>
                        )}
                </ListGroup.Item>
                </Col>
                <Col lg={2}></Col>
            </Row>

        </div>
          )
        }
    </div>
  )
}


export default ForoDetailScreen