import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import image2 from '../assets/images/LALOLACOCINA.png'
import image5 from '../assets/images/LOGOSOKLORENA.png'
import image6 from '../assets/images/FOTO 1.JPG'

function LaCocinaScreen() {

 window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  return (
    <div class='container'>
      <section className='p-1 text-center text-sm-start'>
        <Row className='mt-4'>
          <Col lg={4}></Col>
          <Col lg={4}></Col>
          <Col lg={4} className='d-flex justify-content-center align-items-center'>
            <Link to='/recipeCG' className='mt-1 fs-5 my-2 btn btn-success text-light' style={{color: '#421d74'}}>Recetas o Artículos Cocina Gourmet</Link>
          </Col>
         </Row>
          <Row >
            <Col lg={3} className='d-flex justify-content-center align-items-center'>
                <img class="img-fluid"
                src={image2} style={{width: 220}}/>
            </Col>
            <Col lg={5} className='d-flex justify-content-center align-items-center mt-3'>
                <p className='text-center fw-bold fs-1 fst-italic text-center' style={{color: '#596488'}}><b>Lalola Cocina Gourmet</b></p>
            </Col>
            <Col md={4} className="p-1 mb-4 d-flex justify-content-center align-items-center">
              <img class="img-fluid"
                src={image6} style={{width: 300}}/>
            </Col>            
          </Row>
          <hr className='text-info'/>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='colortextabout text-initial fs-3 fw-bolder' style={{lineHeight: '1.5'}}>Bienvenidos a esta sección de cocina, donde como les comento al inicio, hablaremos del mundo de la gastronomía. <br />

              Desde muy chica me ha gustado cocinar; ya que cuentan las leyendas, que alrededor de los 10 años hice mi 1er sopa de pasta sin supervisión de un adulto; podrán imaginarse como salió eso; mi abuela al ver esto le dijo a mi mamá, nos comemos la sopa no importa si ve mal o sabe peor. <br /> <br />
              
              La vida no me llevo a estudiar gastronomía como profesión, pero si como pasión.
              
              Tomé diplomados, cursos y talleres en el Colegio Superior de Gastronomía de la Ciudad de México; donde conocí grandes maestros y amigos.
              
              Así fue como en 2016 decido dejar mi trabajo en una oficina y poner mi negocio, llamado La Cocina de Lalola, donde hacia eventos para otras personas; cocinaba y también montaba mesas de dulces o una comida formal. <br /><br />
              Eran días en ocasiones que apenas dormía, porque siempre me ha gustado hacer todo. 
              En una ocasión para un evento de empresa a las 7am, me pare desde las 4am para hacer los jugos y la fruta picada para que todo estuviera lo más fresco; hice las ciabattas con antelación (no me gusta comprarlas), brownies  y nos fuimos al evento.
              Si no les voy a negar que es cansado, quien se dedica a la comida es un negocio muy noble, pero a la vez demandante. <br /> <br />
              
              Pero la mayor recompensa es cuando vez la cara de los comensales, degustando los panes y al decirles que tú los hiciste, no puedan creerlo y te dicen que son los mejores que han comido.
              
              Siempre me han preguntado, que te gusta mas cocinar; la verdad no tengo una respuesta a esa pregunta, todo disfruto hacer.
              
              Claro que cada platillo tiene su esencia y su proceso; los panes salados no podemos apresurarlos para que fermenten, hay que darles su tiempo.
              
              
              Dejemos ya a un lado mi historia con la cocina y entremos en materia.
              
              
              Vamos a subir videos donde explicamos términos que se usan en la gastronomía, preparaciones de ciertos platillos; y para leer un poco de historia y claro recetas.
              <br />
              <br />
              Como siempre, este es un espacio abierto para todos ustedes.
              </p>
            </Col>
          </Row>
          <Row className='container-fluid'>
            <Col md={12} className="p-1 mb-4 d-flex justify-content-end align-items-center">
            <Link to='/bio' className='text-decoration-none text-success'>
              <img class="img-fluid d-block"
              src={image5} style={{width: 160}}/>
            </Link>                                   
            </Col>
          </Row>
                   
          </section>

    </div>
  )
}

export default LaCocinaScreen