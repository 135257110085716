export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCTS_ALL_LIST_REQUEST = 'PRODUCTS_ALL_LIST_REQUEST'
export const PRODUCTS_ALL_LIST_SUCCESS = 'PRODUCTS_ALL_LIST_SUCCESS'
export const PRODUCTS_ALL_LIST_FAIL = 'PRODUCTS_ALL_LIST_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL'

export const PRODUCT_CATEGORY_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_CATEGORY_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_CATEGORY_FAIL = 'PRODUCT_TOP_FAIL'

export const REVIEW_LIST_REQUEST = 'REVIEW_LIST_REQUEST'
export const REVIEW_LIST_SUCCESS = 'REVIEW_LIST_SUCCESS'
export const REVIEW_LIST_FAIL = 'REVIEW_LIST_FAIL'

export const PRODUCT_CATEGORY_COMBO_REQUEST = 'PRODUCT_CATEGORY_COMBO_REQUEST'
export const PRODUCT_CATEGORY_COMBO_SUCCESS = 'PRODUCT_CATEGORY_COMBO_SUCCESS'
export const PRODUCT_CATEGORY_COMBO_FAIL = 'PRODUCT_CATEGORY_COMBO_FAIL'
