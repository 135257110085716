import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Carousel, Image, Modal, Button } from 'react-bootstrap'
import Loader from './Loader';
import Message from './Message'
import { listTopProducts } from '../actions/productActions'

function ImageGallery( {product} ) {
    const dispatch = useDispatch()

    const [show, setShow] = useState(false);
    const [imagen, setImagen] = useState(product.image2);

    useEffect(() => {
        dispatch(listTopProducts())
    }, [dispatch])

    const handleClose = () => setShow(false);

    const hmHandler = (imagen) => {
        setImagen(imagen)
        setShow(true)   
    }
    

  return (
      <div>
        <div class="row justify-content-md-center my-1" style={{fontFamily: "Quicksand"}}>
                <div class="col-3">
                        <Image as='button' onClick={() => hmHandler(product.image2)} src={product.image2} alt={product.name} fluid style={{ width: 200, height: 95, cursor: 'pointer' }} resizeMode='contain'/>
                </div>
                <div class="col-3">
                        <Image as='button' onClick={() => hmHandler(product.image3)} src={product.image3} alt={product.name} fluid style={{ width: 200, height: 95, cursor: 'pointer' }} resizeMode='contain'/>
                </div>

            </div>
                <Modal show={show} onHide={handleClose}         
                backdrop="static"
                keyboard={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >

                <Modal.Header closeButton>
                <Modal.Title>{product.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class='row'>
                        <div class="col-md-12 mx-auto">
                            <Image src={imagen} alt={product.name} fluid resizeMode='contain' style={{width: 'auto', height: 'auto'}}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} style={{fontFamily: "Humnst777"}}>
                    Close
                </Button>
                </Modal.Footer>
                </Modal>

        </div>
    )
}

export default ImageGallery