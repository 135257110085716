import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { saveInvoiceData } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps';

function InvoiceScreen() {
  const cart = useSelector(state => state.cart)
  const { invoiceData } = cart

  const dispatch = useDispatch()

  const navigate = useNavigate()
  
  const [rfc, setRfc] = useState(invoiceData.rfc)
  const [socialname, setSocialname] = useState(invoiceData.socialname)
  const [phone, setPhone] = useState(invoiceData.phone)
  const [address, setAddress] = useState(invoiceData.address)
  const [city, setCity] = useState(invoiceData.city)
  const [postalCode, setPostalCode] = useState(invoiceData.postalCode)
  const [country, setCountry] = useState(invoiceData.country)
  const [email, setEmail] = useState(invoiceData.email)
  const [cfdi, setCfdi] = useState(invoiceData.cfdi)
  const [payment, setPayment] = useState(invoiceData.payment)

  useEffect(() => {
 
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
  }, [])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(saveInvoiceData({ rfc, socialname, phone, address, city, postalCode, country, email, cfdi, payment }))
    navigate('/payment')
  }

 
  return (
    <FormContainer>
    <CheckoutSteps step1 step2 step3/>
      <p className='my-1 fw-bolder titulocompra fs-1'>Datos Facturación</p>
      <Form onSubmit={submitHandler} className='my-4'>
          <Form.Group controlId='rfc'>
            <Form.Label className='my-1 fw-bolder fs-3'><b>RFC</b></Form.Label>
            <Form.Control
                required
                type='text'
                placeholder='Ingresa el RFC'
                value={rfc ? rfc : ''}
                onChange={(e) => setRfc(e.target.value)}
                className='fs-4 fw-bold'
            >
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='socialname'>
            <Form.Label className='my-2 fw-bolder fs-3'><b>Razón Social</b></Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='Ingresa la razón social'
              value={socialname ? socialname : ''}
              onChange={(e) => setSocialname(e.target.value)}
              className='fs-4 fw-bold'
            >
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='phone'>
            <Form.Label className='mt-1 fw-bolder fs-3'><b>Teléfono</b></Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='Ingresa el teléfono'
              value={phone ? phone : ''}
              onChange={(e) => setPhone(e.target.value)}
              className='fs-4 fw-bold'
            >
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='address'>
            <Form.Label className='mt-1 fw-bolder fs-3'><b>Dirección</b></Form.Label>
            <Form.Control
              required
              type='text'
              placeholder='Ingresa la dirección fiscal'
              value={address ? address : ''}
              onChange={(e) => setAddress(e.target.value)}
              className='fs-4 fw-bold'
            >
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='city'>
            <Form.Label className='mt-1 fw-bolder fs-3'><b>Ciudad</b></Form.Label>
            <Form.Control
                required
                type='text'
                placeholder='Ingresa la ciudad'
                value={city ? city : ''}
                onChange={(e) => setCity(e.target.value)}
                className='fs-4 fw-bold'
            >
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='postalCode'>
            <Form.Label className='mt-1 fw-bolder fs-3'><b>Código Postal</b></Form.Label>
            <Form.Control
                required
                type='text'
                placeholder='Ingresa el código postal'
                value={postalCode ? postalCode : ''}
                onChange={(e) => setPostalCode(e.target.value)}
                className='fs-4 fw-bold'
            >
            </Form.Control>
          </Form.Group>

            <Form.Group controlId='country'>
              <Form.Label className='mt-1 fw-bolder fs-3'><b>País</b></Form.Label>
              <Form.Control
                  required
                  type='text'
                  placeholder='Ingresa el País'
                  value={country ? country : ''}
                  onChange={(e) => setCountry(e.target.value)}
                  className='fs-4 fw-bold'
              >
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label className='mt-1 fw-bolder fs-3'><b>E-mail</b></Form.Label>
              <Form.Control
                  required
                  type='text'
                  placeholder='Ingresa el correo electrónico'
                  value={email ? email : ''}
                  onChange={(e) => setEmail(e.target.value)}
                  className='fs-4 fw-bold'
              >
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='email'>
              <Form.Label className='mt-1 fw-bolder fs-3'><b>Uso de CFDI</b></Form.Label>
              <Form.Control
                  required
                  type='text'
                  placeholder='Ingresa el CFDI'
                  value={cfdi ? cfdi : ''}
                  onChange={(e) => setCfdi(e.target.value)}
                  className='fs-4 fw-bold'
              >
              </Form.Control>
            </Form.Group>

            <Form.Group controlId='payment'>
              <Form.Label className='mt-1 fw-bolder fs-3'><b>Forma de Pago</b></Form.Label>
              <Form.Control
                  required
                  type='text'
                  placeholder='Ingresa la forma de pago'
                  value={payment ? payment : ''}
                  onChange={(e) => setPayment(e.target.value)}
                  className='fs-4 fw-bold'
              >
              </Form.Control>
            </Form.Group>

            <Button type='submit' variant='primary' className='btn-md mt-3 bg-success fs-4' style={{fontFamily: "Quicksand"}}>Continuar</Button>
    </Form>
    </FormContainer>
  )
}

export default InvoiceScreen