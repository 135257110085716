import { 
    EXPIRIENCE_LIST_REQUEST, 
    EXPIRIENCE_LIST_SUCCESS, 
    EXPIRIENCE_LIST_FAIL,
    EXPIRIENCE_DETAILS_REQUEST,
    EXPIRIENCE_DETAILS_SUCCESS,
    EXPIRIENCE_DETAILS_FAIL,
    EXPIRIENCE_DELETE_REQUEST, 
    EXPIRIENCE_DELETE_SUCCESS, 
    EXPIRIENCE_DELETE_FAIL,

    EXPIRIENCE_CREATE_REQUEST, 
    EXPIRIENCE_CREATE_SUCCESS, 
    EXPIRIENCE_CREATE_FAIL,
    EXPIRIENCE_CREATE_RESET,
    
    EXPIRIENCE_UPDATE_REQUEST, 
    EXPIRIENCE_UPDATE_SUCCESS, 
    EXPIRIENCE_UPDATE_FAIL,
    EXPIRIENCE_UPDATE_RESET,

} from '../constants/expirienceConstants'

export const expirienceListReducer = (state = {expiriences: []}, action) => {
    switch(action.type){
        case EXPIRIENCE_LIST_REQUEST:
            return {loading: true, expiriences:[]}
        case EXPIRIENCE_LIST_SUCCESS:
            return {loading: false, expiriences: action.payload}
        case EXPIRIENCE_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const expirienceDetailsReducer = (state = {expirience: {reviews:[]}}, action) => {
    switch(action.type){
        case EXPIRIENCE_DETAILS_REQUEST:
            return { loading: true, ...state }
        case EXPIRIENCE_DETAILS_SUCCESS:
            return {loading: false, expirience: action.payload}
        case EXPIRIENCE_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const expirienceCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case EXPIRIENCE_CREATE_REQUEST:
            return { loading: true }
    
        case EXPIRIENCE_CREATE_SUCCESS:
            return { loading: false, success: true, expirience: action.payload }
    
        case EXPIRIENCE_CREATE_FAIL:
            return { loading: false, error: action.payload }
    
        case EXPIRIENCE_CREATE_RESET:
                return {}            
    
        default:
            return state
    }
    }
    
    
    export const expirienceDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case EXPIRIENCE_DELETE_REQUEST:
            return { loading: true }
    
        case EXPIRIENCE_DELETE_SUCCESS:
            return { loading: false, success: true }
    
        case EXPIRIENCE_DELETE_FAIL:
            return { loading: false, error: action.payload }
    
        default:
            return state
    }
    }
    
    
    export const expirienceUpdateReducer = (state = {expirience: {}}, action) => {
    switch (action.type) {
        case EXPIRIENCE_UPDATE_REQUEST:
            return { loading: true }
    
        case EXPIRIENCE_UPDATE_SUCCESS:
            return { loading: false, success: true, expirience: action.payload }
    
        case EXPIRIENCE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
    
        case EXPIRIENCE_UPDATE_RESET:
                return { expirience: {} }            
    
        default:
            return state
        }
    }