import React, { useEffect} from 'react';
import { Card, Image, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
//import Rating from './Rating';
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
//import ScrollAnimation from 'react-animate-on-scroll';

function Foro({ foro }) {

  const id = foro._id
  const dispatch = useDispatch()

   
  let keyword = useLocation().search

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [dispatch, keyword])

  return (
    <section className='container-fluid my-4'>
      <div class="grid text-center p-2" style={{backgroundColor: '#8c4799'}}>
        <div class="g-col-4">
          <Link to={`/foros/${foro._id}`} className='text-light'>
            <p className='text-center fs-1 my-2 my-3' style={{color: '#e0e4fd'}}><strong>{foro.name}</strong></p>
          </Link>
        </div>
        <div class="g-col-4"><p className='text-center fs-4 my-3' style={{color: '#fff'}}>{foro.comment}</p></div>
        <div class="g-col-4"><p className='text-center fs-4 my-3' style={{color: '#fff'}}>Colaboración de: <b>{foro.author}</b></p></div>
      </div>

    </section>

  )
}

export default Foro