import React, { useEffect } from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Moment from 'react-moment';


function Recipe({ recipe }) {

    const dispatch = useDispatch()

    let keyword = useLocation().search
  
    useEffect(() => {
  
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      
  }, [dispatch, keyword])
  return (
     <div>
     <Table bordered hover responsive className='table-lg text-dark fs-3 mt-3 table-success table-striped'>
     <tbody>
           <tr key={recipe._id}>
           <td className='fs-3 fw-bold'><Link to={`/recipe/${recipe._id}`} style={{color: '#421d74'}} >{recipe.name}</Link></td>
           <td>{recipe.author}</td>
           <td>{recipe.title}</td>
           <td>{recipe.category}</td>                                        
           <td><Moment format="DD/MM/YYYY hh:mm:ss">{recipe.createdAt}</Moment></td>
         </tr>
   </tbody>
 </Table>
 </div>

  
  )
}

export default Recipe