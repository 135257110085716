export const FILEPDF_LIST_REQUEST = 'FILEPDF_LIST_REQUEST'
export const FILEPDF_LIST_SUCCESS = 'FILEPDF_LIST_SUCCESS'
export const FILEPDF_LIST_FAIL = 'FILEPDF_LIST_FAIL'

export const FILEPDF_DETAILS_REQUEST = 'FILEPDF_DETAILS_REQUEST'
export const FILEPDF_DETAILS_SUCCESS = 'FILEPDF_DETAILS_SUCCESS'
export const FILEPDF_DETAILS_FAIL = 'FILEPDF_DETAILS_FAIL'

export const FILEPDF_DELETE_REQUEST = 'FILEPDF_DELETE_REQUEST'
export const FILEPDF_DELETE_SUCCESS = 'FILEPDF_DELETE_SUCCESS'
export const FILEPDF_DELETE_FAIL = 'FILEPDF_DELETE_FAIL'

export const FILEPDF_CREATE_REQUEST = 'FILEPDF_CREATE_REQUEST'
export const FILEPDF_CREATE_SUCCESS = 'FILEPDF_CREATE_SUCCESS'
export const FILEPDF_CREATE_FAIL = 'FILEPDF_CREATE_FAIL'
export const FILEPDF_CREATE_RESET = 'FILEPDF_CREATE_RESET'

export const FILEPDF_UPDATE_REQUEST = 'FILEPDF_UPDATE_REQUEST'
export const FILEPDF_UPDATE_SUCCESS = 'FILEPDF_UPDATE_SUCCESS'
export const FILEPDF_UPDATE_FAIL = 'FILEPDF_UPDATE_FAIL'
export const FILEPDF_UPDATE_RESET = 'FILEPDF_UPDATE_RESET'

export const FILEPDF_CATEGORY_REQUEST = 'FILEPDF_TOP_REQUEST'
export const FILEPDF_CATEGORY_SUCCESS = 'FILEPDF_TOP_SUCCESS'
export const FILEPDF_CATEGORY_FAIL = 'FILEPDF_TOP_FAIL'