import React, {useEffect} from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ListGroup, Image, Form, Button, Card, Container } from 'react-bootstrap';
import Message from '../components/Message';
import { addToCart, removeFromCart } from '../actions/cartActions';
import { listDiscounts } from '../actions/discountActions';
import NumberFormat from 'react-number-format';

function CartScreen() {
  const navigate = useNavigate();
  const {id} = useParams()
  const productId = id
  const location = useLocation()
  const qty = location.search ? Number(location.search.split('=')[1]) : 1
  
  const dispatch = useDispatch()

  const cart = useSelector(state => state.cart)
  const { cartItems } = cart

  const discountList = useSelector(state => state.discountList)
  const { discounts } = discountList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  /*const makediscount3 = discountItem => discountItem.name === 'Descuento Combo 3 productos' ? discountItem.disc : '';
  const combo3 = discounts.map(makediscount3);
  let comboFinal3 = 0
  if (combo3[0] === '') {
      comboFinal3 = combo3[1]
  }else{
      comboFinal3 = combo3[0]
  }

  const makediscount2 = discountItem => discountItem.name === 'Descuento Combo 2 productos' ? discountItem.disc : '';
  const combo2 = discounts.map(makediscount2);
  let comboFinal2 = 0
  if (combo2[0] === '') {
      comboFinal2 = combo2[1]
  }else{
      comboFinal2 = combo2[0]
  }

  const descuento3 = cartItems.reduce((acc, item) => acc + item.qty * (item.pricereg - ((item.pricereg / 100) * item.discount)), 0)
  const desc3Final = descuento3 - ((comboFinal3 / 100) * descuento3)

  const descuento2 = cartItems.reduce((acc, item) => acc + item.qty * (item.pricereg - ((item.pricereg / 100) * item.discount)), 0)
  const desc2Final = descuento2 - ((comboFinal2 / 100) * descuento2)*/

  useEffect(() => {
    if(productId) {
      dispatch(addToCart(productId, qty))
    }

    dispatch(listDiscounts())

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
  }, [dispatch,  productId, qty])

  const removeFromCartHandler = (id) => {
      dispatch(removeFromCart(id))
  }

  const checkoutHandler = () => {
    //navigate('/login?redirect=shipping')
    navigate('/shipping')
  }

  /*let catArray = []
  const category3Handler = () => {
      for (let i = 0; i < cartItems.length; i++) {
          catArray.push(cartItems[i].category)
      }

      const categorias1 = ['Parrilla Gas', 'Campana Extractora', 'Horno Gas']
      const categorias2 = ['Induccion Magnetica', 'Campana Extractora', 'Horno Gas']
      const categorias3 = ['Parrilla Electrica', 'Campana Extractora', 'Horno Gas']

      const equals = (a, b) =>
      a.length === b.length &&
      a.every((v, i) => v === b[i]);

      if(equals(catArray, categorias1)){
          catArray = []
          return true
      }else if (equals(catArray, categorias2)) {
          catArray = []
          return true
      }else if (equals(catArray, categorias3)) {
          catArray = []
          return true
      }else{
          return false
      }
  }

  let catArray2 = []
  const category2Handler = () => {
      for (let i = 0; i < cartItems.length; i++) {
          catArray2.push(cartItems[i].category)
      }

      const category1 = ['Parrilla Gas', 'Campana Extractora']
      const category2 = ['Induccion Magnetica', 'Campana Extractora']
      const category3 = ['Parrilla Electrica', 'Campana Extractora']

      const equals = (a, b) =>
      a.length === b.length &&
      a.every((v, i) => v === b[i]);

      if(equals(catArray2, category1)){

          catArray = []
          return true
      }else if (equals(catArray2, category2)) {

          catArray = []
          return true
      }else if (equals(catArray2, category3)) {

          catArray = []
          return true
      }else{

          return false
      }

  }*/

  return (
    <div className='container'>
    <Row>
        <Col md={8}>
          <Link className='btn btn-light my-3' to='/'>Go Back</Link>        
          <p className='fs-2 fw-bold my-3'>Carrito de Compra</p>
          {cartItems.length === 0 ? (
            <Message variant='warning' className='fw-bold text-dark'>
              El carro esta vacío<Link to='/' className='mx-2 text-decoration-none text-warning fw-bold text-dark' style={{fontFamily: "Quicksand"}} >Go Back</Link>
            </Message>
          ) : (
            <ListGroup variant='flush' className='mt-1'>
              {cartItems.map(item => (
                <ListGroup.Item key={item.product}>
                  <Row>
                    <div className='col-sm-3'>
                      <Image src={item.image1} alt={item.name} fluid rounded/>
                    </div>
                    <div className='col-sm-3 d-flex justify-content-center align-items-center'>
                      <Link to={`/product/${item.product}`} className="text-decoration-none fs-3 fst-italic fw-bold" style={{ color: '#68bb7d'}}>{item.name}</Link>
                    </div>
                    <div className='col-sm-2 d-flex justify-content-center align-items-center'>
                      <b className='fs-3'><NumberFormat value={Number((item.pricereg - ((item.pricereg / 100) * item.discount))).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/></b>
                    </div>
                    <div className='col-sm-2 d-flex justify-content-center align-items-center'>
                            <Form.Control 
                            as="select" 
                            value={item.qty}
                            onChange={(e) => dispatch(addToCart(item.product, Number(e.target.value)))}
                            className='fs-4 fw-bold'
                        >
                            {
                                [...Array(item.countInStock).keys()].map((x) => (
                                    <option key={ x + 1 } value={ x + 1 }>
                                        {x + 1}
                                    </option>
                                ))
                            }
                        </Form.Control>
                      </div>
                      <div className='col-sm-1 d-flex justify-content-center align-items-center'>
                            <Button 
                              type='button' 
                              variant='light'
                              onClick={() => removeFromCartHandler(item.product)}
                              >
                              <i className='fas fa-trash fs-4'></i> 
                            </Button>
                      </div>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col md={4}>
          <Card className='mt-4'>
              <ListGroup variant='flush'>
                <ListGroup.Item>
                  <p className='fs-2 fw-bold'>Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}) productos</p>
                  <b className='fs-3'><NumberFormat value={cartItems.reduce((acc, item) => acc + item.qty * (item.pricereg - ((item.pricereg / 100) * item.discount)), 0).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} className='fw-bold'/> MN</b>
                </ListGroup.Item>
              </ListGroup>
              {userInfo && (
                <ListGroup>
                <ListGroup.Item className='d-flex justify-content-center align-items-center'>
                  <Button
                    type='button'
                    className='btn btn-block bg-success fs-4'
                    disabled={cartItems.length === 0}
                    onClick={checkoutHandler}
                    style={{fontFamily: "Quicksand"}}
                  >
                    Proceder a la Compra
                  </Button>
                </ListGroup.Item>
              </ListGroup>
              )}
          </Card>
        </Col>
    </Row>
    </div>
  )
}

export default CartScreen