import axios from 'axios'
import { 
    SUBSCRIBE_LIST_REQUEST, 
    SUBSCRIBE_LIST_SUCCESS, 
    SUBSCRIBE_LIST_FAIL,
    SUBSCRIBE_DETAILS_REQUEST, 
    SUBSCRIBE_DETAILS_SUCCESS, 
    SUBSCRIBE_DETAILS_FAIL,
    SUBSCRIBE_DELETE_REQUEST, 
    SUBSCRIBE_DELETE_SUCCESS, 
    SUBSCRIBE_DELETE_FAIL,
    SUBSCRIBE_CREATE_REQUEST, 
    SUBSCRIBE_CREATE_SUCCESS, 
    SUBSCRIBE_CREATE_FAIL,
    SUBSCRIBE_UPDATE_REQUEST, 
    SUBSCRIBE_UPDATE_SUCCESS, 
    SUBSCRIBE_UPDATE_FAIL,

} from '../constants/subscribeConstants';

export const listSubscribes = () => async (dispatch) => {
    try{
        dispatch({type: SUBSCRIBE_LIST_REQUEST })
        const { data } = await axios.get('/api/subscribes/')

        dispatch({
            type: SUBSCRIBE_LIST_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({
            type: SUBSCRIBE_LIST_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })        
    }
}

export const listSubscribeDetails = (id) => async (dispatch) => {
    try{
        dispatch({
            type: SUBSCRIBE_DETAILS_REQUEST
        })
        const {data} = await axios.get(`/api/subscribes/${id}`)

        dispatch({
            type: SUBSCRIBE_DETAILS_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: SUBSCRIBE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteSubscribe = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUBSCRIBE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/subscribes/delete/${id}/`,
            config
        )

        dispatch({
            type: SUBSCRIBE_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: SUBSCRIBE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createSubscribe = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUBSCRIBE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/subscribes/create/`,
            {},
            config
        )

        dispatch({
            type: SUBSCRIBE_CREATE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SUBSCRIBE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateSubscribe = (subscribe) => async (dispatch, getState) => {
    try {
        dispatch({
            type: SUBSCRIBE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/subscribes/update/${subscribe._id}/`,
            subscribe,
            config
        )

        dispatch({
            type: SUBSCRIBE_UPDATE_SUCCESS,
            payload: data
        })

        dispatch({ 
            type: SUBSCRIBE_DETAILS_SUCCESS, 
            payload: data
        })


    } catch (error) {
        dispatch({
            type: SUBSCRIBE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}