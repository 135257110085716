export const EDUCATION_LIST_REQUEST = 'EDUCATION_LIST_REQUEST'
export const EDUCATION_LIST_SUCCESS = 'EDUCATION_LIST_SUCCESS'
export const EDUCATION_LIST_FAIL = 'EDUCATION_LIST_FAIL'

export const EDUCATION_DETAILS_REQUEST = 'EDUCATION_DETAILS_REQUEST'
export const EDUCATION_DETAILS_SUCCESS = 'EDUCATION_DETAILS_SUCCESS'
export const EDUCATION_DETAILS_FAIL = 'EDUCATION_DETAILS_FAIL'

export const EDUCATION_DELETE_REQUEST = 'EDUCATION_DELETE_REQUEST'
export const EDUCATION_DELETE_SUCCESS = 'EDUCATION_DELETE_SUCCESS'
export const EDUCATION_DELETE_FAIL = 'EDUCATION_DELETE_FAIL'

export const EDUCATION_CREATE_REQUEST = 'EDUCATION_CREATE_REQUEST'
export const EDUCATION_CREATE_SUCCESS = 'EDUCATION_CREATE_SUCCESS'
export const EDUCATION_CREATE_FAIL = 'EDUCATION_CREATE_FAIL'
export const EDUCATION_CREATE_RESET = 'EDUCATION_CREATE_RESET'

export const EDUCATION_UPDATE_REQUEST = 'EDUCATION_UPDATE_REQUEST'
export const EDUCATION_UPDATE_SUCCESS = 'EDUCATION_UPDATE_SUCCESS'
export const EDUCATION_UPDATE_FAIL = 'EDUCATION_UPDATE_FAIL'
export const EDUCATION_UPDATE_RESET = 'EDUCATION_UPDATE_RESET'
