import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap';
import CheckoutSteps from '../components/CheckoutSteps';
import Message from '../components/Message';
import { createOrder } from '../actions/orderActions'
import { listDiscounts } from '../actions/discountActions';
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import NumberFormat from 'react-number-format';

function PlaceOrderScreen() {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart)
    const orderCreate = useSelector(state => state.orderCreate)
    const { order, success, error } = orderCreate
    const navigate = useNavigate()

    cart.itemsPrice = cart.cartItems.reduce((acc, item) => acc + (item.pricereg - ((item.pricereg / 100) * item.discount)) * item.qty, 0).toFixed(2)
    cart.shippingPrice = (cart.itemsPrice > 150 ? 0 : 0).toFixed(2)
    cart.taxPrice = Number((0.16) * cart.itemsPrice).toFixed(2)
    cart.totalPrice = (Number(cart.itemsPrice) + Number(cart.shippingPrice))
    
    if(!cart.paymentMethod){
        navigate('/payment')
    }

    useEffect(() => {
        if(success){
            navigate(`/order/${order._id}`)
            dispatch({ type: ORDER_CREATE_RESET })
        }
    }, [success, navigate])

    const placeOrderHandler = () => {
        dispatch(createOrder({
            orderItems: cart.cartItems,
            shippingAddress: cart.shippingAddress,
            invoiceData: cart.invoiceData,
            paymentMethod: cart.paymentMethod,
            itemsPrice: cart.itemsPrice,
            shippingPrice: cart.shippingPrice,
            taxPrice: cart.taxPrice,
            totalPrice: cart.totalPrice,
        }))
    }

    return (
     <Container>
        <div>
        <CheckoutSteps step1 step2 step3 step4 />
        <Row>
            <Col md={8} >
                <ListGroup variant='flush'>
                    <ListGroup.Item>
                        <p className='fs-2 fw-bold mt-1'>Datos de Envío</p>
                        <p className='fs-4 fw-bolder'>
                            <strong>Enviar a : </strong>
                            {cart.shippingAddress.address} {cart.shippingAddress.city}
                            {' '},
                            {cart.shippingAddress.postalCode},
                            {' '}
                            {cart.shippingAddress.country}                        
                        </p>
                    </ListGroup.Item>
                    <ListGroup.Item className='my-2'>
                    <p className='fs-2 fw-bold mt-1'>Datos de Facturación</p>
                        <p className='fs-4 my-2 fw-bolder'>
                            <strong>Facturar a : </strong>
                            <b>RFC:</b> {cart.invoiceData.rfc}
                            {' '},
                            <b>RS:</b>{cart.invoiceData.socialname}, <b>Tel:</b>{cart.invoiceData.phone}
                            {' '},
                            {cart.invoiceData.address}, {cart.invoiceData.city}
                            {' '},
                            {cart.invoiceData.postalCode},
                            {' '}
                            {cart.invoiceData.country},
                            {' '}
                            <b>Email:</b>{cart.invoiceData.email}, <b>CFDI:</b>{cart.invoiceData.cfdi},
                            {' '}
                            <b>FP:{cart.invoiceData.payment}</b>                        
                        </p>
                    </ListGroup.Item>
                    
                    <ListGroup.Item className='my-2'>
                        <p className='fs-2 fw-bold mt-1'>Metodo de Pago</p>
                        <p className='fs-4 fw-bolder'>
                            <strong>Metodo : </strong>
                            {cart.paymentMethod}                        
                        </p>
                     </ListGroup.Item>

                       <ListGroup.Item className='my-2'>
                        <p className='fs-2 fw-bold mt-1'>Articulos de la Orden</p>
                        {cart.cartItems.length === 0 ? <Message variant='info'>
                            Tu carro está vacío
                        </Message> : (
                            <ListGroup variant='flush'>
                                {cart.cartItems.map((item, index) => (
                                    <ListGroup.Item key={index}>
                                        <Row>
                                            <Col md={2}>
                                                <Image src={item.image1} alt={item.name} fluid rounded/>
                                            </Col>
                                            <Col md={4} className='d-flex justify-content-center align-items-center fs-3'>
                                                <Link to={`/product/${item.product}`} style={{ color: '#59ab6e'}} className='fst-italic fw-bolder text-decoration-none fs-3'>{item.name}</Link>  
                                            </Col>
                                            <Col md={4} className='fw-bolder d-flex justify-content-center align-items-center fs-3'>
                                                <span className='mx-1'>{item.qty}</span> x {<NumberFormat value={(item.qty * (item.pricereg - ((item.pricereg / 100) * item.discount))).toFixed(2)} displayType={'text'} thousandSeparator={true} />} = <NumberFormat value={(item.qty * (item.pricereg - ((item.pricereg / 100) * item.discount))).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                     </ListGroup.Item>

                    
                </ListGroup>
            </Col>
            <Col md={4}>
                <Card>
                        <ListGroup variant='flush' className='fs-5'>
                        <ListGroup.Item>
                           <p className='fs-3 fw-bold mt-1'>Resumen de Orden</p>
                          {cart.itemsPrice < 2000 ? <Message variant='info' className='fs-6'><b>Pedido mayor a $2000.00 pesos costo envío gratis</b></Message> : ''}                          
                        </ListGroup.Item>
                        <ListGroup.Item>
                           <Row>
                              <Col className='fw-bolder fs-4'>Subtotal:</Col>
                              <Col className='fw-bolder fs-4'><b><NumberFormat value={cart.itemsPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b></Col>
                           </Row>          
                        </ListGroup.Item>
                        <ListGroup.Item>
                           <Row>
                              <Col className='fw-bolder fs-4' >Costo Envío:</Col>
                              <Col className='fw-bolder fs-4'><b>${cart.shippingPrice}</b></Col>
                           </Row>          
                        </ListGroup.Item>
                        <ListGroup.Item>
                             <Row>
                                 <Col className='fw-bolder fs-4'>Total:</Col>
                                 <Col className='fw-bolder fs-4'><b><NumberFormat value={(cart.totalPrice).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></b></Col>
                             </Row>          
                        </ListGroup.Item>
                        <ListGroup.Item>
                           {error && <Message variant='danger'>{error}</Message>}
                        </ListGroup.Item>
                        </ListGroup>

                       <ListGroup.Item className='d-flex justify-content-center align-items-center'>
                           <Button 
                           type='button' 
                           variant='primary' 
                           className='btn-md my-2 btn-block bg-success fs-4' 
                           disabled={cart.cartItems === 0}
                           onClick={placeOrderHandler}
                           style={{fontFamily: "Quicksand"}}
                           >Colocar la Orden</Button>
                       </ListGroup.Item>                                                                                        

                </Card>
            </Col>            
        </Row>
    </div>
    </Container>

  )
}

export default PlaceOrderScreen