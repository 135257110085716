export const HOLISTIC_LIST_REQUEST = 'HOLISTIC_LIST_REQUEST'
export const HOLISTIC_LIST_SUCCESS = 'HOLISTIC_LIST_SUCCESS'
export const HOLISTIC_LIST_FAIL = 'HOLISTIC_LIST_FAIL'

export const HOLISTIC_DETAILS_REQUEST = 'HOLISTIC_DETAILS_REQUEST'
export const HOLISTIC_DETAILS_SUCCESS = 'HOLISTIC_DETAILS_SUCCESS'
export const HOLISTIC_DETAILS_FAIL = 'HOLISTIC_DETAILS_FAIL'

export const HOLISTIC_DELETE_REQUEST = 'HOLISTIC_DELETE_REQUEST'
export const HOLISTIC_DELETE_SUCCESS = 'HOLISTIC_DELETE_SUCCESS'
export const HOLISTIC_DELETE_FAIL = 'HOLISTIC_DELETE_FAIL'

export const HOLISTIC_CREATE_REQUEST = 'HOLISTIC_CREATE_REQUEST'
export const HOLISTIC_CREATE_SUCCESS = 'HOLISTIC_CREATE_SUCCESS'
export const HOLISTIC_CREATE_FAIL = 'HOLISTIC_CREATE_FAIL'
export const HOLISTIC_CREATE_RESET = 'HOLISTIC_CREATE_RESET'

export const HOLISTIC_UPDATE_REQUEST = 'HOLISTIC_UPDATE_REQUEST'
export const HOLISTIC_UPDATE_SUCCESS = 'HOLISTIC_UPDATE_SUCCESS'
export const HOLISTIC_UPDATE_FAIL = 'HOLISTIC_UPDATE_FAIL'
export const HOLISTIC_UPDATE_RESET = 'HOLISTIC_UPDATE_RESET'

export const HOLISTIC_CATEGORY_REQUEST = 'HOLISTIC_TOP_REQUEST'
export const HOLISTIC_CATEGORY_SUCCESS = 'HOLISTIC_TOP_SUCCESS'
export const HOLISTIC_CATEGORY_FAIL = 'HOLISTIC_TOP_FAIL'