import React, { useState, useEffect } from 'react'
import logo from '../assets/images/LOGOUNOMASUNO.png'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { logout } from '../actions/userActions'
import { listContacts } from '../actions/contactActions'
import { listSubscribes } from '../actions/subscribeActions'


function Header() {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const cart = useSelector(state => state.cart)
  const { cartItems } = cart

  const contactList = useSelector(state => state.contactList)
  const { contacts } = contactList

  const subscribeList = useSelector(state => state.subscribeList)
  const { subscribes } = subscribeList  
  
  const logoutHandler = () => {
    dispatch(logout())
  }

  useEffect(() => {
     
      dispatch(listContacts())
      dispatch(listSubscribes())
  
    }, [dispatch, navigate, userInfo])

  return (
    <header>
    <nav class="navbar navbar-expand-lg d-none d-lg-block mt-0" id="templatemo_nav_top" style={{backgroundColor: '#b1e4e3', color: '#fff'}}>

    <div class="w-100 d-flex justify-content-between" >
        <div class='ms-4' >
            <i class="fa fa-envelope text-light"></i>
            <a class="navbar-sm-brand text-dark text-decoration-none ms-1" href="mailto:info@ecofertas.com"><b className='h5'>lmadrigal@unomasunoestres.com</b></a>
            <i class="fa fa-phone mx-2"></i>
            <a class="navbar-sm-brand text-light text-decoration-none mx-1" href="tel:55-17923352"><b className='h5'>55-18059959</b></a>
            <a href="whatsapp://send?text=Hola buen día, me pudiera mandar información..." data-action="share/whatsapp/share"
            target="_blank" className='text-decoration-none text-light mx-4'> <i class="fab fa-whatsapp mx-2" aria-hidden="true"></i><b className='h5'>Comunicate...</b></a>
        </div>
        <div class='me-5' >
            <a class="text-dark" href="https://www.facebook.com/profile.php?id=100089545930787" rel="sponsored"><i class="fab fa-facebook-f fa-sm fa-fw me-2"></i></a>
            <a class="text-dark" href="https://www.instagram.com/uno_mas_uno_igual_a_tres/" ><i class="fab fa-instagram fa-sm fa-fw me-2"></i></a>
        </div>
    </div>

</nav>
    <Navbar bg="light" expand="xl" collapseOnSelect >
      <a href="/" class="navbar-brand ms-2" ><img src={logo} alt='Logo MC' resizeMode='contain' className='img-fluid logo' style={{width: 220}}/></a>
      <Navbar.Toggle aria-controls="basic-navbar-nav" className='me-1 fw-bold'/>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto me-5 nav-tabs fw-bold">
        <LinkContainer to='/'>
            <Nav.Link><span className='mx-3'><b>Inicio</b></span></Nav.Link>                
        </LinkContainer>
        <LinkContainer to='/bio'>
            <Nav.Link><span className='mx-3'><b>Bio</b></span></Nav.Link>                
        </LinkContainer>
        <LinkContainer to='/porque'>
            <Nav.Link><span className='mx-3'><b>Porqué</b></span></Nav.Link>                
        </LinkContainer>
        <NavDropdown title={ <span className='mx-3'><b>Secciones</b></span> } id='adminmenu'>
            <LinkContainer to='/cocina' className='colorMenu fs-3'>
                <NavDropdown.Item><span className='mx-3'><b>Lalola Cocina Gourmet</b></span></NavDropdown.Item>                
            </LinkContainer>
            <LinkContainer to='/natural' className='colorMenu fs-3'>
                <NavDropdown.Item><span className='mx-3'><b>Lalola Natural</b></span></NavDropdown.Item>                
            </LinkContainer>
            <LinkContainer to='/holistic' className='colorMenu fs-3'>
                <NavDropdown.Item><span className='mx-3'><b>Ser Holístico</b></span></NavDropdown.Item>                
            </LinkContainer>                                                             
            <LinkContainer to='/podcasts' className='colorMenu fs-3'>
                <NavDropdown.Item><span className='mx-3'><b>Podcast</b></span></NavDropdown.Item>                
            </LinkContainer>
            <LinkContainer to='/foro' className='colorMenu fs-3'>
                <NavDropdown.Item><span className='mx-3'><b>Foro</b></span></NavDropdown.Item>                
            </LinkContainer>
            <LinkContainer to='/servicios' className='colorMenu fs-3'>
                <NavDropdown.Item><span className='mx-3'><b>Servicios</b></span></NavDropdown.Item>                
            </LinkContainer>            
            <LinkContainer to='/recomend' className='colorMenu fs-3'>
                <NavDropdown.Item><span className='mx-3'><b>Recomendaciones</b></span></NavDropdown.Item>                
            </LinkContainer>
        </NavDropdown>

        <NavDropdown title={ <span className='mx-3'><b>Tienda</b></span> } id='adminmenu'>
            <LinkContainer to={'/shopCG'} className='colorMenu fs-3'>
                <NavDropdown.Item><b>Cocina Gourmet</b></NavDropdown.Item>
            </LinkContainer>
            <LinkContainer to={'/shopPN'} className='colorMenu fs-3'>
                <NavDropdown.Item><b>Productos Naturales</b></NavDropdown.Item>
            </LinkContainer>
        </NavDropdown>

        <LinkContainer to='/cart'>
        <Nav.Link>
            <i class="fa-solid fa-cart-shopping mx-3"></i>
            <span class="position-absolute top-10 left-100 translate-middle badge rounded-pill bg-light text-dark mx-3">{cartItems.reduce((acc, item) => acc + item.qty, 0) > 0 ? cartItems.reduce((acc, item) => acc + item.qty, 0) : ''}</span>
        </Nav.Link>
      </LinkContainer>                                                                
      <LinkContainer to='/contact'>
        <Nav.Link><span className='mx-3'><b>Contacto</b></span></Nav.Link>                
      </LinkContainer>                        
      {userInfo && userInfo.isAdmin && (
        <>
        <LinkContainer to='/admin/contactlist' className='menubarfont'>
            <Nav.Link >
                <i class="fa fa-fw fa-user text-dark mx-3"></i>
                <span class="position-absolute top-10 left-100 translate-middle badge rounded-pill bg-light text-dark">{contacts.length > 0 ? contacts.length : ''}</span>                        
            </Nav.Link>
        </LinkContainer>
        <LinkContainer to='/admin/subscribelist' className='menubarfont'>
            <Nav.Link >
            <i class="fa-solid fa-bell"></i>
            <span class="position-absolute top-10 left-100 translate-middle badge rounded-pill bg-light text-dark">{subscribes.length > 0 ? subscribes.length : ''}</span>                        
            </Nav.Link>
        </LinkContainer>
        </>
        
        )}

        <LinkContainer to='/login'>
            {userInfo ? (
                <NavDropdown title={<span className='mx-3'><b>{userInfo.name}</b></span>} id='username'>
                    <LinkContainer to={'/profile'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Profile</b></NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler} className='colorMenu fs-3'><b>Logout</b></NavDropdown.Item>                          
                </NavDropdown>
            ) : (
                <LinkContainer to='/login'>
                    <Nav.Link><i className='fas fa-user'></i><b>Login</b></Nav.Link>
                </LinkContainer>
            )
            }
        </LinkContainer>

        {
            userInfo && userInfo.isAdmin && (
                <NavDropdown title={ <span className='mx-4'><b>Admin</b></span> } id='adminmenu'>
                    <LinkContainer to={'/admin/userlist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Usuarios</b></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/admin/productlist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Productos</b></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/admin/orderlist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Ordenes</b></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/admin/contactlist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Mensajes</b></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/admin/podcastlist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Podcast</b></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/admin/forolist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Foro</b></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/admin/articlelist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Recomendaciones</b></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/admin/recipelist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Recetas</b></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/admin/holisticlist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Holístico</b></NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={'/admin/servicelist'} className='colorMenu fs-3'>
                        <NavDropdown.Item><b>Servicios</b></NavDropdown.Item>
                    </LinkContainer>                                                                                                                                 
                </NavDropdown>                    
            )
        }

        </Nav>
      </Navbar.Collapse>

  </Navbar>
    </header>
  )
}

export default Header
