import React, { useEffect, useState } from 'react';
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Moment from 'react-moment';
import { listProfiles } from '../actions/profileActions'
import { listPodcastDetails } from '../actions/podcastActions'
import AudioPlayer from "react-h5-audio-player";


function PodcastDetailScreen() {

  const { id } = useParams();

  const podcastDetails = useSelector(state => state.podcastDetails)
  const { loading, error, podcast } = podcastDetails

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const dispatch = useDispatch()
  let keyword = useLocation().search

  useEffect(() => {

    dispatch(listPodcastDetails(id))
    dispatch(listProfiles(keyword))

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [dispatch, keyword, id])

const [trackIndex, setTrackIndex] = useState(0);

const handleClickPrevious = () => {
  setTrackIndex((currentTrack) =>
    currentTrack === 0 ? podcast.length - 1 : currentTrack - 1
  );
};

const handleClickNext = () => {
  setTrackIndex((currentTrack) =>
    currentTrack < podcast.length - 1 ? currentTrack + 1 : 0
  );
};

  return (
    <div className="section1 container">
    <Link className='btn btn-light my-3' to='/podcasts'>Go Back</Link>
        {
            loading ? <Loader />
            : error ? <Message variant='danger'>{error}</Message>
            : (
            <div>
            <div class='row'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center'>
                    <Image src={podcast.image} alt={podcast.name} className="card-img-top my-2 img-fluid w-50 h-100" style={{ maxHeight: 550}}/>
                </div>
            </div>
            <div class='row'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center mb-1'>
                    <p className='my-1 fs-1 mt-3 fw-bold' style={{color: '#421d74'}}><b>{podcast.name}</b></p> 
                </div>
            </div>
            <Row>
              <Col lg={3}></Col>
              <Col lg={6} className='d-flex justify-content-center align-items-center'>
                  <p className='my-2 fs-4'><b>{podcast.comment}</b></p>
              </Col>
              <Col lg={3}></Col>
            </Row>
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
                <div class="p-2 bd-highlight align-items-center"><p className='fs-4 '><b>Creado por: {podcast.author}</b></p></div>
                <div class="p-2 bd-highlight align-items-center"><p className='fs-4'><b><Moment format="DD/MM/YYYY hh:mm:ss">{podcast.createdAt}</Moment></b></p></div>
            </div>    
            <Row>
                <Col lg={4}></Col>
                <Col lg={4} className="p-2 d-flex justify-content-center align-items-center">

                <AudioPlayer
                    // style={{ width: "300px" }}
                    style={{ borderRadius: "1rem", width: '600px', fontWeight: "bold", fontSize: "1.2rem", color: '#421d74' }}
                    layout="horizontal"
                    src={podcast.audio_file}
                    onPlay={(e) => console.log("onPlay")}
                    showSkipControls={true}
                    showJumpControls={false}
                    header={`Podcast: ${podcast.name}`}
                    footer={`Realizado por: ${podcast.author}`}
                    onClickPrevious={handleClickPrevious}
                    onClickNext={handleClickNext}
                    onEnded={handleClickNext}
                    className='mt-1 audioplayer fs-4'
                    // other props here
                />
                </Col>
                <Col lg={4}></Col>
            </Row>
            <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
              <div class="p-2 bd-highlight align-items-center"><p className='mb-4 fs-5 fw-bold'><strong>Transcripción:</strong> {podcast.transcription}</p></div>
            </div>

        </div>
          )
        }
    </div>


) 
}

export default PodcastDetailScreen
