import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Row, Col, Container} from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listSubscribes, deleteSubscribe } from '../actions/subscribeActions'
import image5 from '../assets/images/LOGOSOKLORENA.png'

function SubscribeListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const subscribeList = useSelector(state => state.subscribeList)
  const { loading, error, subscribes } = subscribeList

  const subscribeDelete = useSelector(state => state.subscribeDelete)
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = subscribeDelete

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let keyword = useLocation().search

  useEffect(() => {
    if(userInfo && userInfo.isAdmin){
      dispatch(listSubscribes())
    }else{
      navigate('/login');
    }
    
    dispatch(listSubscribes())

  }, [dispatch, navigate, userInfo, keyword, successDelete])

  const deleteHandler = (id) => {
    if(window.confirm('Estas seguro de borrar este correo?')){
      dispatch(deleteSubscribe(id))
    }
  }
  
  return (
    <Container>
    <div className='section1'>
        <Row className='align-items-center'>
            <Col md={6} class='col-sm-4 d-flex justify-content-start align-items-center mt-3'>
                <p className='text-dark-50 my-4 fs-1 fw-bold'>Subscripciones</p>
            </Col>
            <Col md={6} className="p-1 mb-1 d-flex justify-content-end align-items-center mt-3">
            <Link to='/bio' className='text-decoration-none text-success'>
                <img class="img-fluid d-block"
                src={image5} style={{width: 160}}/>
            </Link>                                   
            </Col>            
        </Row>
     
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

        {loading ? 
          (<Loader />)
        : error ?
          (<Message variant='danger'>{error}</Message>)
        : (
          <div>
            <Table striped bordered hover responsive className='table-lg text-dark fs-3 mt-3 fw-bold'>
              <thead className='table-primary'>
              <tr>
                <th>EMAIL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {subscribes.map(subscribe => (
                <tr key={subscribe._id}>
                  <td>{subscribe.email}</td>
                  <td>  
                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(subscribe._id)}>
                      <i className='fas fa-trash'></i>
                    </Button>                    
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        )  
      }
    </div>
    </Container>
  )
}

export default SubscribeListScreen