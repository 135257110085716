import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listForoDetails, updateForo } from '../actions/foroActions';
import FormContainer from '../components/FormContainer'
import { FORO_UPDATE_RESET } from '../constants/foroConstants'

function ForoEditScreen() {

    const { id } = useParams()
    const foroId = id

    const [name, setName] = useState('')
    const [image, setImage] = useState('')    
    const [author, setAuthor] = useState('')
    const [comment, setComment] = useState('')
        
    const [uploading, setUploading] = useState(false)    
                    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const location = useLocation()

    const foroDetails = useSelector(state => state.foroDetails)
    const { loading, foro, error } = foroDetails

    const foroUpdate = useSelector(state => state.foroUpdate)
    const { loading: loadingUpdate, success: successUpdate, error: errorUpdate } = foroUpdate    

    useEffect(() => {

        if(successUpdate){
            dispatch({ type: FORO_UPDATE_RESET })
            navigate(`/admin/forolist`)
        }else{
            if(!foro.name || foro._id !== Number(foroId)){
                dispatch(listForoDetails(foroId))
            }else{
                setName(foro.name)
                setImage(foro.image)
                setAuthor(foro.author)
                setComment(foro.comment)
            }
        }

    }, [foro, foroId, dispatch, navigate, successUpdate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateForo({
            _id: foroId,
            name,
            author,
            image,
            comment,
        }))
    }

    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('image', file)
        formData.append('foro_id', foroId)

        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/foros/upload/', formData, config)


            setImage(data)
            setUploading(false)
            

        } catch (error) {
            console.log(error)
            setUploading(false)
        }
    }
    
  return (
    <div className='section1 container'>
    <Link to='/admin/forolist' className='btn btn-light m-2'>
       Go Back
    </Link>
    <FormContainer>
      <p style={{fontFamily: "Quicksand"}} className='fs-2 my-5 fw-bold'>Editar Foro</p>
         {loadingUpdate && <Loader />}
         {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}  
         {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : 
         (
           <Form onSubmit={submitHandler} style={{fontFamily: "Quicksand"}}>
           <Form.Group controlId='name' className='my-2'>
               <Form.Label><p style={{fontFamily: "Quicksand"}} className='fs-3 fw-bolder'>Nombre</p></Form.Label>
               <Form.Control
                   type='name'
                   placeholder='Ingresa el nombre'
                   value={name}
                   onChange={(e) => setName(e.target.value)}
                   className='fs-3 fw-bolder'
               >
               </Form.Control>
           </Form.Group>

            <Form.Group controlId='author' className='my-2'>
                <Form.Label><p style={{fontFamily: "Quicksand"}} className='fs-3 fw-bolder'>Autor</p></Form.Label>
                <Form.Control
                    type='text'
                    placeholder={'Ingresa el Autor'}
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    className='fs-3 fw-bolder'
                >
                </Form.Control>
            </Form.Group>
           
           <Form.Group className='my-2'>
               <Form.Label><p style={{fontFamily: "Quicksand"}} className='fs-3 fw-bolder'>Imagen</p></Form.Label>
               <Form.Control
                   type='text'
                   placeholder='Ingresa la Imagen'
                   value={image}
                   onChange={(e) => setImage(e.target.value)}
                   className='fs-3 fw-bolder'
               >
               </Form.Control>

               <Form.Control
                   type='file'
                   id='image-file'
                   label='Choose Image'
                   custom
                   onChange={uploadFileHandler}
                   className='fs-3'
               >
               
               </Form.Control>
               {uploading && <Loader />}                    
           </Form.Group>
           
           <Form.Group controlId='comment' className='my-2'>
                <Form.Label><p style={{fontFamily: "Quicksand"}} className='fs-3 fw-bolder'>Comentarios</p></Form.Label>
                <Form.Control
                    as='textarea'
                    rows = {8}
                    placeholder='Ingresa los comentarios'
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    className='fs-3 fw-bolder'
                 >
                </Form.Control>
            </Form.Group>
           
           <Button type='submit' className='btn btn-info mt-2 fs-3' style={{fontFamily: "Quicksand"}}>Actualizar</Button>
       </Form>
     )} 
 </FormContainer>      
</div>
  )
}

export default ForoEditScreen