import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col, Table, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import Loader from '../components/Loader';
import Message from '../components/Message';
import { getUserDetails, updateUserProfile } from '../actions/userActions';
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { listMyOrders } from '../actions/orderActions'
import NumberFormat from 'react-number-format';
import Moment from 'moment';


function ProfileScreen() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile

    const orderListMy = useSelector(state => state.orderListMy)
    const { loading: loadingOrders, error: errorOrders, orders } = orderListMy

    useEffect(() => {
        if(!userInfo){
            navigate('/login')
        } else {
            if(!user || !user.name || success || userInfo._id !== user._id){
                dispatch({ type: USER_UPDATE_PROFILE_RESET })
                dispatch(getUserDetails('profile'))
                dispatch(listMyOrders())
            } else {
                setName(user.name)
                setEmail(user.email)
            }
        }
    }, [dispatch, navigate, userInfo, user, success])

    const submitHandler = (e) => {
        e.preventDefault()
        if(password !== confirmPassword){
            setMessage('Passwords do not match')
        }else{
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'email': email,
                'password': password
            }))
            setMessage('')            
        }
    }

  return (
    <Container>
            <Row>
              <Col md={3}>
                <p className='mt-4 fs-2 fw-bold'>Profile de Usuario</p>

                {message && <Message variant='danger'>{message}</Message>}
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='name'>
                        <Form.Label><p className='fs-3 fw-bold mt-2'>Nombre</p></Form.Label>
                        <Form.Control
                            required
                            type='name'
                            placeholder='Enter name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className='fs-3'
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='email'>
                        <Form.Label><p className='fs-3 fw-bold mt-3'>E-Mail</p></Form.Label>
                        <Form.Control
                            required
                            type='email'
                            placeholder='Enter Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className='fs-3'
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='password'>
                        <Form.Label><p className='fs-3 fw-bold mt-3'>Password</p></Form.Label>
                        <Form.Control

                            type='password'
                            placeholder='Enter Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='fs-3'
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='passwordConfirm'>
                        <Form.Label><p className='fs-3 fw-bold mt-3'>Confirmar Password</p></Form.Label>
                        <Form.Control

                            type='password'
                            placeholder='Confirm Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className='fs-3'
                        >
                        </Form.Control>
                    </Form.Group>

                    <Button className='btn-md mt-3 bg-success fs-3' type='submit' variant='primary' style={{fontFamily: 'Quicksand'}}>
                        Update
                    </Button>
                </Form>
            </Col>
        <Col md={9}>
            <p className='mt-4 fs-2 fw-bold text-center'>Mis Ordenes</p>
            {loadingOrders ? (
                <Loader />
            ) : errorOrders ? (
                <Message variant='danger'>{errorOrders}</Message>
            ) : (
                <Table striped responsive className='table-lg text-dark fs-3 mt-3 fw-bold'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Fecha</th>
                            <th>Total</th>
                            <th>Pagado</th>
                            <th>Entregado</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map(order => (
                            <tr key={order._id}>
                                <td>{order._id}</td>
                                <td className='fw-bolder'>{order.createdAt = Moment().format("MMMM Do YYYY, h:mm:ss a")}</td>
                                <td><NumberFormat value={order.totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} className='fw-bolder'/></td>
                                <td className='fw-bolder'>{order.isPaid ? order.paidAt = Moment().format("MMMM Do YYYY, h:mm:ss a") : (
                                    <i className='fas fa-times' style={{ color: 'red'}}></i>
                                )}</td>
                                <td className='fw-bolder'>{order.isDelivered ? order.deliveredAt.substring(0,10) : (
                                    <i className='fas fa-times' style={{ color: 'red'}}></i>
                                )}</td>                                
                                <td>
                                    <LinkContainer to={`/order/${order._id}`}>
                                        <Button className='btn-sm bg-success' style={{fontFamily: 'Quicksand'}}>Detalles</Button>
                                    </LinkContainer>
                                </td>                            
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
        </Col>
    
    </Row>
    </Container>
  )
}

export default ProfileScreen
