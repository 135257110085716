import { Container } from 'react-bootstrap'
import React from 'react';
import {HashRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen';
import YogaScreen from './screens/YogaScreen';
import AboutScreen from './screens/AboutScreen';
import ContactScreen from './screens/ContactScreen';
import PorqueScreen from './screens/PorqueScreen';
import NaturalScreen from './screens/NaturalScreen';
import LaCocinaScreen from './screens/LaCocinaScreen';
import HolisticoScreen from './screens/HolisticoScreen';
import RecomendScreen from './screens/RecomendScreen';
import ForoScreen from './screens/ForoScreen';
import ForoDetailScreen from './screens/ForoDetailScreen';
import ForoEditScreen from './screens/ForoEditScreen';
import ForoListScreen from './screens/ForoListScreen';
import PodcastScreen from './screens/PodcastScreen';
import BioScreen from './screens/BioScreen';
import ProductScreen from './screens/ProductScreen';
import ShopScreenCG from './screens/ShopScreenCG';
import ShopScreenPN from './screens/ShopScreenPN';
import CartScreen from './screens/CartScreen'
import ShippingScreen from './screens/ShippingScreen'
import InvoiceScreen from './screens/InvoiceScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import LoginScreen from './screens/LoginScreen';
import ProfileScreen from './screens/ProfileScreen';
import RegisterScreen from './screens/RegisterScreen';
import UserListScreen from './screens/UserListScreen'
import EditUserScreen  from './screens/EditUserScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import ProductListScreen from './screens/ProductListScreen'
import OrderListScreen from './screens/OrderListScreen';
import ContactListScreen from './screens/ContactListScreen';
import PodcastEditScreen from './screens/PodcastEditScreen';
import PodcastListScreen from './screens/PodcastListScreen';
import PodcastDetailScreen from './screens/PodcastDetailScreen';
import BlogDetailScreen from './screens/BlogDetailScreen';
import BlogEditScreen from './screens/BlogEditScreen';
import BlogListScreen from './screens/BlogListScreen';
import RecipeEditScreen from './screens/RecipeEditScreen'
import RecipeListScreen from './screens/RecipeListScreen'
import RecipeScreenCG from './screens/RecipeScreenCG'
import RecipeScreenPN from './screens/RecipeScreenPN'
import RecipeDetailScreen from './screens/RecipeDetailScreen'
import HolisticoArtScreen from './screens/HolisticoArtScreen';
import HolisticoDetailScreen from './screens/HolisticoDetailScreen';
import HolisticoListScreen from './screens/HolisticoListScreen';
import HolisticoEditScreen from './screens/HolisticoEditScreen';
import SubscribeListScreen from './screens/SubscribeListScreen';
import ServiceScreen from './screens/ServiceScreen';
import ServiceArtScreen from './screens/ServiceArtScreen'
import ServiceDetailScreen from './screens/ServiceDetailScreen'
import ServiceListScreen from './screens/ServiceListScreen'
import ServiceEditScreen from './screens/ServiceEditScreen'

function App() {
  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path='/' element={<HomeScreen />} exact />
          <Route path='/yoga' element={<YogaScreen />} />
          <Route path='/about' element={<AboutScreen />} />
          <Route path='/contact' element={<ContactScreen />} />
          <Route path='/subscribe' element={<SubscribeListScreen />} />          
          <Route path='/porque' element={<PorqueScreen />} />
          <Route path='/natural' element={<NaturalScreen />} />                            
          <Route path='/cocina' element={<LaCocinaScreen />} />          
          <Route path='/holistic' element={<HolisticoScreen />} />
          <Route path='/holisticart' element={<HolisticoArtScreen />} />
          <Route path='/holistic/:id' element={<HolisticoDetailScreen />} />
          <Route path='/service' element={<ServiceScreen />} />
          <Route path='/servicecart' element={<ServiceArtScreen />} />
          <Route path='/service/:id' element={<ServiceDetailScreen />} />                              
          <Route path='/recomend' element={<RecomendScreen />} />
          <Route path='/recipeCG' element={<RecipeScreenCG />} />
          <Route path='/recipePN' element={<RecipeScreenPN />} />
          <Route path='/recipe/:id' element={<RecipeDetailScreen />} />                   
          <Route path='/article/:id' element={<BlogDetailScreen />} />
          <Route path='/foro' element={<ForoScreen />} />
          <Route path='/foros/:id' element={<ForoDetailScreen />} />
          <Route path='/bio' element={<BioScreen />} />
          <Route path='/login' element={<LoginScreen />}/>
          <Route path='/register' element={<RegisterScreen />}/>
          <Route path='/profile' element={<ProfileScreen />}/>          
          <Route path='/product/:id' element={<ProductScreen />} />
          <Route path='/shopCG' element={<ShopScreenCG />} />
          <Route path='/shopPN' element={<ShopScreenPN />} />
          <Route path='/cart' element={<CartScreen />} />
          <Route path='/cart/:id' element={<CartScreen />}/>
          <Route path='/shipping' element={<ShippingScreen />}/>
          <Route path='/invoice' element={<InvoiceScreen />}/>                
          <Route path='/payment' element={<PaymentScreen />}/>
          <Route path='/placeorder' element={<PlaceOrderScreen />}/>
          <Route path='/order/:id' element={<OrderScreen />}/>
          <Route path='/podcasts' element={<PodcastScreen />} />
          <Route path='/podcasts/:id' element={<PodcastDetailScreen />} />
          <Route path='/servicios' element={<ServiceScreen />} />          
          <Route path='/admin/userlist' element={<UserListScreen />}/>
          <Route path='/admin/user/:id/edit' element={<EditUserScreen />}/>
          <Route path='/admin/productlist' element={<ProductListScreen />}/>
          <Route path='/admin/product/:id/edit' element={<ProductEditScreen />}/>
          <Route path='/admin/orderlist' element={<OrderListScreen />}/>
          <Route path='/admin/contactlist' element={<ContactListScreen />}/>
          <Route path='/admin/subscribelist' element={<SubscribeListScreen />}/>          
          <Route path='/admin/podcastlist' element={<PodcastListScreen />}/>
          <Route path='/admin/podcast/:id/edit' element={<PodcastEditScreen />}/>
          <Route path='/admin/forolist' element={<ForoListScreen />}/>
          <Route path='/admin/foro/:id/edit' element={<ForoEditScreen />}/>
          <Route path='/admin/articlelist' element={<BlogListScreen />}/>
          <Route path='/admin/article/:id/edit' element={<BlogEditScreen />}/>
          <Route path='/admin/recipelist' element={<RecipeListScreen />}/>
          <Route path='/admin/recipe/:id/edit' element={<RecipeEditScreen />}/>
          <Route path='/admin/holisticlist' element={<HolisticoListScreen />}/>
          <Route path='/admin/holistic/:id/edit' element={<HolisticoEditScreen />}/>
          <Route path='/admin/servicelist' element={<ServiceListScreen />}/>
          <Route path='/admin/service/:id/edit' element={<ServiceEditScreen />}/>                                                 
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
