import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { listByCategorycg, listRecipesCG } from '../actions/recipeActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import image5 from '../assets/images/LOGOSOKLORENA.png'
import Recipe from '../components/Recipe';
import SearchBoxRecipeCG from '../components/SearchBoxRecipeCG';

function RecipeScreenCG() {

  const dispatch = useDispatch()

  let navigate = useNavigate();
  let keyword = useLocation().search

  const [categoryval, setCategoryval] = useState('Cocina Gourmet')

  const recipeList = useSelector(state => state.recipeList)
  const { error, loading, recipes } = recipeList

  useEffect(() => {

      dispatch(listRecipesCG(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])

  let handleChange = (e) => {
    console.log(e.target.value)
    navigate(`/recipe/${e.target.value}`)
  }

  return (
    <div class='container'>
      <section className='p-1 text-center text-sm-start'>
          <Row className='align-items-center justify-content-around text-center mt-2'>
            <Col lg={4} className='d-flex justify-content-start align-items-center mt-1'>
              <p className='fs-2 fw-bold fst-italic' style={{color: '#596488'}}><b>Recetas o Artículos Cocina Gourmet</b></p>
            </Col>
          <Col md={6} className="p-1 mb-2 d-flex justify-content-center align-items-center">
              <SearchBoxRecipeCG />
          </Col>
          <Col md={2} className="p-1 mb-2 d-flex justify-content-end align-items-center">
            <Link to='/bio' className='text-decoration-none text-success'>
                <img class="img-fluid d-block"
                src={image5} style={{width: 160}}/>
            </Link>
          </Col>          
        </Row>
          <hr className='text-info'/>
          <Row className='my-4'>
            <Col md={3} className='d-flex justify-content-start align-items-center fs-3 fw-bolder'>
                <p>Seleccione la opción deseada</p>
            </Col>
            <Col md={7} className='d-flex justify-content-start aligm-item-center fs-3 fw-bolder'>
                <select onChange={handleChange} className='fs-3 fw-bold p-1'> 
                  <option value="⬇️ Select un podcast ⬇️"> -- Selecciona una Receta Gourmet -- </option>
                    {/* Mapping through each fruit object in our fruits array
                      and returning an option element with the appropriate attributes / values.
                    */}
                  {recipes.map((recipe) => <option className='fs-3 fw-bold' value={recipe._id} >{recipe.name}</option>)}
              </select>
            </Col>
            <Col md={2}>

            </Col>
          </Row>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='colortextabout text-initial fs-3 fw-bolder' style={{lineHeight: '1.5'}}>Una receta de cocina es una guía de instrucciones para la elaboración de platillos. Esta guía sigue un orden debidamente estructurado, que atiende a las necesidades específicas de cada plato. La palabra receta proviene del latín recipere, que quiere decir 'dar y recibir'.
              </p>
            </Col>
          </Row>
          {
            loading ? <Loader /> 
            : error ? <Message variant='danger'>{error}</Message>
              : <div>
              <Row className='mx-1'>
                {recipes.length === 0 ? <p className='fs-4 fw-bold'><b>No existen Recetas o Artículos actualmente</b></p> : ''}
                  {recipes.map(recipe => (
                    <Col key={recipe._id} md={12}>
                        <Recipe recipe={recipe} />
                    </Col>                        
                ))}
              </Row>
              </div>
        }
          </section>
    </div>
  )
}

export default RecipeScreenCG