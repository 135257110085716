import axios from 'axios'
import { 
    FORO_LIST_REQUEST, 
    FORO_LIST_SUCCESS, 
    FORO_LIST_FAIL,
    FORO_DETAILS_REQUEST,
    FORO_DETAILS_SUCCESS,
    FORO_DETAILS_FAIL,
    FORO_CREATE_DETAILS_REQUEST,
    FORO_CREATE_DETAILS_SUCCESS,
    FORO_CREATE_DETAILS_FAIL,

    FORO_DELETE_REQUEST,
    FORO_DELETE_SUCCESS,
    FORO_DELETE_FAIL,

    FORO_CREATE_REQUEST, 
    FORO_CREATE_SUCCESS,
    FORO_CREATE_FAIL, 
    FORO_CREATE_RESET,

    FORO_UPDATE_REQUEST,
    FORO_UPDATE_SUCCESS,
    FORO_UPDATE_FAIL,
    FORO_UPDATE_RESET,

} from '../constants/foroConstants'

export const listForos = (keyword = '') => async (dispatch) => {
    try{
        dispatch({type: FORO_LIST_REQUEST })
        const { data } = await axios.get(`/api/foros${keyword}`)

        dispatch({
            type: FORO_LIST_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({
            type: FORO_LIST_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })        
    }
}

export const listForosAuthor = (keyword = '') => async (dispatch) => {
    try{
        dispatch({type: FORO_LIST_REQUEST })
        const { data } = await axios.get(`/api/foros/author${keyword}`)

        dispatch({
            type: FORO_LIST_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({
            type: FORO_LIST_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })        
    }
}

export const listForoDetails = (id) => async (dispatch) => {
    try{
        dispatch({type: FORO_DETAILS_REQUEST })
        const { data } = await axios.get(`/api/foros/${id}`)

        dispatch({
            type: FORO_DETAILS_SUCCESS,
            payload: data
        })

    }catch(error){
        dispatch({
            type: FORO_DETAILS_FAIL,
            payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
        })        
    }
}

export const createForoDetail = (foroId, review) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FORO_CREATE_DETAILS_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/foros/${foroId}/fdetails/`,
            review,
            config
        )

        dispatch({
            type: FORO_CREATE_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FORO_CREATE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const deleteForo = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FORO_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/foros/delete/${id}/`,
            config
        )

        dispatch({
            type: FORO_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: FORO_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createForo = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: FORO_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/foros/create/`,
            {},
            config
        )

        dispatch({
            type: FORO_CREATE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: FORO_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateForo = (foro) => async (dispatch, getState) => {
    try {
        dispatch({
            type: FORO_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/foros/update/${foro._id}/`,
            foro,
            config
        )

        dispatch({
            type: FORO_UPDATE_SUCCESS,
            payload: data
        })

        dispatch({ 
            type: FORO_DETAILS_SUCCESS, 
            payload: data
        })


    } catch (error) {
        dispatch({
            type: FORO_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
