import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom'
import { Row, Col, Container, Image } from 'react-bootstrap'
import { listByCategorycg, listOrderByNameCG, listTopProductsCG, listProductsCG } from '../actions/productActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import picture1 from '../assets/images/canape.jpg'
import picture2 from '../assets/images/bird.jpg'
import picture3 from '../assets/images/canape.jpg'
import picture4 from '../assets/images/bird.jpg'
import picture5 from '../assets/images/canape.jpg'
import picture6 from '../assets/images/bird.jpg'
import ProductShop from '../components/ProductShop';
import SearchBoxProductsCG from '../components/SearchBoxProductsCG';

function ShopScreenCG() {

    
    const {id} = useParams()
    const productId = id
    const location = useLocation()

    const [categoryval, setCategoryval] = useState('Cocina Gourmet')
    const navigate = useNavigate();
    const dispatch = useDispatch()
    
    /*const productCategory = useSelector(state => state.productCategory)
    const { error, loading, products } = productCategory*/
    
    const productList = useSelector(state => state.productList)
    const { error, loading, products } = productList


    const options = [
        {value: '', text: '--Escoge una opción--'},
        {value: 'destacados', text: 'Destacados'},
        {value: 'atoz', text: 'A to Z'},
        {value: 'ztoa', text: 'Z to A'},
        {value: 'product', text: 'Product'},
      ];

    const [selected, setSelected] = useState(options[0].value);

    let keyword = useLocation().search
    
    const handleChange = event => {
        event.preventDefault()
        if (event.target.value === 'atoz') {
            dispatch(listByCategorycg(keyword))
            setSelected(event.target.value);
        } else if (event.target.value === 'ztoa') {
            dispatch(listOrderByNameCG(keyword))
            setSelected(event.target.value);
        } else if (event.target.value === 'destacados') {
            dispatch(listTopProductsCG())
            setSelected(event.target.value);
        } 
  };

     
    useEffect(() => {
        dispatch(listProductsCG(keyword))
        
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }, [dispatch, categoryval, keyword, navigate])

   /*const cgHandler = () => {
      dispatch(listByCategorycg())
      setCategoryval('Cocina Gourmet')    
   }

   const getProductsByNameCG = () => {
        console.log('Aqui estoy')
        dispatch(listOrderByNameCG())
   }*/


  return (
    <div>
    <div class="container py-5">
        <div class="row">

            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-7">
                        <ul class="list-inline shop-top-menu pb-3 pt-1">
                            <li class="list-inline-item" style={{color: '#421d74'}}>
                                <p className='fw-bolder fs-1'>{categoryval}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-5 d-flex justify-content-center align-items-center">
                        <div class="d-flex mb-4">
                            <SearchBoxProductsCG />
                        </div>
                    </div>
                </div>
               {
                    loading ? <Loader /> 
                    : error ? <Message variant='danger'>{error}</Message>
                    : <div>
                        <Row>
                            {products.map(product => (
                                <ProductShop product={product}/>
                             ))}
                        </Row>
                      </div>
                }
            </div>

        </div>
    </div>
    
    </div>
  )
}

export default ShopScreenCG
