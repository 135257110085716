import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listRecipeDetails, updateRecipe } from '../actions/recipeActions';
import FormContainer from '../components/FormContainer'
import { RECIPE_UPDATE_RESET } from '../constants/recipeConstants'
import EditorToolbar, { modules, formats, Size, Font } from "./EditToolbar";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function RecipeEditScreen() {

    const { id } = useParams()
    const recipeId = id

    const [name, setName] = useState('')
    const [author, setAuthor] = useState('')
    const [title, setTitle] = useState('')
    const [category, setCategory] = useState('')
    const [createby, setCreateBy] = useState('')
    const [timeline, setTimeLine] = useState('')
    const [video_file, setVideo_file] = useState('')               
    const [image, setImage] = useState('')
    const [comment_rtf, setComment_rtf] = useState('')
        
    const [uploading, setUploading] = useState(false)
                  
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const location = useLocation()

    const recipeDetails = useSelector(state => state.recipeDetails)
    const { loading, recipe, error } = recipeDetails

    const recipeUpdate = useSelector(state => state.recipeUpdate)
    const { loading: loadingUpdate, success: successUpdate, error: errorUpdate } = recipeUpdate
    

    useEffect(() => {

        if(successUpdate){
            dispatch({ type: RECIPE_UPDATE_RESET })
            navigate(`/admin/recipelist`)
        }else{
            if(!recipe.name || recipe._id !== Number(recipeId)){
                dispatch(listRecipeDetails(recipeId))
            }else{
                setName(recipe.name)
                setAuthor(recipe.author)                
                setTitle(recipe.title)
                setCategory(recipe.category)
                setCreateBy(recipe.createby)
                setTimeLine(recipe.timeline)
                setVideo_file(recipe.video_file)
                setComment_rtf(recipe.comment_rtf)
            }
        }
    }, [recipe, recipeId, dispatch, navigate, successUpdate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateRecipe({
            _id: recipeId,
            name,
            author,
            title,
            category,
            createby,
            timeline,
            video_file,
            comment_rtf,
         }))
    }

    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('video_file', file)
        formData.append('recipe_id', recipeId)

        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/recipes/uploadvideo/', formData, config)


            setVideo_file(data)
            setUploading(false)
            

        } catch (error) {
            console.log(error)
            setUploading(false)
        }
    }


   
  return (
    <div className='container'>
    <Link to='/admin/recipelist' className='btn btn-light m-2'>
       Go Back
    </Link>

      <p className='fs-2 fw-bold my-5'>Editar Receta o Artículo</p>
         {loadingUpdate && <Loader />}
         {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}  
         {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : 
         (
           <Form onSubmit={submitHandler}>
           <Form.Group controlId='name' className='my-2'>
               <Form.Label><p className='fs-4 fw-bold'>Nombre</p></Form.Label>
               <Form.Control
                   type='text'
                   placeholder='Ingresa el nombre'
                   value={name}
                   onChange={(e) => setName(e.target.value)}
                   className='fw-bold fs-3'
               >
               </Form.Control>
           </Form.Group>

            <Form.Group controlId='author' className='my-3'>
            <Form.Label><p className='fs-3 fw-bold'>Subido por:</p></Form.Label>
            <Form.Control
               type='text'
               placeholder={'Ingresa el Autor'}
               value={author}
               onChange={(e) => setAuthor(e.target.value)}
               className='fw-bold fs-3'
            >
            </Form.Control>
            </Form.Group>           

            <Form.Group controlId='name' className='my-3'>
            <Form.Label><p className='fs-3 fw-bold'>Titulo</p></Form.Label>
            <Form.Select
                type='select'
                placeholder='Ingresa el titulo'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className='fw-bold fs-3'
                >
                <option>Seleccione...</option>
                <option>Receta</option>
                <option>Artículo</option>
            </Form.Select>
            </Form.Group>

            <Form.Group controlId='name' className='my-3'>
            <Form.Label><p className='fs-3 fw-bold'>Categoria</p></Form.Label>
            <Form.Select
                type='select'
                placeholder='Ingresa la Categoría'
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className='fw-bold fs-3'
                >
                <option>Seleccione...</option>
                <option>Cocina Gourmet</option>
                <option>Productos Naturales</option>
            </Form.Select>
            </Form.Group>
            
            <Form.Group controlId='name' className='my-3'>
            <Form.Label><p className='fs-3 fw-bold'>Creado por:</p></Form.Label>
            <Form.Control
               type='text'
               placeholder='Ingresa el creador'
               value={createby}
               onChange={(e) => setCreateBy(e.target.value)}
               className='fw-bold fs-3'
            >
            </Form.Control>
            </Form.Group>
            
            <Form.Group controlId='name' className='my-2'>
            <Form.Label><p className='fs-3 fw-bold'>Fecha creación:</p></Form.Label>
            <Form.Control
               type='text'
               placeholder='Ingresa fecha creación'
               value={timeline}
               onChange={(e) => setTimeLine(e.target.value)}
               className='fw-bold fs-3'
            >
            </Form.Control>
            </Form.Group>            

            <Form.Group>
                <Form.Group className='my-3'>
                <Form.Label><p className='fs-3 fw-bold'>Video</p></Form.Label>
                <Form.Text><p className='fw-bold text-info fs-5'><b>Formato mp4 menor a 40MB 480p 25fps</b></p></Form.Text>
                <Form.Text><p className='fw-bold text-info fs-5'><b>Para bajar resolución a un video - https://online-video-cutter.com/resize-video</b></p></Form.Text>
                <Form.Control
                    type='text'
                    placeholder='Ingresa el Video'
                    value={video_file}
                    onChange={(e) => setVideo_file(e.target.value)}
                    className='fw-bold fs-3'
                >
                </Form.Control>
                <Form.Control
                    type='file'
                    id='video-file'
                    label='Choose Video'
                    custom
                    onChange={uploadFileHandler}
                    className='fw-bold fs-3'
                >
                </Form.Control>
                {uploading && <Loader />}                    
            </Form.Group>

            <Form.Label className='mt-3'><p className='fs-3 fw-bold'>Comentarios</p></Form.Label>
            <div className='my-2 container-fluid text-dark'  >
                <EditorToolbar />
                <ReactQuill 
                    theme="snow" 
                    value={comment_rtf} 
                    onChange={setComment_rtf}
                    placeholder={"Escribe algo increible..."}
                    modules={modules}
                    formats={formats}
                    size={Size}
                    font={Font}
                    style={{fontFamily: "Quicksand"}}
                />
            </div>

             </Form.Group>

           <Button type='submit' className='btn btn-light btn-info my-2 fs-3'>Actualizar</Button>
       </Form>
     )} 

</div>
  )
}

export default RecipeEditScreen