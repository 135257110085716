import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listHolistics, deleteHolistic, createHolistic } from '../actions/holisticActions'
import { HOLISTIC_CREATE_RESET } from '../constants/holisticConstants'
import Moment from 'react-moment';


function HolisticoListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const holisticList = useSelector(state => state.holisticList)
  const { loading, error, holistics } = holisticList

  const holisticDelete = useSelector(state => state.holisticDelete)
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = holisticDelete

  const holisticCreate = useSelector(state => state.holisticCreate)
  const { loading: loadingCreate, success: successCreate, error: errorCreate, holistic: createdHolistic } = holisticCreate

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let keyword = useLocation().search

  useEffect(() => {
    dispatch({ type: HOLISTIC_CREATE_RESET })
    if(!userInfo){
      navigate('/login')
    }
    
    dispatch(listHolistics(keyword))

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  }, [dispatch, navigate, userInfo, successDelete, successCreate, createdHolistic, keyword])

  const deleteHandler = (id) => {
    if(window.confirm('Estas seguro de borrar este articulo?')){
      dispatch(deleteHolistic(id))
    }
  }

  const createHolisticHandler = () => {
    dispatch(createHolistic())
  }

  return (
    <div className='container mt-5'>
        <div class='row'>
            <div class='col-sm-4 d-flex justify-content-start align-items-center mt-3'>
                <p className='text-dark-50 text-dark fs-1 fw-bold'>Artículos Ser Holístico</p>
            </div>
            <div class='col-sm-3 d-flex justify-content-center align-items-center mt-3'>
                <Button className='btn btn-success mt-3 fs-3'  style={{fontFamily: "Quicksand"}} onClick={createHolisticHandler}>
                    <i className='fas fa-plus'></i> Crear Nuevo Artículo
                </Button>
            </div>
            <div class='col-sm-5 d-flex justify-content-center align-items-center mt-3'>
              <span className='text-center border border-danger border-2 p-1 text-danger fs-5 mt-2'><b>Importante: En esta sección, si creas nueva receta, para accesar, favor de editarlo</b></span>
            </div>           
        </div>
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

        {loadingCreate && <Loader />}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}        

        {loading ? 
            (<Loader />)
          : error ?
            (<Message variant='danger'>{error}</Message>)
          : (
            <div>
              <Table bordered hover responsive className='table-lg text-dark fs-3 mt-3 fw-bold'>
                <thead className="table-primary">
                <tr>
                  <th>NAME</th>
                  <th>UP BY</th>
                  <th>TITLE</th>                    
                  <th>CATEGORY</th>
                  <th>CREATE BY</th>
                  <th>TIMELINE</th>                                                        
                  <th>DATE</th>
                  <th></th>
                  <th></th>                            
                </tr>
              </thead>
              <tbody>
              {holistics.length === 0 ? <p className='text-danger fx-2'><b>No existen Articulos actualmente</b></p> : ''}
                {holistics.map(holistic => (
                  <tr key={holistic._id}>
                    <td>{holistic.name}</td>
                    <td>{holistic.author}</td>
                    <td>{holistic.title}</td>
                    <td>{holistic.category}</td>                                        
                    <td>{holistic.createby}</td>
                    <td>{holistic.timeline}</td>                                        
                    <td><Moment format="DD/MM/YYYY hh:mm:ss">{holistic.createdAt}</Moment></td>
                    <td>
                      <LinkContainer to={`/admin/holistic/${holistic._id}/edit`}>
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-edit'></i>
                        </Button>
                      </LinkContainer>
                    </td>
                    <td>  
                      <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(holistic._id)}>
                        <i className='fas fa-trash fs-4'></i>
                      </Button>                    
                    </td>
                  </tr>
                ))}
  
            </tbody>
          </Table>
          </div>
        )  
      }
    </div>
  )
}

export default HolisticoListScreen