import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listUsers, deleteUser } from '../actions/userActions'
import image5 from '../assets/images/LOGOSOKLORENA.png'

function UserListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const userList = useSelector(state => state.userList)
  const { loading, error, users } = userList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const userDelete = useSelector(state => state.userDelete)
  const { success: successDelete } = userDelete
  
  useEffect(() => {
      if(userInfo && userInfo.isAdmin){
        dispatch(listUsers())
      }else{
        navigate('/login');
      }
  }, [dispatch, navigate, successDelete, userInfo])

  const deleteHandler = (id) => {
    if(window.confirm('Estas seguro de borrar este usuario?')){
      dispatch(deleteUser(id))
    }
  }
  
  return (
    <Container>
    <div>
        <Row className='align-items-center'>
          <Col md={6}>
              <p className='text-dark-50 my-4 fs-2 fw-bold'>Usuarios Uno+Uno=Tres</p>
          </Col>
          <Col md={6} className="p-1 mb-1 d-flex justify-content-end align-items-center">
          <Link to='/bio' className='text-decoration-none text-success'>
              <img class="img-fluid d-block"
              src={image5} style={{width: 160}}/>
          </Link>                                   
          </Col>            
        </Row>
        {loading ? 
          (<Loader />)
        : error ?
          (<Message variant='danger'>{error}</Message>)
        : (
          <Table striped bordered hover responsive className='table-lg text-dark fs-3 mt-3 fw-bold'>
            <thead className='table-primary'>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>ADMIN</th>
                <th></th>            
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.isAdmin ? (
                    <i className='fas fa-check' style={{ color:'green' }}></i>
                  ) : (
                    <i className='fas fa-check' style={{ color:'red' }}></i>                    
                  )}</td>
                  <td>
                    <LinkContainer to={`/admin/user/${user._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(user._id)}>
                      <i className='fas fa-trash fs-3'></i>
                    </Button>                    
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )  
      }
    </div>
    </Container>
  )
}

export default UserListScreen