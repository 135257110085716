import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
import { Button, Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader'
import { getOrderDetails, payOrder, deliverOrder } from '../actions/orderActions'
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from '../constants/orderConstants'
import Moment from 'react-moment';

function OrderScreen() {
    const {id} = useParams()
    const orderId = id
    const dispatch = useDispatch()

    const [sdkReady, setSdkReady] = useState(false)

    const orderDetails = useSelector(state => state.orderDetails)
    const { order, loading, error } = orderDetails

    const orderPay = useSelector(state => state.orderPay)
    const { loading:loadingPay, success:successPay } = orderPay

    const orderDeliver = useSelector(state => state.orderDeliver)
    const { loading: loadingDeliver, success: successDeliver } = orderDeliver

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const navigate = useNavigate()

    if(!loading && !error){
        order.itemsPrice = order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)
    }

    //ClientID Paypal    
    //Af7vu0O17FRz5I1qZhbHG0UjjKkh0Y3-_vf_kuSJ-xYzYtliVpaNt5-8k41_L1z01IzpB20SDzO9nbG6

    const addPayPalScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://www.paypal.com/sdk/js?client-id=Af7vu0O17FRz5I1qZhbHG0UjjKkh0Y3-_vf_kuSJ-xYzYtliVpaNt5-8k41_L1z01IzpB20SDzO9nbG6'
        script.async = true
        script.onload = () => {
            setSdkReady(true)
        }
        document.body.appendChild(script)
    }

    useEffect(() => {

        if(!userInfo){
            navigate('/login')
        }

        if(!order || successPay || order._id !== Number(orderId) || successDeliver){
            dispatch({ type: ORDER_PAY_RESET })
            dispatch({ type: ORDER_DELIVER_RESET })            
            dispatch(getOrderDetails(orderId))
        }else if(!order.isPaid){
            if(!window.paypal){
                addPayPalScript()
            }else{
                setSdkReady(true) 
            }
        }
    }, [dispatch, order, orderId, successPay, successDeliver, navigate, userInfo])

    const successPaymentHandler = (paymentResult) => {
        dispatch(payOrder(orderId, paymentResult))
    }

    const deliverHandler = () => {
        dispatch(deliverOrder(order))
    }

    return loading ? (
        <Loader />
    ) : error ? (
        <Message variant='danger'>{error}</Message>
    ) : (
<Container>
    <div>
        <Row>
        <p className='my-4 fs-1 fw-bold text-info'><strong>Tu Orden: {order._id}</strong></p>  
            <Col md={8} className='mb-4'>
                <ListGroup variant='flush'>
                    <ListGroup.Item>
                        <p className='fs-2 fw-bold mt-1'>Datos de Envío</p>
                        <p className='fs-4 fw-bolder'><strong>Nombre: </strong>{order.user.name}</p>
                        <p className='fs-4 fw-bolder'><strong>Email: </strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p>                        
                        <p className='fs-4 fw-bolder'>
                            <strong>Enviar a : </strong>
                            {order.shippingAddress.address} {order.shippingAddress.city}
                            {' '},
                            {order.shippingAddress.postalCode},
                            {' '}
                            {order.shippingAddress.country}                        
                        </p>
                        {order.isDelivered ? (
                            <Message variant='success' className='fs-3 fw-bold'>Entregado : <Moment format="DD/MM/YYYY hh:mm:ss">{order.deliveredAt}</Moment></Message>
                            
                        ): (
                            <Message variant='warning' className='fs-3 fw-bold'>No Entregado</Message>                            
                        )}                        
                    </ListGroup.Item>
                    <ListGroup.Item>
                    <p className='fs-2 fw-bold mt-1'>Datos de Facturación</p>
                        <p className='fs-4 fw-bolder my-2'>
                            <strong>Facturar a : </strong>
                            <b>RFC:</b> {order.invoiceData.rfc}
                            {' '},
                            <b>RS:</b>{order.invoiceData.socialname}, <b>Tel:</b>{order.invoiceData.phone}
                            {' '},
                            {order.invoiceData.address}, {order.invoiceData.city}
                            {' '},
                            {order.invoiceData.postalCode},
                            {' '}
                            {order.invoiceData.country},
                            {' '}
                            <b>Email:</b>{order.invoiceData.email}, <b>CFDI:</b>{order.invoiceData.cfdi},
                            {' '}
                            <b>FP:{order.invoiceData.payment}</b>                        
                        </p>
                    </ListGroup.Item>                    
                    <ListGroup.Item>
                        <p className='fs-2 fw-bold my-3'>Metodo de Pago</p>
                        <p className='fs-4 fw-bolder'>
                            <strong>Metodo : </strong>
                            {order.paymentMethod}                        
                        </p>
                        {order.isPaid ? (
                            <Message variant='success' className='fs-3 fw-bold'>Pagado : <Moment format="DD/MM/YYYY hh:mm:ss">{order.paidAt}</Moment></Message>
                        ): (
                            <Message variant='warning' className='fs-3 fw-bold'>No pagado</Message>                            
                        )}
                     </ListGroup.Item>
                     <ListGroup.Item>
                        <p className='fs-2 fw-bold mt-1'>Articulos de la Orden</p>
                        {order.orderItems.length === 0 ? <Message variant='info'>
                            Your order is Empty
                        </Message> : (
                            <ListGroup variant='flush'>
                                {order.orderItems.map((item, index) => (
                                    <ListGroup.Item key={index}>
                                        <Row>
                                            <Col md={2}>
                                                <Image src={item.image} alt={item.name} fluid rounded/>
                                            </Col>
                                            <Col md={4} className='d-flex justify-content-center align-items-center'>
                                                <Link to={`/product/${item.product}`} style={{ color: '#59ab6e'}} className='fst-italic fw-bolder text-decoration-none fs-3'>{item.name}</Link>  
                                            </Col>
                                            <Col md={4} className='fw-bolder d-flex justify-content-center align-items-center fs-3'>
                                                {item.qty} x ${item.price} = ${(item.qty * item.price).toFixed(2)}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        )}
                     </ListGroup.Item>                     
                </ListGroup>
            </Col>
            <Col md={4}>
                <Card className='fs-4 mb-2'>
                    <ListGroup variant='flush'>
                       <ListGroup.Item>
                          <p className='fs-2 fw-bold mt-1'>Resumen de Orden</p>
                          <p className='fs-3 fw-bold text-info mt-1'>Productos con IVA incluido</p>
                       </ListGroup.Item>
                       <ListGroup.Item>
                          <Row>
                             <Col><b className='fs-3 fw-bolder'>Subtotal:</b></Col>
                             <Col><b className='fs-3 fw-bolder'>${order.itemsPrice}</b></Col>
                          </Row>          
                       </ListGroup.Item>
                       <ListGroup.Item>
                          <Row>
                             <Col><b className='fs-3 fw-bolder'>Costo Envío:</b></Col>
                             <Col><b className='fs-3 fw-bolder'>${order.shippingPrice}</b></Col>
                          </Row>          
                       </ListGroup.Item>
                       <ListGroup.Item>
                            <Row>
                                <Col><b className='fs-3 fw-bolder'>Total:</b></Col>
                                <Col><b className='fs-3 fw-bolder'>${order.totalPrice}</b></Col>
                            </Row>          
                       </ListGroup.Item>

                       {!order.isPaid && (
                           <ListGroup.Item>
                             {loadingPay && <Loader />}
                             {!sdkReady ? (
                                <Loader />
                             ) : (
                                <PayPalButton 
                                    amount={order.totalPrice}
                                    onSuccess={successPaymentHandler}
                                />

                             )}
                           </ListGroup.Item>
                       )}

                    </ListGroup>
                    {loadingDeliver && <Loader />}
                    {userInfo && userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                        <ListGroup.Item className='d-flex justify-content-center'>
                            <Button
                                type='button'
                                variant='primary' 
                                className='btn-md my-2 btn-block bg-success fs-4 fw-bold'
                                onClick={deliverHandler}
                                style={{fontFamily: "Quicksand"}}
                            >
                                Marcado como Entregado
                            </Button>
                        </ListGroup.Item>
                    )}            

                </Card>
            </Col>            
        </Row>
    </div>
    </Container>
  )
}

export default OrderScreen
