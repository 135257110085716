import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { listArticles } from '../actions/articleActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import image5 from '../assets/images/LOGOSOKLORENA.png'
import Article from '../components/Article';
import SearchBoxRecomend from '../components/SearchBoxRecomend';

function RecomendScreen() {

  const dispatch = useDispatch()

  let navigate = useNavigate();
  let keyword = useLocation().search

  const articleList = useSelector(state => state.articleList)
  const {error, loading, articles} = articleList

  useEffect(() => {
    dispatch(listArticles(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])

  let handleChange = (e) => {
    console.log(e.target.value)
    navigate(`/article/${e.target.value}`)
  }

  return (
    <div class='container'>
      <section className='p-1 text-center text-sm-start'>
          <Row className='align-items-center justify-content-around text-center mt-2'>
            <Col lg={4} className='d-flex justify-content-center align-items-center mt-1'>
              <p className='fs-1 fw-bolder text-center fst-italic' style={{color: '#596488'}}>Recomendaciones</p>
            </Col>
            <Col lg={6} className='d-flex justify-content-center align-items-center mt-1'>
              <SearchBoxRecomend />
            </Col>
            <Col md={2} className="p-1 mb-2 d-flex justify-content-end align-items-center">
              <Link to='/bio' className='text-decoration-none text-success'>
                  <img class="img-fluid d-block"
                  src={image5} style={{width: 160}}/>
              </Link>                                   
            </Col>
        </Row>
        <Row className='my-4'>
            <Col md={3} className='d-flex justify-content-start align-items-center fs-3 fw-bolder'>
                <p>Seleccione la opción deseada</p>
            </Col>
            <Col md={7} className='d-flex justify-content-start aligm-item-center fs-3 fw-bolder'>
                <select onChange={handleChange} className='fs-3 fw-bold p-1'> 
                  <option value="⬇️ Select un podcast ⬇️"> -- Selecciona una Recomendación -- </option>
                    {/* Mapping through each fruit object in our fruits array
                      and returning an option element with the appropriate attributes / values.
                    */}
                  {articles.map((article) => <option className='fs-3 fw-bold' value={article._id} >{article.name}</option>)}
              </select>
            </Col>
            <Col md={2}>

            </Col>
        </Row>
          <hr className='text-info'/>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='colortextabout text-initial fs-3 fw-bolder' style={{lineHeight: '1.5'}}>Cuantas veces requerimos de una buena frase o un pensamiento para iniciar nuestro día, para entender y comprender lo que está pasando. <br /> <br /> He seleccionado con mucho cuidado y amor, las frases que me han vibrado, que en algún momento de mi vida me hicieron quedarme en silencio y meditar sobre eso. <br />
              <br />
              También la lectura se me ha hecho un hábito maravilloso, ya que debo de confesar, que casi no leía. <br /> <br /> Les mostrare lecturas de todo tipo que me han acompañado en estos últimos años. <br /> <br />
              
              Haremos un recorrido por lugares increíbles por conocer.
              <br />
              <br />
              Disfruta!
              </p>
            </Col>
          </Row>
          {
            loading ? <Loader /> 
            : error ? <Message variant='danger'>{error}</Message>
              : <div>
              <Row className='mx-1'>
                {articles.length === 0 ? <p className='fs-2 fw-bold'><b>No existen Recomendaciones actualmente</b></p> : ''}
                  {articles.map(article => (
                    <Col key={article._id} md={6}>
                        <Article article={article} />
                    </Col>                        
                ))}
              </Row>
              </div>
        }
          </section>
    </div>
  )
}

export default RecomendScreen