import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { listForos, listForosAuthor } from '../actions/foroActions'
import Loader from '../components/Loader';
import Message from '../components/Message';
import Foro from '../components/Foro';
import { FORO_CREATE_DETAILS_RESET } from '../constants/foroConstants'
import image5 from '../assets/images/LOGOSOKLORENA.png'
import SearchBoxForo from '../components/SearchBoxForo';
import SearchBoxForoAuthor from '../components/SearchBoxForoAuthor';

function ForoScreen() {
  const [toggle, setToggle] = useState(null);
  const dispatch = useDispatch()
  
  /*const blogList = useSelector(state => state.blogList)
  const {error, loading, blogs} = blogList*/

  const foroList = useSelector(state => state.foroList)
  const {error, loading, foros} = foroList
 
  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin
  
  /*const blogDetailCreate = useSelector(state => state.blogDetailCreate)
  const {error: errorBlogDetail, loading: loadingBlogDetail, success: successBlogDetail} = blogDetailCreate*/

  const { id } = useParams();
  let navigate = useNavigate();
  let keyword = useLocation().search

  
  useEffect(() => {
     dispatch(listForosAuthor(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])

  let handleChange = (e) => {
    console.log(e.target.value)
    navigate(`/foros/${e.target.value}`)
  }

  return (
    <div class='container'>
      <section className='p-1 text-center text-sm-start'>
          <Row className='mt-2'>
            <Col lg={4} className='d-flex justify-content-center align-items-center mt-1'>
              <p className='fw-bold fs-1 text-center fst-italic' style={{color: '#596488'}}>Foro Uno+Uno=Tres</p>
            </Col>
            <Col lg={4} className='d-flex justify-content-center align-items-center mt-1'>
              <SearchBoxForoAuthor />
            </Col>
            <Col md={4} className="p-1 mb-2 d-flex justify-content-center align-items-center">
                <img class="img-fluid"
                src={image5} style={{width: 160}}/>
            </Col>
          </Row>
          <Row className='my-4'>
            <Col md={3} className='d-flex justify-content-start align-items-center fs-3 fw-bolder'>
                <p>Seleccione la opción deseada</p>
            </Col>
            <Col md={7} className='d-flex justify-content-start aligm-item-center fs-3 fw-bolder'>
                <select onChange={handleChange} className='fs-3 fw-bold p-1'> 
                  <option value="⬇️ Select un podcast ⬇️"> -- Selecciona una Foro -- </option>
                    {/* Mapping through each fruit object in our fruits array
                      and returning an option element with the appropriate attributes / values.
                    */}
                  {foros.map((foro) => <option className='fs-3 fw-bold' value={foro._id} >{foro.name}</option>)}
              </select>
            </Col>
            <Col md={2}>

            </Col>
          </Row>
          <hr className='text-info'/>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='colortextabout text-initial fs-3 fw-bolder' style={{lineHeight: '1.5'}}>
              En términos generales un <span className='text-info fw-bolder'>foro de discusión</span> puede definirse como un espacio de encuentro (físico o virtual) entre diversos participantes con el 
              objetivo de intercambiar opiniones, plantear preguntas en torno a un tema o subtemas de interés común, así como compartir habilidades, experiencias 
              o respuestas a preguntas. <br />
              <br />
              Este es un proyecto vivo y por tal motivo, la interacción e intercambio de ideas y opiniones es sumamente valioso para cada uno de nosotros.
              Hay temas que nos deben ocupar y no preocupar; así que este espacio está abierto para que cada uno de nosotros nos expresemos. <br />
              <br />
              No tengas miedo, no hay nada que no sea perfecto en lo que preguntes o en lo que respondas.
              Recordando que no hay bueno o malo; simplemente hay.
              Dejemos a un lado los prejuicios sin juicio y compartámonos.
              </p>
            </Col>
          </Row>
                  
          </section>
          {loading ? 
            <Loader /> 
            : error ? <Message variant='danger'>{error}</Message>
            :
            <div>
              <Row className='mx-1'>
              {foros.length === 0 ? <h4><b>No existen Foros actualmente</b></h4> : ''}
                {foros.map(foro => (
                   <Col key={foro._id} md={12}>
                      <Foro foro={foro} />
                   </Col>                        
              ))}
              </Row>

            </div>
          }

    </div>
  )
}

export default ForoScreen