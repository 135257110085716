import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import image1 from '../assets/images/SERHOLISTICO.png'
import image5 from '../assets/images/LOGOSOKLORENA.png'

function HolisticoScreen() {

 window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  return (
    <div class='container'>
      <section className='p-1 text-center text-sm-start'>
          <Row className='mt-4'>
            <Col lg={4}></Col>
            <Col lg={4}></Col>
            <Col lg={4} className='d-flex justify-content-center align-items-center'>
              <Link to='/holisticart' className='mt-4 fs-4 my-1 btn btn-success text-light' style={{color: '#421d74'}}>Artículos Holístico</Link>
            </Col>
          </Row>      
          <Row className='mt-1'>
            <Col lg={4} className='d-flex justify-content-center align-items-center'>
                <img class="img-fluid d-block "
                src={image1} style={{width: 200}}/>
            </Col>
            <Col lg={8} className='d-flex justify-content-center align-items-center mt-3 fs-1'>
              <p className='text-center fw-bold fst-italic text-center' style={{color: '#596488'}}>Ser Holístico</p>
            </Col>
          </Row>
          <hr className='text-info'/>
 
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='fs-3 fw-bolder' style={{lineHeight: '1.4'}}>
              <span className='fw-bold fst-italic text-center '><b>" Has escuchado muchas veces que la falta de salud viene de una emoción o pensamiento; que hay que silenciar la mente, para poder escuchar; que mente sana en cuerpo sano.
              Así es, cada uno de estos cuerpos es un engranaje perfecto; que, al verse perturbado, altera a los demás cuerpos. "</b></span> <br /> <br />
              Esta sección es una de las que más me emociona compartir, porque es quien soy ahora; ese encuentro y reencuentro que he vivido estos últimos años me ha llevado hasta donde estoy hoy. <br /> <br />

              Todos los días algo nuevo llega a mi vida; no les ha pasado que buscan una respuesta o una señal, y en cada paso que dan se dan cuenta que ya la tienen, pero llega algo nuevo y encaja perfectamente con lo que estas buscando, y después llega algo más y encaja, todo es perfecto; así yo en todo este tiempo. <br />
              <br />
              Y mientras más te muevas, más encuentras y te encuentras y sobre todo empiezas a HACER y a SER. <br /> <br />Nada es estático en la naturaleza, todo se mueve y tiene ciclos. Así que empezar a movernos que la vida es bella.
              Yoga, acupuntura, biomagnetismo, meditación, trabajo de energía, música, lectura y practica; todo esto me ha permitido ser quien soy hoy. <br /> <br /> Y también es lo que hago con mis pacientes, con la gente que se acerca a preguntarme ¿Y ahora como le hago? <br /> <br />
              
              Soy solo un acompañante en este proceso que cada uno elige caminar.
              <br />
              
              <br /> Empecemos.
              
              </p>
            </Col>
          </Row>
          <Row className='container-fluid'>
            <Col md={12} className="p-1 mb-4 d-flex justify-content-end align-items-center">
            <Link to='/bio' className='text-decoration-none text-success'>
                <img class="img-fluid d-block"
                src={image5} style={{width: 160}}/>
            </Link>                                   
            </Col>
          </Row>
                   
          </section>

    </div>
  )
}

export default HolisticoScreen