import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listArticleDetails, updateArticle } from '../actions/articleActions';
import FormContainer from '../components/FormContainer'
import { ARTICLE_UPDATE_RESET } from '../constants/articleConstants'
import EditorToolbar, { modules, formats } from "./EditToolbar";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function BlogEditScreen() {

    const { id } = useParams()
    const articleId = id

    const [name, setName] = useState('')
    const [author, setAuthor] = useState('')
    const [title, setTitle] = useState('')
    const [subtitle, setSubTitle] = useState('')
    const [createby, setCreateBy] = useState('')
    const [timeline, setTimeLine] = useState('')               
    const [image, setImage] = useState('')
    const [video_file, setVideo_file] = useState('')
    const [comment_rtf, setComment_rtf] = useState('')
        
    const [uploading, setUploading] = useState(false)
                  
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const location = useLocation()

    const articleDetails = useSelector(state => state.articleDetails)
    const { loading, article, error } = articleDetails

    const articleUpdate = useSelector(state => state.articleUpdate)
    const { loading: loadingUpdate, success: successUpdate, error: errorUpdate } = articleUpdate
    

    useEffect(() => {

        if(successUpdate){
            dispatch({ type: ARTICLE_UPDATE_RESET })
            navigate(`/admin/articlelist`)
        }else{
            if(!article.name || article._id !== Number(articleId)){
                dispatch(listArticleDetails(articleId))
            }else{
                setName(article.name)
                setAuthor(article.author)                
                setTitle(article.title)
                setSubTitle(article.subtitle)
                setCreateBy(article.createby)
                setTimeLine(article.timeline)
                setVideo_file(article.video_file)
                setComment_rtf(article.comment_rtf)
            }
        }
    }, [article, articleId, dispatch, navigate, successUpdate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateArticle({
            _id: articleId,
            name,
            author,
            title,
            subtitle,
            createby,
            timeline,
            video_file,
            comment_rtf,
         }))
    }

    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('video_file', file)
        formData.append('article_id', articleId)

        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/articles/uploadvideo/', formData, config)


            setVideo_file(data)
            setUploading(false)
            

        } catch (error) {
            console.log(error)
            setUploading(false)
        }
    }

   
  return (
    <div className='container'>
    <Link to='/admin/articlelist' className='btn btn-light mt-2'>
       Go Back
    </Link>

      <p className='fs-2 fw-bold my-5'>Editar Recomendación</p>
         {loadingUpdate &&  <Loader />}
         {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}  
         {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : 
         (
           <Form onSubmit={submitHandler}>
           <Form.Group controlId='name' className='my-3'>
              <Form.Label><p className='fs-3 fw-bold'>Nombre</p></Form.Label>
               <Form.Control
                   type='text'
                   placeholder='Ingresa el nombre'
                   value={name}
                   onChange={(e) => setName(e.target.value)}
                   className='fw-bold fs-3'
               >
               </Form.Control>
           </Form.Group>

            <Form.Group controlId='author' className='mt-3'>
           <Form.Label><p className='fs-3 fw-bold'>Subido por:</p></Form.Label>
            <Form.Control
               type='text'
               placeholder={'Ingresa el Autor'}
               value={author}
               onChange={(e) => setAuthor(e.target.value)}
               className='fw-bold fs-3'
            >
            </Form.Control>
            </Form.Group>           

            <Form.Group controlId='name' className='my-3'>
           <Form.Label><p className='fs-3 fw-bold'>Título</p></Form.Label>
            <Form.Control
               type='text'
               placeholder='Ingresa el titulo'
               value={title}
               onChange={(e) => setTitle(e.target.value)}
               className='fw-bold fs-3'
            >
            </Form.Control>
            </Form.Group>

            <Form.Group controlId='name' className='my-3'>
           <Form.Label><p className='fs-3 fw-bold'>SubTitulo</p></Form.Label>
            <Form.Control
               type='text'
               placeholder='Ingresa el subtitulo'
               value={subtitle}
               onChange={(e) => setSubTitle(e.target.value)}
               className='fw-bold fs-3'
            >
            </Form.Control>
            </Form.Group>
            
            <Form.Group controlId='name' className='my-3'>
           <Form.Label><p className='fs-3 fw-bold'>Creado por:</p></Form.Label>
            <Form.Control
               type='text'
               placeholder='Ingresa el creador'
               value={createby}
               onChange={(e) => setCreateBy(e.target.value)}
               className='fw-bold fs-3'
            >
            </Form.Control>
            </Form.Group>
            
            <Form.Group controlId='name' className='my-3'>
           <Form.Label><p className='fs-3 fw-bold'>Fecha creación:</p></Form.Label>
            <Form.Control
               type='text'
               placeholder='Ingresa fecha creación'
               value={timeline}
               onChange={(e) => setTimeLine(e.target.value)}
               className='fw-bold fs-3'
            >
            </Form.Control>
            </Form.Group>            

            <Form.Group>
                <Form.Group className='my-3'>
                <Form.Label><p className='fs-3 fw-bold'>Video</p></Form.Label>
                <Form.Text><p className='fw-bold text-info fs-5'><b>Formato mp4 menor a 40MB 480p 25fps</b></p></Form.Text>
                <Form.Text><p className='fw-bold text-info fs-5'><b>Para bajar resolución a un video - https://online-video-cutter.com/resize-video</b></p></Form.Text>
                <Form.Control
                    type='text'
                    placeholder='Ingresa el Video'
                    value={video_file}
                    onChange={(e) => setVideo_file(e.target.value)}
                    className='fw-bold fs-3'
                >
                </Form.Control>
                <Form.Control
                    type='file'
                    id='video-file'
                    label='Choose Video'
                    custom
                    onChange={uploadFileHandler}
                    className='fw-bold fs-3'
                >
                </Form.Control>
                {uploading && <Loader />}                    
            </Form.Group>

            <Form.Label className='mt-3'><p className='fs-3 fw-bold'>Comentarios</p></Form.Label>
            <div className='my-2 container-fluid text-dark ql-editor'  >
                <EditorToolbar />
                <ReactQuill 
                    theme="snow" 
                    value={comment_rtf} 
                    onChange={setComment_rtf}
                    placeholder={"Escribe algo increible..."}
                    modules={modules}
                    formats={formats}
                    style={{fontFamily: "Quicksand"}}
                    className='ql-editor ql-formats'
                />
            </div>

             </Form.Group>

           <Button type='submit' className='btn btn-light btn-info my-2 fs-3'>Actualizar</Button>
       </Form>
     )} 

</div>
  )
}

export default BlogEditScreen
