import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listPodcasts, deletePodcast, createPodcast } from '../actions/podcastActions'
import { PODCAST_CREATE_RESET } from '../constants/podcastConstants'
import Moment from 'react-moment';
import image5 from '../assets/images/LOGOSOKLORENA.png'

function PodcastListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const podcastList = useSelector(state => state.podcastList)
  const { loading, error, podcasts } = podcastList

  const podcastDelete = useSelector(state => state.podcastDelete)
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = podcastDelete

  const podcastCreate = useSelector(state => state.podcastCreate)
  const { loading: loadingCreate, success: successCreate, error: errorCreate, podcast: createdPodcast } = podcastCreate

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let keyword = useLocation().search

  useEffect(() => {
    dispatch({ type: PODCAST_CREATE_RESET })
    if(!userInfo){
      navigate('/login')
    }

    if(successCreate){
        navigate(`/admin/podcast/${createdPodcast._id}/edit`)
    }else{
      dispatch(listPodcasts(keyword))
    }

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  }, [dispatch, navigate, userInfo, successDelete, successCreate, createdPodcast, keyword])

  const deleteHandler = (id) => {
    if(window.confirm('Estas seguro de borrar este podcast?')){
      dispatch(deletePodcast(id))
    }
  }

  const createPodcastHandler = () => {
    dispatch(createPodcast())
  }
  
  return (
    <div className='section1 container'>
        <Row className='align-items-center'>
            <Col className='col-sm-4 d-flex justify-content-start align-items-center mt-3'>
                <p className='text-dark-50 mt-2 fs-1 fw-bold'>Podcast</p>
            </Col>
            <Col className='col-sm-3 d-flex justify-content-center align-items-center mt-3'>
                <Button className='my-3 fs-3' onClick={createPodcastHandler} style={{fontFamily: "Quicksand"}}>
                    <i className='fas fa-plus'></i> Crear Nuevo Podcast
                </Button>
            </Col>
            <Col md={4} className="p-1 mb-1 d-flex justify-content-end align-items-center">
            <Link to='/bio' className='text-decoration-none text-success'>
              <img class="img-fluid d-block"
              src={image5} style={{width: 160}}/>
            </Link>                                   
          </Col>            
        </Row>
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

        {loadingCreate && <Loader />}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}        

        {loading ? 
          (<Loader />)
        : error ?
          (<Message variant='danger'>{error}</Message>)
        : (
          <div>
            <Table bordered hover responsive className='table-lg text-dark fs-4 mt-3 fw-bold'>
              <thead className='table-primary'>
              <tr>
                <th>ID</th>
                <th>NOMBRE</th>
                <th>AUTOR</th>
                <th>COMENTARIOS</th>
                <th>FECHA</th>
                <th>DURACION</th>
                <th>INFORMACION</th>
                <th></th>
                <th></th>            
              </tr>
            </thead>
            <tbody>
            {podcasts.length === 0 ? <p className='text-danger fx-2'><b>No existen Podcasts actualmente</b></p> : ''}
              {podcasts.map(podcast => (
                <tr key={podcast._id}>
                  <td>{podcast._id}</td>
                  <td>{podcast.name}</td>
                  <td>{podcast.author}</td>
                  <td>{podcast.comment}</td>
                  <td><Moment format="DD/MM/YYYY hh:mm:ss">{podcast.createdAt}</Moment></td>
                  <td>{podcast.time_pod}</td>
                  <td>{podcast.transcription}</td>                                    

                  <td>
                    <LinkContainer to={`/admin/podcast/${podcast._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                   </td>
                   <td> 
                    <Button variant='danger' className='btn-sm mt-2' onClick={() => deleteHandler(podcast._id)} style={{fontFamily: "Humnst777"}}>
                      <i className='fas fa-trash fs-4'></i>
                    </Button>                    
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        )  
      }
    </div>
  )
}

export default PodcastListScreen