import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import swal from 'sweetalert';

function ContactScreen() {

    /*const { id } = useParams()
    const contactId = id*/

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')    
    const [topic, setTopic] = useState('')
    const [comment, setComment] = useState('')

    const [uploading, setUploading] = useState(false)    
                    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const location = useLocation()

    const contactCreate = useSelector(state => state.contactCreate)
    const { loading: loadingCreate, success: successCreate, error: errorCreate, contact: createdContact } = contactCreate

    const contactUpdate = useSelector(state => state.contactUpdate)
    const { loading: loadingUpdate, success: successUpdate, error: errorUpdate } = contactUpdate 
  
    let keyword = useLocation().search

    useEffect(() => {

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
   
    }, [])


    const createHandler = async (e) => {
        e.preventDefault()
        await axios.post(`/api/contacts/create/`, {
            'name': name,
            'email': email,
            'topic': topic,
            'comment': comment,
        }
        )
            .catch(error => console.err(error))
            .then(res => console.log(res), swal('Mensaje enviado a Lorena Madrigal...en breve se comunicara contigo')
        )
    }
 
  return (

    <div class="container py-2">
        <Link to='/' className='btn btn-light mt-2'>Go Back</Link>
        <div class="col-md-6 mx-auto text-center">
            <p className="text-center fw-bold fs-2 mt-1" style={{color: '#421d74'}}><strong>Contáctame</strong></p>
            <p className='fs-4 fw-bold mt-2'>
                Si tienes alguna inquietud, comentario o simplemente quieres hablar; te dejo mis datos.
            </p>
            <p className='fs-4 fw-bold' style={{color: '#421d74'}}><b>Tu mensaje es muy importante para mi. Gracias!</b></p>
        </div>


    <div class="container-fluid py-1">
        <div class="col text-end mt-2 fs-3">
            <a href="whatsapp://send?text=Hola buen día, me puedes enviar información de..." data-action="share/whatsapp/share"
            target="_blank" className='text-decoration-none text-info'> <i class="fab fa-whatsapp mx-2 text-success" aria-hidden="true"></i><strong style={{color: '#596488'}}>Comunicate conmigo</strong></a>
        </div>

        {loadingCreate && <Loader />}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>} 

        <div class="row py-3">
            <Form className="col-md-9 m-auto fs-3" onSubmit={createHandler}>
                <div class="row">
                    <div className="col-md-6 mb-3">
                    <Form.Group controlId='name' className='my-2'>
                        <Form.Label><p className='fs-3 fw-bold'>Nombre</p></Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Ingresa el nombre'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className='fw-bold fs-3'
                        >
                        </Form.Control>
                    </Form.Group>
                    </div>
                    <div className="col-md-6 mb-3">
                        <Form.Group controlId='email' className='my-2'>
                            <Form.Label><p p className='fs-3 fw-bold'>Email</p></Form.Label>
                            <Form.Control
                                type='email'
                                placeholder={'Ingresa el Correo Electrónico'}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className='fw-bold fs-3'
                            >
                            </Form.Control>
                        </Form.Group>
                    </div>
                </div>
                <div class="mb-3">
                    <Form.Group controlId='topic' className='my-2'>
                        <Form.Label><p className='fs-3 fw-bold'>Asunto</p></Form.Label>
                        <Form.Control
                            type='text'
                            placeholder={'Ingresa el Asunto'}
                            value={topic}
                            onChange={(e) => setTopic(e.target.value)}
                            className='fw-bold fs-3'
                        >
                        </Form.Control>
                    </Form.Group>
                </div>
                <div class="mb-3">
                    <Form.Group controlId='comment' className='my-2'>
                        <Form.Label><p className='fs-3 fw-bold'>Mensaje</p></Form.Label>
                        <Form.Control
                            as='textarea'
                            rows = {5}
                            placeholder={'Ingresa el mensaje'}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            className='fw-bold fs-3'
                        >
                        </Form.Control>
                    </Form.Group>
                </div>
                <div class="row">
                    <div class="col-md-12 text-end mt-2 ">
                        <button type="submit" class="btn btn-success btn-md fs-3" style={{fontFamily: "Quicksand"}}>Enviar Información</button>
                    </div>
                </div>
            </Form>

        </div>

    </div>    
    
    
    </div>
  )
}

export default ContactScreen