import React, { useEffect } from 'react';
import { Card, Image, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import Moment from 'react-moment';


function Article({ article }) {

    const dispatch = useDispatch()

    let keyword = useLocation().search
  
    useEffect(() => {
  
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      
  }, [dispatch, keyword])
  return (
    <Card className='my-2 p-1 rounded card-img-top border border-3 d-flex justify-content-center align-items-center' style={{backgroundColor: '#8c4799'}}>
        <Card.Header >
            <Link to={`/article/${article._id}`} className='text-light' style={{color: '#8c4799'}}>
                <p className='text-center fs-2 my-2' style={{color: '#e0e4fd'}}><strong>{article.name}</strong></p>
                <p className='text-center fs-5 my-3' style={{color: '#fff'}}>{article.title}</p>
                <p className='text-center fs-6 mt-2' style={{color: '#fff'}}>{article.subtitle}</p>
            </Link>            
        </Card.Header>
     </Card>
  
  )
}

export default Article