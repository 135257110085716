import { 
    HOLISTIC_LIST_REQUEST, 
    HOLISTIC_LIST_SUCCESS, 
    HOLISTIC_LIST_FAIL,
    HOLISTIC_DETAILS_REQUEST, 
    HOLISTIC_DETAILS_SUCCESS, 
    HOLISTIC_DETAILS_FAIL,
    HOLISTIC_DELETE_REQUEST, 
    HOLISTIC_DELETE_SUCCESS, 
    HOLISTIC_DELETE_FAIL,
    HOLISTIC_CREATE_REQUEST, 
    HOLISTIC_CREATE_SUCCESS, 
    HOLISTIC_CREATE_FAIL,
    HOLISTIC_CREATE_RESET,
    HOLISTIC_UPDATE_REQUEST, 
    HOLISTIC_UPDATE_SUCCESS, 
    HOLISTIC_UPDATE_FAIL,
    HOLISTIC_UPDATE_RESET,
} from '../constants/holisticConstants';

export const holisticListReducers = (state = {holistics: []}, action) => {
    switch(action.type){
        case HOLISTIC_LIST_REQUEST:
            return {loading: true, holistics:[]}
        case HOLISTIC_LIST_SUCCESS:
            return {loading: false, holistics: action.payload}
        case HOLISTIC_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const holisticDetailsReducers = (state = { holistic: {reviews:[]} }, action) => {
switch(action.type) {
    case HOLISTIC_DETAILS_REQUEST:
        return { loading: true, ...state }
    case HOLISTIC_DETAILS_SUCCESS:
        return { loading: false, holistic: action.payload }
    case HOLISTIC_DETAILS_FAIL:
        return { loading: false, error: action.payload }
    default:
        return state              
}
}

export const holisticCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case HOLISTIC_CREATE_REQUEST:
            return { loading: true }
        case HOLISTIC_CREATE_SUCCESS:
            return { loading: false, success: true, holistic: action.payload }
        case HOLISTIC_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case HOLISTIC_CREATE_RESET:
                return {}            
        default:
            return state
    }
}


export const holisticDeleteReducer = (state = {}, action) => {
switch (action.type) {
    case HOLISTIC_DELETE_REQUEST:
        return { loading: true }

    case HOLISTIC_DELETE_SUCCESS:
        return { loading: false, success: true }

    case HOLISTIC_DELETE_FAIL:
        return { loading: false, error: action.payload }

    default:
        return state
}
}


export const holisticUpdateReducer = (state = {holistic: {}}, action) => {
switch (action.type) {
    case HOLISTIC_UPDATE_REQUEST:
        return { loading: true }

    case HOLISTIC_UPDATE_SUCCESS:
        return { loading: false, success: true, holistic: action.payload }

    case HOLISTIC_UPDATE_FAIL:
        return { loading: false, error: action.payload }

    case HOLISTIC_UPDATE_RESET:
            return { holistic: {} }            

    default:
        return state
    }
}
