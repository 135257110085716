export const RECIPE_LIST_REQUEST = 'RECIPE_LIST_REQUEST'
export const RECIPE_LIST_SUCCESS = 'RECIPE_LIST_SUCCESS'
export const RECIPE_LIST_FAIL = 'RECIPE_LIST_FAIL'

export const RECIPE_DETAILS_REQUEST = 'RECIPE_DETAILS_REQUEST'
export const RECIPE_DETAILS_SUCCESS = 'RECIPE_DETAILS_SUCCESS'
export const RECIPE_DETAILS_FAIL = 'RECIPE_DETAILS_FAIL'

export const RECIPE_DELETE_REQUEST = 'RECIPE_DELETE_REQUEST'
export const RECIPE_DELETE_SUCCESS = 'RECIPE_DELETE_SUCCESS'
export const RECIPE_DELETE_FAIL = 'RECIPE_DELETE_FAIL'

export const RECIPE_CREATE_REQUEST = 'RECIPE_CREATE_REQUEST'
export const RECIPE_CREATE_SUCCESS = 'RECIPE_CREATE_SUCCESS'
export const RECIPE_CREATE_FAIL = 'RECIPE_CREATE_FAIL'
export const RECIPE_CREATE_RESET = 'RECIPE_CREATE_RESET'

export const RECIPE_UPDATE_REQUEST = 'RECIPE_UPDATE_REQUEST'
export const RECIPE_UPDATE_SUCCESS = 'RECIPE_UPDATE_SUCCESS'
export const RECIPE_UPDATE_FAIL = 'RECIPE_UPDATE_FAIL'
export const RECIPE_UPDATE_RESET = 'RECIPE_UPDATE_RESET'

export const RECIPE_CATEGORY_REQUEST = 'RECIPE_TOP_REQUEST'
export const RECIPE_CATEGORY_SUCCESS = 'RECIPE_TOP_SUCCESS'
export const RECIPE_CATEGORY_FAIL = 'RECIPE_TOP_FAIL'