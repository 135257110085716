import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useParams, useNavigate } from 'react-router-dom';
import { listArticleDetails, createArticleDetail } from '../actions/articleActions'
import { ARTICLE_CREATE_DETAILS_RESET } from '../constants/articleConstants'
import Moment from 'react-moment';
//import EditorToolbar, { modules, formats } from "./EditToolbar";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Player, ControlBar, ForwardControl, LoadingSpinner } from 'video-react'
import "video-react/dist/video-react.css"; // import css


function BlogDetailScreen({ match }) {
   

    const { id } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const articleDetails = useSelector(state => state.articleDetails)
    const { error, loading, article } = articleDetails

    let keyword = useLocation().search

    useEffect(() => {
        dispatch(listArticleDetails(id))

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }, [dispatch, id, keyword])


   return (
    <div className="section1 container">
        <Link className='btn btn-light mt-1 mb-4 mt-2 ' to='/recomend'>Go Back</Link>
        {
            loading ? <Loader />
            : error ? <Message variant='danger'>{error}</Message>
            : (
            <div>
            <div class='row'>
                <div class='col-sm-12 d-flex justify-content-center align-items-center my-1'>
                    <p className='fs-1 fw-bold' style={{color: '#421d74'}}><b>{article.name}</b></p>
                </div>
            </div>
            <div class="row container app">
                <div class="col-12 p-1 text-center d-flex justify-content-center align-items-center">
                    <ReactQuill 
                        theme="snow"
                        readOnly = 'true'
                        value={article.comment_rtf}
                        className='fontrtf'
                        style={{fontFamily: "Quicksand"}}
                    />
                </div>
            </div>
            {article.video_file && (
                <div class='row'>
                <div class='col-md text-center d-flex justify-content-start align-items-center'>
                <Card className='border border-success border-1 rounded w-50 mb-2' >
                <Card.Body>
                    <Player
                        fluid={true} 
                        playsInline
                        src={article.video_file}
                        className='d-flex justify-content-center align-items-center playerv'
                        >
                        <LoadingSpinner />
                        <ControlBar autoHide={false} >
                        <ForwardControl seconds={5} order={3.1} />
                        <ForwardControl seconds={10} order={3.2} />
                        </ControlBar>
                    </Player>
    
                </Card.Body>
              </Card>
              </div>
              </div>
            )}
 
        </div>


          )
        }
    </div>
  )
}

export default BlogDetailScreen