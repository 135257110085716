import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listOrders } from '../actions/orderActions'
import NumberFormat from 'react-number-format';
import image5 from '../assets/images/LOGOSOKLORENA.png'

function OrderListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const orderList = useSelector(state => state.orderList)
  const { loading, error, orders } = orderList

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
      if(userInfo && userInfo.isAdmin){
        dispatch(listOrders())
      }else{
        navigate('/login');
      }
  }, [dispatch, navigate, userInfo])

  return (
    <Container>
    <div>
        <Row className='align-items-center'>
          <Col md={6} class='col-sm-4 d-flex justify-content-start align-items-center mt-3'>
              <p className='text-dark-50 my-4 fs-1 fw-bold' >Ordenes</p>
          </Col>
          <Col md={6} className="p-1 mb-1 d-flex justify-content-end align-items-center mt-3">
          <Link to='/bio' className='text-decoration-none text-success'>
              <img class="img-fluid d-block"
              src={image5} style={{width: 160}}/>
          </Link>                                   
          </Col>            
        </Row>
        {loading ? 
          (<Loader />)
        : error ?
          (<Message variant='danger'>{error}</Message>)
        : (
          <Table striped bordered hover responsive className='table-lg text-dark fs-3 mt-3 fw-bold'>
            <thead className='table-primary'>
              <tr>
                <th>ID</th>
                <th>USUARIO</th>
                <th>FECHA</th>
                <th>TOTAL</th>                
                <th>PAGADO</th>
                <th>ENTREGADO</th>                
                <th></th>            
              </tr>
            </thead>
            <tbody>
              {orders.map(order => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>{order.user && order.user.name}</td>
                  <td>{order.createdAt.substring(0,10)}</td>
                  <td><NumberFormat value={order.totalPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} className='fw-bolder'/></td>
                  <td>{order.isPaid ? (
                    order.paidAt.substring(0,10)
                  ) : (
                    <i className='fas fa-check' style={{ color:'red' }}></i>                    
                  )}
                  </td>

                  <td>{order.isDelivered ? (
                    order.deliveredAt.substring(0,10)
                  ) : (
                    <i className='fas fa-check' style={{ color:'red' }}></i>                    
                  )}
                  </td>                  

                  <td>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button variant='success' className='btn-sm' style={{fontFamily: 'Quicksand'}}>
                        Detalles
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )  
      }
    </div>
    </Container>
  )
}

export default OrderListScreen
