import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listProductDetails, updateProduct } from '../actions/productActions';
import FormContainer from '../components/FormContainer'
import { PRODUCT_UPDATE_RESET } from '../constants/productConstants'

function ProductEditScreen() {

    const {id} = useParams()
    const productId = id

    const [name, setName] = useState('')
    const [pricereg, setPricereg] = useState(0)
    const [pricedis, setPricedis] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [image1, setImage1] = useState('')
    const [image2, setImage2] = useState('')
    const [image3, setImage3] = useState('')
    const [tech_file, setTech_file] = useState('')       
    const [brand, setBrand] = useState('')
    const [model, setModel] = useState('')
    const [category, setCategory] = useState('')
    const [countInStock, setCountInStock] = useState(0)
    const [description, setDescription] = useState('')
    const [spec1, setSpec1] = useState('')
    const [spec2, setSpec2] = useState('')
    const [spec3, setSpec3] = useState('')
    const [width, setWidth] = useState(0)
    const [depth, setDepth] = useState(0)
    const [height, setHeight] = useState(0)
    const [weight, setWeight] = useState(0)
    const [uploading, setUploading] = useState(false)    
                    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const location = useLocation()

    const productDetails = useSelector(state => state.productDetails)
    const { loading, product, error } = productDetails

    const productUpdate = useSelector(state => state.productUpdate)
    const { loading: loadingUpdate, success: successUpdate, error: errorUpdate } = productUpdate    

    useEffect(() => {

        if(successUpdate){
            dispatch({ type: PRODUCT_UPDATE_RESET })
            navigate(`/admin/productlist`)
        }else{
            if(!product.name || product._id !== Number(productId)){
                dispatch(listProductDetails(productId))
            }else{
                setName(product.name)
                setPricereg(product.pricereg)
                setPricedis(product.pricedis)
                setDiscount(product.discount)
                setImage1(product.image1)
                setImage2(product.image2)
                setImage3(product.image3)
                setTech_file(product.tech_file)               
                setBrand(product.brand)
                setModel(product.model)
                setCountInStock(product.countInStock)
                setCategory(product.category)
                setDescription(product.description)
                setSpec1(product.spec1)
                setSpec2(product.spec2)
                setSpec3(product.spec3)
                setWidth(product.width)
                setDepth(product.depth)
                setHeight(product.height)
                setWeight(product.weight)
            }
        }
    }, [product, productId, dispatch, navigate, successUpdate])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(updateProduct({
            _id: productId,
            name,
            pricereg,
            pricedis,
            discount,
            image1,
            image2,
            image3,
            tech_file,
            brand,
            model,
            countInStock,
            category,
            description,
            spec1,
            spec2,
            spec3,
            width,
            depth,
            height,
            weight,
        }))
    }

    const uploadFileHandler1 = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('image1', file)
        formData.append('product_id', productId)

        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/products/upload1/', formData, config)


            setImage1(data)
            setUploading(false)

        } catch (error) {
            setUploading(false)
        }
    }

    const uploadFileHandler2 = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('image2', file)
        formData.append('product_id', productId)

        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/products/upload2/', formData, config)


            setImage2(data)
            setUploading(false)

        } catch (error) {
            setUploading(false)
        }
    }

    const uploadFileHandler3 = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('image3', file)
        formData.append('product_id', productId)

        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/products/upload3/', formData, config)


            setImage3(data)
            setUploading(false)

        } catch (error) {
            setUploading(false)
        }
    }


    const uploadFileHandler6 = async (e) => {

        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('tech_file', file)
        formData.append('product_id', productId)

        setUploading(true)

        try {
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post('/api/products/uploadfile/', formData, config)


            setTech_file(data)
            setUploading(false)
            

        } catch (error) {
            console.log(error)
            setUploading(false)
        }
    }


    
  return (
    <Container>
      <div>
         <Link className='btn btn-light mt-3' to='/admin/productlist'>Go Back</Link>
         <FormContainer>
           <h1 style={{fontFamily: "Quicksand"}}>Editar Producto</h1>
              {loadingUpdate && <Loader />}
              {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}  
              {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message> : 
              (
                <Form onSubmit={submitHandler} style={{fontFamily: "Quicksand"}}>
                <Form.Group controlId='name'>
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        type='name'
                        placeholder='Ingresa el nombre'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='description'>
                    <Form.Label className='mt-1'>Descripción</Form.Label>
                    <Form.Control
                    as='textarea'
                    rows = {4}
                    placeholder='Ingresa la Descripción'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                >
                    </Form.Control>
                </Form.Group>
                
                <Form.Group>
                    <Form.Label className='mt-1'>Imagen1</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Ingresa la Imagen 1'
                        value={image1}
                        onChange={(e) => setImage1(e.target.value)}
                    >
                    </Form.Control>

                    <Form.Control
                        type='file'
                        id='image-file'
                        label='Choose Image'
                        onChange={uploadFileHandler1}
                    >
                    
                    </Form.Control>
                    {uploading && <Loader />}                    
                </Form.Group>

                <Form.Group>
                    <Form.Label className='mt-1'>Imagen2</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Ingresa la Imagen 2'
                        value={image2}
                        onChange={(e) => setImage2(e.target.value)}
                    >
                    </Form.Control>

                    <Form.Control
                        type='file'
                        id='image-file'
                        label='Choose Image'
                        onChange={uploadFileHandler2}
                    >
                    
                    </Form.Control>
                    {uploading && <Loader />}                    
                </Form.Group>
                
                <Form.Group>
                    <Form.Label className='mt-1'>Imagen3</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Ingresa la Imagen 3'
                        value={image3}
                        onChange={(e) => setImage3(e.target.value)}
                    >
                    </Form.Control>

                    <Form.Control
                        type='file'
                        id='image-file'
                        label='Choose Image'
                        onChange={uploadFileHandler3}
                    >
                    
                    </Form.Control>
                    {uploading && <Loader />}                    
                </Form.Group>
                
                <Form.Group className='my-2'>
                <Form.Label className='mt-1'>Ficha Técnica</Form.Label>
                <Form.Text><h6>Formato pdf</h6></Form.Text>
                <Form.Control
                    type='text'
                    placeholder='Ingresa Ficha Técnica'
                    value={tech_file}
                    onChange={(e) => setTech_file(e.target.value)}
                >
                </Form.Control>

                <Form.Control
                    type='file'
                    id='tech_file'
                    label='Choose File'
                    custom
                    onChange={uploadFileHandler6}
                >
                
                </Form.Control>
                {uploading && <Loader />}                    
                </Form.Group>
                
                <Form.Group controlId='brand'>
                    <Form.Label className='mt-1'>Marca</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Ingresa la Marca'
                        value={brand}
                        onChange={(e) => setBrand(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='model'>
                    <Form.Label className='mt-1'>Modelo</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Ingresa el Modelo'
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='pricereg'>
                    <Form.Label className='mt-1'>Precio Regular</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder='Ingresa el Precio Regular'
                        value={pricereg}
                        onChange={(e) => setPricereg(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='pricedis'>
                    <Form.Label className='mt-1'>Precio Descuento</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder='Ingresa el Precio Descuento'
                        value={pricedis}
                        onChange={(e) => setPricedis(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                
                <Form.Group controlId='discount'>
                    <Form.Label className='mt-1'>Descuento</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder='Ingresa el Descuento'
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                
                <Form.Group controlId='countInStock'>
                    <Form.Label className='mt-1'>Inventario</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder='Ingresa la Cantidad Inventario'
                        value={countInStock}
                        onChange={(e) => setCountInStock(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                
                <Form.Group controlId='category'>
                    <Form.Label className='mt-1'>Categoria</Form.Label>
                    <Form.Select
                        type='select'
                        placeholder='Ingresa la Categoría'
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                    >
                        <option>Seleccione...</option>
                        <option>Cocina Gourmet</option>
                        <option>Productos Naturales</option>
                   </Form.Select>
                </Form.Group>

                <Form.Group controlId='spec1'>
                    <Form.Label className='mt-1'>Especificación 1</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Ingresa la Especificación 1'
                        value={spec1}
                        onChange={(e) => setSpec1(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='spec2'>
                    <Form.Label className='mt-1'>Especificación 2</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Ingresa la Especificación 2'
                        value={spec2}
                        onChange={(e) => setSpec2(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='spec3'>
                    <Form.Label className='mt-1'>Especificación 3</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='Ingresa la Especificación 3'
                        value={spec3}
                        onChange={(e) => setSpec3(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='width'>
                    <Form.Label className='mt-1'>Ancho</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder='Ingresa el Ancho'
                        value={width}
                        onChange={(e) => setWidth(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='depth'>
                    <Form.Label className='mt-1'>Profundidad</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder='Ingresa la profundidad'
                        value={depth}
                        onChange={(e) => setDepth(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='height'>
                    <Form.Label className='mt-1'>Altura</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder='Ingresa la Altura'
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId='weight'>
                    <Form.Label className='mt-1'>Peso</Form.Label>
                    <Form.Control
                        type='number'
                        placeholder='Ingresa el Peso'
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
                
                <Button type='submit' variant='primary' className='btn-md my-2' style={{fontFamily: "Humnst777"}}>Actualizar</Button>
            </Form>
          )} 
      </FormContainer>      
    </div>
    </Container>
  )
}

export default ProductEditScreen
