import { SUBSCRIBE_LIST_REQUEST, 
    SUBSCRIBE_LIST_SUCCESS, 
    SUBSCRIBE_LIST_FAIL
 } 
from '../constants/subscribeConstants';
import { SUBSCRIBE_DETAILS_REQUEST, 
    SUBSCRIBE_DETAILS_SUCCESS, 
    SUBSCRIBE_DETAILS_FAIL,
    SUBSCRIBE_DELETE_REQUEST, 
    SUBSCRIBE_DELETE_SUCCESS, 
    SUBSCRIBE_DELETE_FAIL,

    SUBSCRIBE_CREATE_REQUEST, 
    SUBSCRIBE_CREATE_SUCCESS, 
    SUBSCRIBE_CREATE_FAIL,
    SUBSCRIBE_CREATE_RESET,
    
    SUBSCRIBE_UPDATE_REQUEST, 
    SUBSCRIBE_UPDATE_SUCCESS, 
    SUBSCRIBE_UPDATE_FAIL,
    SUBSCRIBE_UPDATE_RESET,

 } 
from '../constants/subscribeConstants';

export const subscribeListReducer = (state = {subscribes: []}, action) => {
    switch(action.type){
        case SUBSCRIBE_LIST_REQUEST:
            return {loading: true, subscribes:[]}
        case SUBSCRIBE_LIST_SUCCESS:
            return {loading: false, subscribes: action.payload}
        case SUBSCRIBE_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const subscribeDetailsReducer = (state = { subscribe: {reviews:[]} }, action) => {
switch(action.type) {
    case SUBSCRIBE_DETAILS_REQUEST:
        return { loading: true, ...state }
    case SUBSCRIBE_DETAILS_SUCCESS:
        return { loading: false, subscribe: action.payload }
    case SUBSCRIBE_DETAILS_FAIL:
        return { loading: false, error: action.payload }
    default:
        return state              
}
}

export const subscribeCreateReducer = (state = {}, action) => {
switch (action.type) {
    case SUBSCRIBE_CREATE_REQUEST:
        return { loading: true }

    case SUBSCRIBE_CREATE_SUCCESS:
        return { loading: false, success: true, subscribe: action.payload }

    case SUBSCRIBE_CREATE_FAIL:
        return { loading: false, error: action.payload }

    case SUBSCRIBE_CREATE_RESET:
            return {}            

    default:
        return state
}
}


export const subscribeDeleteReducer = (state = {}, action) => {
switch (action.type) {
    case SUBSCRIBE_DELETE_REQUEST:
        return { loading: true }

    case SUBSCRIBE_DELETE_SUCCESS:
        return { loading: false, success: true }

    case SUBSCRIBE_DELETE_FAIL:
        return { loading: false, error: action.payload }

    default:
        return state
}
}


export const subscribeUpdateReducer = (state = {subscribe: {}}, action) => {
switch (action.type) {
    case SUBSCRIBE_UPDATE_REQUEST:
        return { loading: true }

    case SUBSCRIBE_UPDATE_SUCCESS:
        return { loading: false, success: true, subscribe: action.payload }

    case SUBSCRIBE_UPDATE_FAIL:
        return { loading: false, error: action.payload }

    case SUBSCRIBE_UPDATE_RESET:
            return { subscribe: {} }            

    default:
        return state
}
}