export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS'
export const CART_SAVE_INVOICE_DATA = 'CART_SAVE_INVOICE_DATA'
export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD'
export const CART_CLEAR_ITEMS = 'CART_CLEAR_ITEMS'

export const CART_ADD_ITEM_COMBO = 'CART_ADD_ITEM_COMBO'
export const CART_REMOVE_ITEM_COMBO = 'CART_REMOVE_ITEM_COMBO'
export const CART_SAVE_SHIPPING_ADDRESS_COMBO = 'CART_SAVE_SHIPPING_ADDRESS_COMBO'
export const CART_SAVE_INVOICE_DATA_COMBO = 'CART_SAVE_INVOICE_DATA_COMBO'
export const CART_SAVE_PAYMENT_METHOD_COMBO = 'CART_SAVE_PAYMENT_METHOD_COMBO'
export const CART_CLEAR_ITEMS_COMBO = 'CART_CLEAR_ITEMS_COMBO'
