import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom'
import { Row, Col, Container, Image } from 'react-bootstrap'
import { listByCategorypn, listOrderByNamePN, listTopProductsPN, listProductsPN } from '../actions/productActions';
import Loader from '../components/Loader';
import Message from '../components/Message';
import picture1 from '../assets/images/canape.jpg'
import picture2 from '../assets/images/bird.jpg'
import picture3 from '../assets/images/canape.jpg'
import picture4 from '../assets/images/bird.jpg'
import picture5 from '../assets/images/canape.jpg'
import picture6 from '../assets/images/bird.jpg'
import ProductShop from '../components/ProductShop';
import SearchBoxProductsPN from '../components/SearchBoxProductsPN';

function ShopScreenPN() {

    const navigate = useNavigate();
    const {id} = useParams()
    const productId = id
    const location = useLocation()
    //let category1 = location.search.split('=')[1].split('%20')[0]
    //let category2 = location.search.split('=')[1].split('%20')[1]

    const [categoryval, setCategoryval] = useState('Productos Naturales')

    const dispatch = useDispatch()

    /*const productCategory = useSelector(state => state.productCategory)
    const { error, loading, products, page, pages } = productCategory*/
    
    const productList = useSelector(state => state.productList)
    const { error, loading, products } = productList

    const options = [
        {value: '', text: '--Escoge una opción--'},
        {value: 'destacados', text: 'Destacados'},
        {value: 'atoz', text: 'A to Z'},
        {value: 'ztoa', text: 'Z to A'},
        {value: 'product', text: 'Product'},
    ];

    const [selected, setSelected] = useState(options[0].value);

    let keyword = useLocation().search

    const handleChange = event => {
        event.stopPropagation()
        if (event.target.value === 'atoz') {
            dispatch(listByCategorypn())
            setSelected(event.target.value);
        } else if (event.target.value === 'ztoa') {
            dispatch(listOrderByNamePN())
            setSelected(event.target.value);
        } else if (event.target.value === 'destacados') {
            dispatch(listTopProductsPN())
            setSelected(event.target.value);
        }
    };

    useEffect(() => {
        dispatch(listProductsPN(keyword))
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

    }, [dispatch, keyword, navigate])


   const pnHandler = () => {
        dispatch(listByCategorypn())
        setCategoryval('Productos Naturales')    
    }


  return (
    <div>
    <div class="container py-5">
        <div class="row">
 
        <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-7">
                        <ul class="list-inline shop-top-menu pb-3 pt-1">
                            <li class="list-inline-item" style={{color: '#421d74'}}>
                                <p className='fw-bolder fs-1'>{categoryval}</p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-5 d-flex justify-content-center align-items-center">
                        <div class="d-flex mb-4">
                            <SearchBoxProductsPN />
                        </div>
                    </div>
                </div>
               {
                    loading ? <Loader /> 
                    : error ? <Message variant='danger'>{error}</Message>
                    : <div>
                        <Row>
                            {products.map(product => (
                                    <ProductShop product={product}/>
                            ))}
                        </Row>
                      </div>
                }
            </div>

        </div>
    </div>
    
    </div>
  )
}

export default ShopScreenPN