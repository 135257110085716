import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useParams, useNavigate } from 'react-router-dom';
import { listfilePDFs } from '../actions/filePDFActions'
import image6 from '../assets/images/yoga5_315x295.jpg'
import image7 from '../assets/images/ACUPUNTURA_315x295.jpg'
import image8 from '../assets/images/BIOMAGNETISMO_315x295.jpg' 


function ServiceScreen() {

    const { id } = 1
    const dispatch = useDispatch()

    const filePDFeList = useSelector(state => state.filePDFeList)
    const { error, loading, filePDFs } = filePDFeList

    useEffect(() => {
            dispatch(listfilePDFs())
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, [dispatch, id])

    const handlePDFDownload = (filePDF, id) => {
        console.log(filePDF)
        axios.get(`/api/pdfFiles/download/${id}`, { 
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, filePDF);
            console.log(res);
        }).catch(err => {
            console.log(err);
        })
    }

  return (
            <div>
            <section className='mb-5'>
            <div class="container-fluid">
                <div class="row mb-4">
                        <div class="col-md text-center my-2">
                            <Link to='/servicecart' className='mt-4 btn btn-success text-light' style={{color: '#421d74'}}>Artículos Servicios</Link>
                        </div>      
                    </div>
                </div>
                <div class="container-fluid">
                    <div class="row mb-4">
                        <div class="col-md text-center my-2">
                            <span className='fw-bold textam ' style={{color: '#421d74'}}>Servicios que ofrezco</span>
                        </div>      
                    </div>
                </div>
            
            <div class="container-fluid">
            <div class="row">
                <div class="col-md text-center mb-2">
                    <img class="img-fluid"
                    src={image8}/>
                    <p className='fs-2 my-4'><b>Biomagnetismo</b></p>
                    <p className='fw-bold fs-3' style={{lineHeight: '1.5'}}>
                    El biomagnetismo es una terapia que trabaja con imanes y regula el PH de tu cuerpo para
                    estabilizarlo. Es una terapia que no se queda únicamente en el plano físico, trabaja también la
                    parte emocional y ayuda a mejorar el estado de ánimo y a eliminar estrés.
                    
                    Junto con la acupuntura son un gran aliado en tu salud. Pregunta por sesiones y costos.                    
                    </p>
 
                </div>
                <div class="col-md text-center mb-2">
                    <img class="img-fluid"
                    src={image6}/>
                    <p className='fs-2 my-4'><b>Yoga</b></p>
                    <p className='fw-bold fs-3' style={{lineHeight: '1.5'}}>El yoga es una práctica que conecta el cuerpo, la respiración y la mente. Esta práctica utiliza posturas físicas, ejercicios de respiración y meditación para mejorar la salud general. <br /> Pregunta por las clases individuales o en grupo.</p>
                    
                </div>
                <div class="col-md text-center mb-2">
                    <img class="img-fluid"
                    src={image7}/>
                    <p className='fs-2 my-4'><b>Acupuntura</b></p>
                    <p className='fw-bold fs-3' style={{lineHeight: '1.5'}}>La acupuntura es la aplicación de agujas, calor, presión y otros tratamientos en puntos estratégicos del cuerpo.
                    La acupuntura se basa en el qi (la energía vital) fluye por el organismo a través de canales que se llaman meridianos. <br /> El qi afecta tanto el estado espiritual, emocional y mental como la salud física de una persona.
                   
                    Lleva un tratamiento alternativo a tu cuerpo. Pregunta por las sesiones y costos.
                    </p>
                    
                </div>
            </div>
            </div>

        </section>
        <section>
            <div class="container-fluid">
                <div class="row">
                {filePDFs.length === 0 ? <p className='fs-4 fw-bold'><b>No existen archivos actualmente</b></p> : ''}
                {filePDFs.map(filePDF => (
                    <div key={filePDF._id} class="col-md text-center mb-2">
                        <button
                            onClick={() => handlePDFDownload(filePDF.tech_file, filePDF._id)} className="btn btn-success btn-md fs-3">Descargar Cuestionario Médico
                        </button>
                        <p className='text-danger fs-bold mt-2 fs-5'><b>Espere a que baje el archivo PDF</b></p>
                    </div>                        
                ))}
                     <div class="col-md text-center mb-2">
                        <Link to='/contact' className='text-decoration-none text-success'>
                            <a href="#" class="btn btn-success fs-4" style={{fontFamily: 'Quicksand'}}>Contáctame</a>
                        </Link>
                     </div>
                     
                </div>
            </div>
        </section>
        <div class="clear"></div>


</div>

  )
}

export default ServiceScreen