import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { Row, Col, Image, ListGroup, Button, Form, Container } from 'react-bootstrap';
import Rating from '../components/Rating';
import Loader from '../components/Loader';
import Message from '../components/Message';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useParams, useNavigate } from 'react-router-dom';
import { listProductDetails, createProductReview } from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
import { listMyOrders } from '../actions/orderActions'
import NumberFormat from 'react-number-format';
import ImageGallery from '../components/ImageGallery';

function ProductScreen() {
    const [qty, setQty] = useState(1)
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')
    const [categorypg, setCategorypg] = useState('Parrilla de Gas')
   
    const { id } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails)
    const { error, loading, product } = productDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const productReviewCreate = useSelector(state => state.productReviewCreate)
    const { error: errorProductReview, loading: loadingProductReview, success: successProductReview } = productReviewCreate

    const orderListMy = useSelector(state => state.orderListMy)
    const { loading: loadingOrders, error: errorOrders, orders } = orderListMy

    useEffect(() => {
        if(successProductReview){
            setRating(0)
            setComment('')
            dispatch({type: PRODUCT_CREATE_REVIEW_RESET})
        }
        dispatch(listProductDetails(id))
        dispatch(listMyOrders())

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        
    }, [dispatch, id, successProductReview])

    const addToCartHandler = () => {
        navigate(`/cart/${id}?qty=${qty}`);
    }

    const toShoppingHandler = () => {
        if (product.category === 'Cocina Gourmet') {
            navigate('/shopCG');
        } else if (product.category === 'Productos Naturales') {
            navigate('/shopPN');
        }
        
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createProductReview(id, {
            rating,
            comment
        }))
    }

    const handlePDFDownload = (filePDF) => {

        axios.get(`/api/products/download/${id}`, { 
            responseType: 'blob',
        }).then(res => {
            fileDownload(res.data, filePDF);
            console.log(res);
        }).catch(err => {
            console.log(err);
        })
    }


  return (

    <div>
    <Container>
        {product.category === 'Cocina Gourmet' ? <Link className='btn btn-light my-3' to='/shopCG'>Go Back</Link> : <Link className='btn btn-light my-3' to='/shopPN'>Go Back</Link>}
   
    <div>
    <section class="bg-light">
    <div class="container pb-5">
        <div class="row">
            <div class="col-lg-5 mt-5">
                <div class="card mb-3">
                    <Image src={product.image1} alt={product.name} fluid resizeMode='contain'/>
                </div>
                <div class="row">
                    <ImageGallery product={product}/>
                </div>
                <Row className='my-4 justify-content-md-center'>
                    <Col lg={12} >
                        <p className='fs-4 fw-bold' style={{color: '#421d74'}}>Comentarios</p>
                        {product.reviews.length === 0 && <Message variant='warning'>Sin comentarios...</Message>}
                        <ListGroup variant='flush'>
                            {product.reviews.map((review) => (
                                <ListGroup.Item key={review._id}>
                                    <p className='fs-4 fw-bold'>{review.name}</p>
                                    <Rating value={review.rating} color='#f8e825' />
                                    <p>{review.createdAt.substring(0, 10)}</p>
                                    <p className='fs-4 fw-bold'>{review.comment}</p>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                        <ListGroup.Item>
                            <p className='fs-4 fw-bold'>Escribe un comentario</p>

                            {loadingProductReview && <Loader />}
                            {successProductReview && <Message variant='success'>Comentario Enviado</Message>}
                            {errorProductReview && <Message variant='danger'>{errorProductReview}</Message>}
   
                            {userInfo ? (
                                <Form onSubmit={submitHandler}>
                                    <Form.Group controlId='ratings'>
                                        <Form.Label className='fs-3 fw-bold mt-1'>Rating</Form.Label>
                                        <Form.Control
                                            as='select'
                                            value={rating}
                                            onChange={(e) => setRating(e.target.value)}
                                            className='fs-4 fw-bold'
                                        >
                                            <option value=''>Selecciona...</option>
                                            <option value='1' className='fw-bold'><b>1 - Pobre</b></option>
                                            <option value='2' className='fw-bold'><b>2 - Pasa</b></option>
                                            <option value='3' className='fw-bold'><b>3 - Bueno</b></option>
                                            <option value='4' className='fw-bold'><b>4 - Muy bueno</b></option>
                                            <option value='5' className='fw-bold'><b>5 - Excelente</b></option>                                                                                                                                                                                   
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group controlId='comment'>
                                        <Form.Label className='fs-4 fw-bold mt-1'>Review</Form.Label>
                                        <Form.Control
                                            as='textarea'
                                            row='5'
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                        >
                                        </Form.Control>                               
                                    </Form.Group>
                                    <Button
                                        disabled={loadingProductReview}
                                        type='submit'
                                        variant='outline-primary'
                                        className='btn-sm px-2 py-2 mt-2 btn-space fw-bold fs-4'
                                        style={{fontFamily: 'Quicksand'}}
                                    >
                                        Enviar Comentario
                                    </Button>
                                </Form>
                            ) : (
                                <Message variant='info'>
                                    Por favor <Link to='/login' className='fw-bold' style={{color: '#1e752f'}}>Ingresar</Link> para escribir comentario
                                </Message>
                            )}
                        </ListGroup.Item>
                </Col>
            </Row>                
            </div>

            <div class="col-lg-7 mt-5">
                <div class="card">
                    <div class="card-body">
                        <p class="fs-1 fw-bold" >{product.name}</p>
                        {product.discount > 0 ? <p class="py-2 text-decoration-line-through text-danger fs-4" ><b>De: ${product.pricereg} MN</b></p> : ''}
                        <p class="py-2 text-success fs-4"><strong>A solo: <NumberFormat value={Number((product.pricereg - ((product.pricereg / 100) * product.discount))).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2}/>MN</strong></p>
                        <Rating value={product.rating} text={`${product.numReviews} reviews`} color='#f8e825'/>
                        <ul class="list-inline">
                            <li class="list-inline-item">
                                <p className='fs-4 fw-bold'>Categoria:</p>
                            </li>
                            <li class="list-inline-item">
                                <p class="text-muted fs-4"><strong>{product.category}</strong></p>
                            </li>                            
                        </ul>

                        <p className='fs-4 fw-bold'>Descripción:</p>
                        <p className='fs-4 fw-bold text-muted'>{product.description}</p>
                        <Row className='mt-4'>
                            <Col md={6}>            
                                <p className='fs-4 fw-bold'>Especificaciones:</p>
                                <ul class="list-unstyled pb-3 fs-4 text-muted fw-bold">
                                    <li>{product.spec1}</li>
                                    <li>{product.spec2}</li>
                                    <li>{product.spec3}</li>
                                </ul>
                            </Col>
                          <Col md={6}>      
                            <Row>
                                <Col md={12} className='d-flex justify-content-center align-items-center'>
                                    <p className='fs-4 fw-bold'>Ficha Técnica</p>
                                </Col>
                            </Row>
                            <Row>
                            {product.tech_file ? 
                                <Col md={12} className='d-flex justify-content-center align-items-center my-2'>
                                <Button
                                    type='button'
                                    onClick={() => handlePDFDownload(product.tech_file)} 
                                    className="btn-sm px-2 py-2 btn-space fw-bold fs-4"
                                    variant='outline-primary'
                                    style={{fontFamily: 'Quicksand'}} >
                                    Descargar Archivo
                                </Button>    
                            </Col>
                            :
                                <Col md={12} className='d-flex justify-content-center align-items-center my-2 fw-bold'> 
                                    <p className='fs-4 text-muted fw-bold'>No existe archivo técnico a descargar</p>    
                                </Col>
                                }
                            </Row>
                           </Col>
                        </Row>
                        

                        <form action="" method="GET">
                            <input type="hidden" name="product-title" value="Activewear" />

                                           <Row>

                                                <Col lg={6} className='d-flex justify-content-start align-items-center my-2'>
                                                    <span className='fs-4 fw-bold mx-1'>Status :</span>
                                                        {product.countInStock > 0 ? <strong className='fs-4 text-muted'> En Stock</strong> : <strong className='text-danger'> Fuera de Stock</strong>}
    
                                                        <input type="hidden" name="product-quanity" id="product-quanity" value="1" />                                            
                                                </Col>                                            
                                                <Col lg={5} className='d-flex justify-content-center align-items-center my-2 fw-bold' >
                                                    <span className='fs-4 fw-bold mx-1'>Cantidad:</span>
                                                    <Form.Control 
                                                    as="select" 
                                                    value={qty}
                                                    onChange={(e) => setQty(e.target.value)}
                                                    className='fw-bold fs-4 mx-2'
                                                    >
                                                    {
                                                        [...Array(product.countInStock).keys()].map((x) => (
                                                            <option key={ x + 1 } value={ x + 1 }>
                                                                {x + 1}
                                                            </option>
                                                        ))
                                                    }
                                                    </Form.Control>                                                    
                                                </Col>

      
                                        </Row>
                                                           

                            <div class="row pb-3">
                                <div class="col d-grid">
                                    <Button 
                                        type='button'
                                        onClick={toShoppingHandler}
                                        style={{fontFamily: 'Quicksand'}}
                                        variant='outline-primary'
                                        className='btn-sm px-2 py-2 btn-space fw-bold fs-4'

                                    >Comprar</Button>
                                </div>
                                <div class="col d-grid">
                                <Button
                                    className="btn-sm px-2 py-2 btn-space fw-bold fs-4"                                
                                    disabled={product.countInStock === 0 || product.countInStock < 0} 
                                    type='button'
                                    variant='outline-primary'
                                    onClick={addToCartHandler}
                                    style={{fontFamily: 'Quicksand'}}
                                    >
                                    Agregar a la Compra
                                </Button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

    </div>

    </Container>  
    
    </div>
  )
}

export default ProductScreen

