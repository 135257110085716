import React from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

function CheckoutSteps({ step1, step2, step3, step4, step5 }) {
  return (
    <Nav className='justify-content-center my-4'>
        <Nav.Item>
            {step1 ? (
                <LinkContainer to='/login'>
                    <Nav.Link className='fs-4 my-1 fw-bolder stepcolor'>Login</Nav.Link>
                </LinkContainer>            
                ) : (
                    <Nav.Link className='fs-4 my-1 fw-bolder stepcolor' disabled>Login</Nav.Link>
                )}
        </Nav.Item>

        <Nav.Item>
            {step2 ? (
                <LinkContainer to='/shipping'>
                    <Nav.Link className='fs-4 my-1 fw-bolder stepcolor'>Envío</Nav.Link>
                </LinkContainer>            
                ) : (
                    <Nav.Link className='fs-4 my-1 fw-bolder stepcolor' disabled>Envío</Nav.Link>
                )}
        </Nav.Item>

        <Nav.Item>
            {step3 ? (
                <LinkContainer to='/invoice'>
                    <Nav.Link className='fs-4 my-1 fw-bolder stepcolor'>Facturación</Nav.Link>
                </LinkContainer>            
            ) : (
                <Nav.Link className='fs-4 my-1 fw-bolder stepcolor' disabled>Facturación</Nav.Link>
            )}
        </Nav.Item>

        <Nav.Item>
            {step4 ? (
                <LinkContainer to='/payment'>
                    <Nav.Link className='fs-4 my-1 fw-bolder stepcolor'>Pago</Nav.Link>
                </LinkContainer>            
                ) : (
                    <Nav.Link className='fs-4 my-1 fw-bolder stepcolor' disabled>Pago</Nav.Link>
                )}
        </Nav.Item>

        <Nav.Item>
            {step5 ? (
                <LinkContainer to='/placeorder'>
                    <Nav.Link className='fs-4 my-1 fw-bolder stepcolor'>Colocar Orden</Nav.Link>
                </LinkContainer>            
                ) : (
                    <Nav.Link className='fs-4 my-1 fw-bolder stepcolor' disabled>Colocar Orden</Nav.Link>
                )}
        </Nav.Item>
    </Nav>
  )
}

export default CheckoutSteps