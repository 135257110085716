import React, {useState, useEffect} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import image1 from '../assets/images/LOGOUNOMASUNO.png'
import image5 from '../assets/images/LOGOSOKLORENA.png'

function PorqueScreen() {

 window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  return (
    <div class='container'>
      <section className='p-1 text-center text-sm-start'>
          <Row className='mt-4'>
            <Col lg={3} className='d-flex justify-content-center align-items-center'>
                <img class="img-fluid"
                src={image1} style={{width: 300}}/>
            </Col>
            <Col lg={9} className='d-flex justify-content-center align-items-center mt-3'>
            <p className='text-center fw-bold fs-1 fst-italic' style={{color: '#596488'}}>Porque, Uno + Uno = tres</p>
          </Col>
          </Row>
          <hr className='text-info'/>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='colortextabout text-initial fs-3 fw-bolder' style={{lineHeight: '1.5'}}>A lo largo de este recorrido, conocerán como fue que surgió la idea de crear uno + uno =3.
              Compartiré con ustedes este proceso, donde descubrí en realidad quien es Lorena. <br />
              <br/> ¿Nos les ha pasado que un día se ven al espejo y solo ven una imagen?, bueno pues así empieza mi historia, la historia de este proyecto. <br />
              <br/> En este camino que he recorrido; me he dado cuenta de que estamos separados de nuestro cuerpo físico, emocional, mental y espiritual. <br/> La búsqueda de respuestas a veces nos aleja de una y nos acerca a otras. Debemos de recordar todos los días que somos la suma de todo. <br/> 
              <br/> Han oído hablar de los números de Fibonacci 1, 1, 2, 3, 5, 8, 13 …., de ahí tomo la importancia de que la suma de 1 + 1 no es 2, cuando das un paso hacia adelante y luego otro y luego otro y otro, al final te vas a dar cuenta que recorriste mas de lo que imaginaste, y cuando menos lo esperas, estas ya en tu 1er meta. <br />
              <br/> Por eso UNO + UNO para mi es TRES.
              Y este proyecto SOY YO. <br />
              <br />
              Es un espacio libre y lleno de amor, donde podemos compartir nuestros procesos, nuestras inquietudes; donde podemos encontrarnos entre nosotros y reencontramos con nosotros; porque deben saber que no están solos, que nos son los únicos que sienten que no encajan en este mundo, o que siguen en una rueda sin fin o simplemente están en busca de su verdadero YO.
              <br /> <br />
              Aquí nos quitaremos todas esas capas que nos impiden ver la belleza en cada uno de nosotros y que hemos guardado u ocultado por tantos años. <br/> <br/> Encontraremos nuestra certeza, nuestra alegría, nuestra esencia, nuestra verdad y sobre todo nuestro amor; porque, aunque esta frase suene trillada “Cada uno somos únicos e irrepetibles”
              <br />
              <br />
              Bienvenidos 
            </p>
            </Col>
          </Row>
          <Row className='container-fluid'>
            <Col md={12} className="p-1 mb-4 d-flex justify-content-end align-items-center">
            <Link to='/bio' className='text-decoration-none text-success'>
                <img class="img-fluid d-block"
                src={image5} style={{width: 160}}/>
            </Link>                                   
            </Col>
          </Row>   
          <hr className='text-info'/>                   
          </section>

    </div>
  )
}

export default PorqueScreen