import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button } from 'react-bootstrap';
import FormContainer from '../components/FormContainer'
import { saveShippingAddress } from '../actions/cartActions'
import CheckoutSteps from '../components/CheckoutSteps';

function ShippingScreen() {
  const cart = useSelector(state => state.cart)
  const { shippingAddress } = cart

  const dispatch = useDispatch()

  const navigate = useNavigate()
  
  const [address, setAddress] = useState(shippingAddress.address)
  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [country, setCountry] = useState(shippingAddress.country)

  useEffect(() => {

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
  }, [])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(saveShippingAddress({ address, city, postalCode, country }))
    navigate('/invoice')
  }

  
  return (
    <FormContainer>
    <CheckoutSteps step1 step2/>
      <p className='my-1 fw-bolder titulocompra fs-1'>Envío</p>
      <Form onSubmit={submitHandler} className='my-4'>
          <Form.Group controlId='address'>
            <Form.Label className='my-1 fw-bolder fs-3'><b>Dirección</b></Form.Label>
            <Form.Control
                required
                type='text'
                placeholder='Ingresa la dirección'
                value={address ? address : ''}
                onChange={(e) => setAddress(e.target.value)}
                className='fs-4 fw-bold'
            >
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='city'>
            <Form.Label className='my-2 fw-bolder fs-3'><b>Ciudad</b></Form.Label>
            <Form.Control
                required
                type='text'
                placeholder='Ingresa la ciudad'
                value={city ? city : ''}
                onChange={(e) => setCity(e.target.value)}
                className='fs-4 fw-bold'
            >
            </Form.Control>
          </Form.Group>

          <Form.Group controlId='postalCode'>
            <Form.Label className='my-2 fw-bolder fs-3'><b>Código Postal</b></Form.Label>
            <Form.Control
                required
                type='text'
                placeholder='Ingresa el código postal'
                value={postalCode ? postalCode : ''}
                onChange={(e) => setPostalCode(e.target.value)}
                className='fs-4 fw-bold'
            >
            </Form.Control>
          </Form.Group>

            <Form.Group controlId='country'>
              <Form.Label className='my-2 fw-bolder fs-4'><b>País</b></Form.Label>
              <Form.Control
                  required
                  type='text'
                  placeholder='Ingresa el País'
                  value={country ? country : ''}
                  onChange={(e) => setCountry(e.target.value)}
                  className='fs-4 fw-bold'
              >
              </Form.Control>
            </Form.Group>
            <Button type='submit' variant='primary' className='btn-md mt-3 bg-success fs-4' style={{fontFamily: "Quicksand"}}>Continuar</Button>
    </Form>
    </FormContainer>
  )
}

export default ShippingScreen
