import { 
    FORO_LIST_REQUEST, 
    FORO_LIST_SUCCESS, 
    FORO_LIST_FAIL,
    FORO_DETAILS_REQUEST,
    FORO_DETAILS_SUCCESS,
    FORO_DETAILS_FAIL,
    FORO_CREATE_DETAILS_REQUEST,
    FORO_CREATE_DETAILS_SUCCESS,
    FORO_CREATE_DETAILS_FAIL,
    FORO_CREATE_DETAILS_RESET,
    
    FORO_DELETE_REQUEST,
    FORO_DELETE_SUCCESS,
    FORO_DELETE_FAIL,

    FORO_CREATE_REQUEST, 
    FORO_CREATE_SUCCESS,
    FORO_CREATE_FAIL, 
    FORO_CREATE_RESET,

    FORO_UPDATE_REQUEST,
    FORO_UPDATE_SUCCESS,
    FORO_UPDATE_FAIL,
    FORO_UPDATE_RESET,

} from '../constants/foroConstants'

export const foroListReducers = (state = {foros: []}, action) => {
    switch(action.type){
        case FORO_LIST_REQUEST:
            return {loading: true, foros:[]}
        case FORO_LIST_SUCCESS:
            return {loading: false, foros: action.payload}
        case FORO_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const foroDetailsReducers = (state = {foro: {reviews:[]}}, action) => {
    switch(action.type){
        case FORO_DETAILS_REQUEST:
            return { loading: true, ...state }
        case FORO_DETAILS_SUCCESS:
            return {loading: false, foro: action.payload}
        case FORO_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const foroDetailCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case FORO_CREATE_DETAILS_REQUEST:
            return { loading: true }

        case FORO_CREATE_DETAILS_SUCCESS:
            return { loading: false, success: true }

        case FORO_CREATE_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        case FORO_CREATE_DETAILS_RESET:
                return {}            
        default:
            return state
    }
}

export const foroCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case FORO_CREATE_REQUEST:
            return { loading: true }

        case FORO_CREATE_SUCCESS:
            return { loading: false, success: true, foro: action.payload }

        case FORO_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case FORO_CREATE_RESET:
                return {}            

        default:
            return state
    }
}


export const foroDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case FORO_DELETE_REQUEST:
            return { loading: true }

        case FORO_DELETE_SUCCESS:
            return { loading: false, success: true }

        case FORO_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const foroUpdateReducer = (state = { foro: {} }, action) => {
    switch (action.type) {
        case FORO_UPDATE_REQUEST:
            return { loading: true }

        case FORO_UPDATE_SUCCESS:
            return { loading: false, success: true, foro: action.payload }

        case FORO_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case FORO_UPDATE_RESET:
                return { foro: {} }            

        default:
            return state
    }
}