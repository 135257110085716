import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Row, Col,  } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { register } from '../actions/userActions';
import FormContainer from '../components/FormContainer'

function RegisterScreen() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const location = useLocation()

    const redirect = location.search ? location.search.split('=')[1] : '/'
    const userRegister = useSelector(state => state.userRegister)
    const { loading, userInfo, error } = userRegister

    useEffect(() => {
        if(userInfo){
            navigate(redirect)
        }
    }, [navigate, redirect, userInfo])

    const submitHandler = (e) => {
        e.preventDefault()
        if(password !== confirmPassword){
            setMessage('Passwords do not match')
        }else{
            dispatch(register(name, email, password))            
        }
    }
    
  return (
    <FormContainer>
        <p className='mt-4 fs-1 fw-bold'>Registrarse</p>
        {message && <Message variant='danger'>{message}</Message>}        
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}  
        <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
                <Form.Label className='fs-5 mt-2'><b>Name</b></Form.Label>
                <Form.Control
                    required
                    type='name'
                    placeholder='Enter your Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className='fw-bold'
                >
                </Form.Control>
            </Form.Group>
            <Form.Group controlId='email' className='my-2'>
                <Form.Label className='fs-5'><b>Email Address</b></Form.Label>
                <Form.Control
                    required
                    type='email'
                    placeholder='Enter your Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='fw-bold'
                >
                </Form.Control>
            </Form.Group>
            <Form.Group controlId='password' className='my-2'>
            <Form.Label className='fs-5'><b>Password</b></Form.Label>
                <Form.Control
                    required
                    type='password'
                    placeholder='Enter your password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className='fw-bold'
                >
                </Form.Control>
            </Form.Group>
            <Form.Group controlId='passwordConfirm' className='my-2'>
            <Form.Label className='fs-5'><b>Confirmar Password</b></Form.Label>
                <Form.Control
                    required
                    type='password'
                    placeholder='Enter your password again'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className='fw-bold'
                >
                </Form.Control>
            </Form.Group>
            <Button type='submit' variant='primary' className='btn-md my-2 bg-success' style={{fontFamily:'Quicksand'}}>Registrar</Button>
        </Form>
        <Row className='py-1'>
            <Col className='fs-6 fw-bold'>
                Ya estas registrado? <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>Ingresar</Link>
            </Col>
        </Row>     
    </FormContainer>
  )
}

export default RegisterScreen