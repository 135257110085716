import axios from 'axios';
import { 
    RECIPE_LIST_REQUEST, 
    RECIPE_LIST_SUCCESS, 
    RECIPE_LIST_FAIL,
    RECIPE_DETAILS_REQUEST, 
    RECIPE_DETAILS_SUCCESS, 
    RECIPE_DETAILS_FAIL,
    RECIPE_DELETE_REQUEST, 
    RECIPE_DELETE_SUCCESS, 
    RECIPE_DELETE_FAIL,
    RECIPE_CREATE_REQUEST, 
    RECIPE_CREATE_SUCCESS, 
    RECIPE_CREATE_FAIL,
    RECIPE_CREATE_RESET,
    RECIPE_UPDATE_REQUEST, 
    RECIPE_UPDATE_SUCCESS, 
    RECIPE_UPDATE_FAIL,
    RECIPE_UPDATE_RESET,
    RECIPE_CATEGORY_REQUEST,
    RECIPE_CATEGORY_SUCCESS,
    RECIPE_CATEGORY_FAIL
} from '../constants/recipeConstants';

export const listRecipes = () => async (dispatch) => {
    try {
        dispatch({ type: RECIPE_LIST_REQUEST })

        const { data } = await axios.get('/api/recipes/')

        dispatch({
            type: RECIPE_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RECIPE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listRecipesCG = (keyword = '') => async (dispatch) => {
    try {
        dispatch({ type: RECIPE_LIST_REQUEST })

        const { data } = await axios.get(`/api/recipes/krecipeCG${keyword}`)

        dispatch({
            type: RECIPE_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RECIPE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listRecipesPN = (keyword = '') => async (dispatch) => {
    try {
        dispatch({ type: RECIPE_LIST_REQUEST })

        const { data } = await axios.get(`/api/recipes/krecipePN${keyword}`)

        dispatch({
            type: RECIPE_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RECIPE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listRecipeDetails = (id) => async (dispatch) => {
    try{
        dispatch({
            type: RECIPE_DETAILS_REQUEST
        })
        const {data} = await axios.get(`/api/recipes/${id}`)

        dispatch({
            type: RECIPE_DETAILS_SUCCESS,
            payload: data
        })
    }catch(error){
        dispatch({
            type: RECIPE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteRecipe = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: RECIPE_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `/api/recipes/delete/${id}/`,
            config
        )

        dispatch({
            type: RECIPE_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: RECIPE_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const createRecipe = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: RECIPE_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/recipes/create/`,
            {},
            config
        )

        dispatch({
            type: RECIPE_CREATE_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: RECIPE_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const updateRecipe = (recipe) => async (dispatch, getState) => {
    try {
        dispatch({
            type: RECIPE_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `/api/recipes/update/${recipe._id}/`,
            recipe,
            config
        )

        dispatch({
            type: RECIPE_UPDATE_SUCCESS,
            payload: data
        })

        dispatch({ 
            type: RECIPE_DETAILS_SUCCESS, 
            payload: data
        })


    } catch (error) {
        dispatch({
            type: RECIPE_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listByCategorycg = () => async (dispatch) => {
    try {
        dispatch({ type: RECIPE_CATEGORY_REQUEST })

        const { data } = await axios.get(`/api/recipes/bycategorycg/`)

        dispatch({
            type: RECIPE_CATEGORY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RECIPE_CATEGORY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listByCategorypn = () => async (dispatch) => {
    try {
        dispatch({ type: RECIPE_CATEGORY_REQUEST })

        const { data } = await axios.get(`/api/recipes/bycategorypn/`)

        dispatch({
            type: RECIPE_CATEGORY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: RECIPE_CATEGORY_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}