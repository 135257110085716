import React from 'react'
import { Alert } from 'react-bootstrap'

function Message({ variant, children }) {
  return (
    <Alert
        variant={variant}
    >
        <b className='fs-4 text-dark fw-bolder'>{children}</b>
    </Alert>
  )
}

export default Message