import axios from "axios";
import { CART_ADD_ITEM, 
        CART_REMOVE_ITEM, 
        CART_SAVE_SHIPPING_ADDRESS,
        CART_SAVE_INVOICE_DATA,
        CART_SAVE_PAYMENT_METHOD,
        CART_CLEAR_ITEMS,               
         } from '../constants/cartConstants';

export const addToCart = (id, qty) => async(dispatch, getState) => {
    const {data} = await axios.get(`/api/products/${id}`)
    dispatch({
        type: CART_ADD_ITEM,
        payload: {
            product: data._id,
            name: data.name,
            image1: data.image1,
            pricereg: data.pricereg,
            pricedis: data.pricedis,
            discount: data.discount,
            model: data.model,
            category: data.category,
            countInStock: data.countInStock,
            qty
        }
    })
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => (dispatch, getState) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: id,

    })
    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data,

    })
    localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const saveInvoiceData = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_INVOICE_DATA,
        payload: data,

    })
    localStorage.setItem('invoiceData', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data,

    })
    localStorage.setItem('paymentMethod', JSON.stringify(data))
}
