export const DISCOUNT_LIST_REQUEST = 'DISCOUNT_LIST_REQUEST'
export const DISCOUNT_LIST_SUCCESS = 'DISCOUNT_LIST_SUCCESS'
export const DISCOUNT_LIST_FAIL = 'DISCOUNT_LIST_FAIL'

export const DISCOUNT_DETAILS_REQUEST = 'DISCOUNT_DETAILS_REQUEST'
export const DISCOUNT_DETAILS_SUCCESS = 'DISCOUNT_DETAILS_SUCCESS'
export const DISCOUNT_DETAILS_FAIL = 'DISCOUNT_DETAILS_FAIL'

export const DISCOUNT_DELETE_REQUEST = 'DISCOUNT_DELETE_REQUEST'
export const DISCOUNT_DELETE_SUCCESS = 'DISCOUNT_DELETE_SUCCESS'
export const DISCOUNT_DELETE_FAIL = 'DISCOUNT_DELETE_FAIL'

export const DISCOUNT_CREATE_REQUEST = 'DISCOUNT_CREATE_REQUEST'
export const DISCOUNT_CREATE_SUCCESS = 'DISCOUNT_CREATE_SUCCESS'
export const DISCOUNT_CREATE_FAIL = 'DISCOUNT_CREATE_FAIL'
export const DISCOUNT_CREATE_RESET = 'DISCOUNT_CREATE_RESET'

export const DISCOUNT_UPDATE_REQUEST = 'DISCOUNT_UPDATE_REQUEST'
export const DISCOUNT_UPDATE_SUCCESS = 'DISCOUNT_UPDATE_SUCCESS'
export const DISCOUNT_UPDATE_FAIL = 'DISCOUNT_UPDATE_FAIL'
export const DISCOUNT_UPDATE_RESET = 'DISCOUNT_UPDATE_RESET'
