import { 
    FILEPDF_LIST_REQUEST, 
    FILEPDF_LIST_SUCCESS, 
    FILEPDF_LIST_FAIL,
    FILEPDF_DETAILS_REQUEST, 
    FILEPDF_DETAILS_SUCCESS, 
    FILEPDF_DETAILS_FAIL,
    FILEPDF_DELETE_REQUEST, 
    FILEPDF_DELETE_SUCCESS, 
    FILEPDF_DELETE_FAIL,
    FILEPDF_CREATE_REQUEST, 
    FILEPDF_CREATE_SUCCESS, 
    FILEPDF_CREATE_FAIL,
    FILEPDF_CREATE_RESET,
    FILEPDF_UPDATE_REQUEST, 
    FILEPDF_UPDATE_SUCCESS, 
    FILEPDF_UPDATE_FAIL,
    FILEPDF_UPDATE_RESET,
} from '../constants/filePDFConstants';

export const filePDFListReducers = (state = {filePDFs: []}, action) => {
    switch(action.type){
        case FILEPDF_LIST_REQUEST:
            return {loading: true, filePDFs:[]}
        case FILEPDF_LIST_SUCCESS:
            return {loading: false, filePDFs: action.payload}
        case FILEPDF_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const filePDFDetailsReducers = (state = { filePDF: {reviews:[]} }, action) => {
switch(action.type) {
    case FILEPDF_DETAILS_REQUEST:
        return { loading: true, ...state }
    case FILEPDF_DETAILS_SUCCESS:
        return { loading: false, filePDF: action.payload }
    case FILEPDF_DETAILS_FAIL:
        return { loading: false, error: action.payload }
    default:
        return state              
}
}

export const filePDFCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case FILEPDF_CREATE_REQUEST:
            return { loading: true }
        case FILEPDF_CREATE_SUCCESS:
            return { loading: false, success: true, filePDF: action.payload }
        case FILEPDF_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case FILEPDF_CREATE_RESET:
                return {}            
        default:
            return state
    }
}


export const filePDFDeleteReducer = (state = {}, action) => {
switch (action.type) {
    case FILEPDF_DELETE_REQUEST:
        return { loading: true }

    case FILEPDF_DELETE_SUCCESS:
        return { loading: false, success: true }

    case FILEPDF_DELETE_FAIL:
        return { loading: false, error: action.payload }

    default:
        return state
}
}


export const filePDFUpdateReducer = (state = {filePDF: {}}, action) => {
switch (action.type) {
    case FILEPDF_UPDATE_REQUEST:
        return { loading: true }

    case FILEPDF_UPDATE_SUCCESS:
        return { loading: false, success: true, filePDF: action.payload }

    case FILEPDF_UPDATE_FAIL:
        return { loading: false, error: action.payload }

    case FILEPDF_UPDATE_RESET:
            return { service: {} }            

    default:
        return state
    }
}