import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listRecipes, deleteRecipe, createRecipe } from '../actions/recipeActions'
import { RECIPE_CREATE_RESET } from '../constants/recipeConstants'
import Moment from 'react-moment';


function RecipeListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [quickFilter, setQuickFilter] = useState("");
  
  const recipeList = useSelector(state => state.recipeList)
  const { loading, error, recipes } = recipeList

  const recipeDelete = useSelector(state => state.recipeDelete)
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = recipeDelete

  const recipeCreate = useSelector(state => state.recipeCreate)
  const { loading: loadingCreate, success: successCreate, error: errorCreate, recipe: createdRecipe } = recipeCreate

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let keyword = useLocation().search

  useEffect(() => {
    dispatch({ type: RECIPE_CREATE_RESET })
    if(!userInfo){
      navigate('/login')
    }
    
    dispatch(listRecipes(keyword))

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  }, [dispatch, navigate, userInfo, successDelete, successCreate, createdRecipe, keyword])

  const deleteHandler = (id) => {
    if(window.confirm('Estas seguro de borrar esta receta o articulo?')){
      dispatch(deleteRecipe(id))
    }
  }

  const createRecipeHandler = () => {
    dispatch(createRecipe())
  }

  return (
    <div className='container mt-5'>
        <div class='row mt-5'>
            <div class='col-sm-4 d-flex justify-content-start align-items-center'>
                <p className='text-dark-50 text-dark mt-3 fs-2 fw-bold'>Recetas o Artículos</p>
            </div>
            <div class='col-sm-3 d-flex justify-content-center align-items-center'>
                <Button className='btn btn-success mt-3 fs-3'  style={{fontFamily: "Quicksand"}} onClick={createRecipeHandler}>
                    <i className='fas fa-plus'></i> Crear Nueva Receta o Artículo
                </Button>
            </div>
            <div class='col-sm-5 d-flex justify-content-center align-items-center'>
              <span className='text-center border border-danger border-2 p-1 text-danger fs-5 mt-2'><b>Importante: En esta sección, si creas nueva receta, para accesar, favor de editarlo</b></span>
            </div>           
        </div>
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

        {loadingCreate && <Loader />}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}        

        {loading ? 
            (<Loader />)
          : error ?
            (<Message variant='danger'>{error}</Message>)
          : (
            <div>
              <Table bordered hover responsive className='table-lg text-dark fs-4 mt-3 fw-bold'>
                <thead className="table-primary">
                <tr>
                  <th>NAME</th>
                  <th>UP BY</th>
                  <th>TITLE</th>                    
                  <th>CATEGORY</th>
                  <th>CREATE BY</th>
                  <th>TIMELINE</th>                                                        
                  <th>DATE</th>
                  <th></th>
                  <th></th>                            
                </tr>
              </thead>
              <tbody>
              {recipes.length === 0 ? <p className='text-danger fx-2'><b>No existen Recetas actualmente</b></p> : ''}
                {recipes.map(recipe => (
                  <tr key={recipe._id}>
                    <td>{recipe.name}</td>
                    <td>{recipe.author}</td>
                    <td>{recipe.title}</td>
                    <td>{recipe.category}</td>                                        
                    <td>{recipe.createby}</td>
                    <td>{recipe.timeline}</td>                                        
                    <td><Moment format="DD/MM/YYYY hh:mm:ss">{recipe.createdAt}</Moment></td>
                    <td>
                      <LinkContainer to={`/admin/recipe/${recipe._id}/edit`}>
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-edit'></i>
                        </Button>
                      </LinkContainer>
                    </td>
                    <td>  
                      <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(recipe._id)}>
                        <i className='fas fa-trash fs-4'></i>
                      </Button>                    
                    </td>
                  </tr>
                ))}
  
            </tbody>
          </Table>
          </div>
        )  
      }
    </div>
  )
}

export default RecipeListScreen