import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button, Row, Col, Container } from 'react-bootstrap';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { listProducts, deleteProduct, createProduct } from '../actions/productActions'
import { PRODUCT_CREATE_RESET } from '../constants/productConstants'
import image5 from '../assets/images/LOGOSOKLORENA.png'

function ProductListScreen() {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  
  const productList = useSelector(state => state.productList)
  const { loading, error, products } = productList

  const productDelete = useSelector(state => state.productDelete)
  const { loading: loadingDelete, success: successDelete, error: errorDelete } = productDelete

  const productCreate = useSelector(state => state.productCreate)
  const { loading: loadingCreate, success: successCreate, error: errorCreate, product: createdProduct } = productCreate

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  let keyword = useLocation().search

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET })

    if(userInfo && userInfo.isAdmin){
      dispatch(listProducts(keyword))
    }else{
      navigate('/login');
    }

    if(successCreate){
        navigate(`/admin/product/${createdProduct._id}/edit`)
    }else{
      dispatch(listProducts(keyword))
    }

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});

  }, [dispatch, navigate, userInfo, successDelete, successCreate, createdProduct, keyword])

  const deleteHandler = (id) => {
    if(window.confirm('Estas seguro de borrar este producto?')){
      dispatch(deleteProduct(id))
    }
  }

  const createProductHandler = () => {
    dispatch(createProduct())
  }
  
  return (
    <Container>
    <div>
        <Row className='align-items-center'>
            <Col md={4}>
                <p className='fs-1 fw-bold'>Productos</p>
            </Col>
            <Col md={4} className='text-right d-flex justify-content-center align-items-center mt-3'>
                <Button className='my-3 bg-success fs-3' onClick={createProductHandler} style={{fontFamily: "Quicksand"}}>
                    <i className='fas fa-plus'></i> Crear Producto
                </Button>
            </Col>
            <Col md={4} className="p-1 mb-1 d-flex justify-content-end align-items-center">
              <Link to='/bio' className='text-decoration-none text-success'>
                <img class="img-fluid d-block"
                src={image5} style={{width: 160}}/>
              </Link>                                   
            </Col>            
        </Row>
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

        {loadingCreate && <Loader />}
        {errorCreate && <Message variant='danger'>{errorCreate}</Message>}        

        {loading ? 
          (<Loader />)
        : error ?
          (<Message variant='danger'>{error}</Message>)
        : (
          <div>
            <Table striped bordered hover responsive className='table-lg text-dark fs-3 mt-3 fw-bold'>
              <thead className='table-primary'>
              <tr>
                <th>ID</th>
                <th>NOMBRE</th>
                <th>PRECIO</th>
                <th>CATEGORIA</th>
                <th>MARCA</th>
                <th>STOCK</th>                                
                <th></th>
                <th></th>            
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td>{product.name}</td>
                  <td>${product.pricereg}</td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>{product.countInStock}</td>                                    
                  <td>
                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                      <Button variant='light' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                   
                  </td>
                  <td>
                  <Button variant='danger' className='btn-sm' onClick={() => deleteHandler(product._id)}>
                  <i className='fas fa-trash fs-4'></i>
                    </Button>                   
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          </div>
        )  
      }
    </div>
    </Container>
  )
}

export default ProductListScreen