import React, { useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import swal from 'sweetalert';

function Footer() {

    const [email, setEmail] = useState('')

    const createHandler = async (e) => {
        e.preventDefault();
        await axios.post(`/api/subscribes/create/`, {
            'email': email,
        },
        )
        .catch(error => console.err(error))
        .then(res => console.log(res), swal("Muchas gracias por subscribirte...en breve recibiras mas información")
        )
        
    }

    const clearInput = () => {
        let getValue= document.getElementById("email");
        console.log(getValue)
        if (getValue.value !== "") {
            getValue.value = "";
        }
    }

  return (
    <footer id="tempaltemo_footer" style={{backgroundColor: '#948eb8'}} >
        <div class="container colorfooter">
            <div class="row">

                   <div class="col-md-3 pt-5">
                    <a class="navbar-brand text-success logo h1 align-self-center" href="index.html">
                        {/*<img src={logo}  style={{ width: 170, height: 99 }} resizeMode='contain' alt='fotoP' />*/}
                    </a>
                    <ul class="list-unstyled text-light footer-link-list">
                        <li className='fs-3'>
                            Lorena Madrigal Sosa
                        </li>
                        <li className='text-light fs-3'>
                        <i class="fas fa-map-marker-alt fa-fw"></i>
                            San Juan del Río, Querétaro.
                        </li>
                        <li className='fs-3'>
                            <i class="fa fa-phone fa-fw"></i>
                            <a class="text-decoration-none text-light" href="tel:55-17923352">55-18059959</a>
                        </li>
                        <li className='fs-3'>
                            <i class="fa fa-envelope fa-fw"></i>
                            <a class="text-decoration-none text-light" href="mailto:info@company.com">lmadrigal@unomasunoestres.com</a>
                        </li>
                        <li class="list-inline-item border border-light rounded-circle text-center fs-3">
                            <a class="social-icon facebook text-light" href="https://www.facebook.com/profile.php?id=100089545930787" target="_blank" rel="author">
                                <i class="fab fa-facebook-f fa-md fa-fw"></i>
                            </a>
                        </li>
                        <li class="list-inline-item border border-light rounded-circle text-center fs-3">
                            <a class="social-icon twitter text-light" href="https://www.instagram.com/uno_mas_uno_igual_a_tres/" target="_blank" rel="author" >
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="col-md-4 pt-3 mt-2 fs-3" >
                    <p class="text-light border-bottom pb-3 border-light fs-2">Secciones</p>
                    <ul class="collapse show list-unstyled pl-3 ">
                        <li><a class="text-decoration-none text-light" href='/#/porque'>Porqué</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/cocina'>Lalola Cocina Gourmet</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/natural'>Lalola Natural</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/holistic'>Ser Holístico</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/podcasts'>Podcast</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/foro'>Foro</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/recomend'>Recomendaciones</a></li>
                        
                    </ul>
                </div>

                <div class="col-md-5 pt-3 mt-2 fs-3">
                    <p class="text-light border-bottom pb-3 border-light fs-2">Mas información</p>
                    <ul class="list-unstyled text-light footer-link-list">
                        <li><a class="text-decoration-none text-light" href="#">Inicio</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/bio'>Acerca de mí</a></li>
                        <li><a class="text-decoration-none text-light" href='/#/contact'>Contacto</a></li>
                    </ul>
                </div>

            </div>

            <div class="row text-dark my-2 fs-3">
                <div class="col-12">
                    <form onSubmit={createHandler} className='d-flex justify-content-start align-items-center'>
                        <p style={{fontFamily: "Quicksand"}}>
                            <span className='text-light'>Dirección de Correo:</span> <input type="email" value={email} class='fw-bold' id='email' onChange={(e) => setEmail(e.target.value)} placeholder='Para suscribirse y recibir mas información' size="40" required/>
                            <input type="submit" class='fw-bolder p-2' value="Enviar" style={{fontFamily: "Quicksand"}}/>
                            <input type="button" class='fw-bolder p-2' value= "Limpiar" onClick={clearInput} style={{fontFamily: "Quicksand"}}/>
                        </p>
                    </form>
                </div>
          </div>
        


        </div>

        <div class="w-100 bg-black py-3 fs-3">
            <div class="container">
                <div class="row pt-2">
                    <div class="col-12">
                        <p class="text-left">
                            Copyright &copy; 2023 EVP 
                            | Designed by <a href="https://www.eduardovillalpando.com" target="_blank" class='text-light text-decoration-none'>Eduardo Villalpando Prieto</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </footer>
  )
}

export default Footer