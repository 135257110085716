export const FORO_LIST_REQUEST = 'FORO_LIST_REQUEST'
export const FORO_LIST_SUCCESS = 'FORO_LIST_SUCCESS'
export const FORO_LIST_FAIL = 'FORO_LIST_FAIL'

export const FORO_DETAILS_REQUEST = 'FORO_DETAILS_REQUEST'
export const FORO_DETAILS_SUCCESS = 'FORO_DETAILS_SUCCESS'
export const FORO_DETAILS_FAIL = 'FORO_DETAILS_FAIL'

export const FORO_CREATE_DETAILS_REQUEST = 'FORO_CREATE_DETAILS_REQUEST'
export const FORO_CREATE_DETAILS_SUCCESS = 'FORO_CREATE_DETAILS_SUCCESS'
export const FORO_CREATE_DETAILS_FAIL = 'FORO_CREATE_DETAILS_FAIL'
export const FORO_CREATE_DETAILS_RESET = 'FORO_CREATE_DETAILS_RESET'

export const FORO_DELETE_REQUEST = 'FORO_DELETE_REQUEST'
export const FORO_DELETE_SUCCESS = 'FORO_DELETE_SUCCESS'
export const FORO_DELETE_FAIL = 'FORO_DELETE_FAIL'

export const FORO_CREATE_REQUEST = 'FORO_CREATE_REQUEST'
export const FORO_CREATE_SUCCESS = 'FORO_CREATE_SUCCESS'
export const FORO_CREATE_FAIL = 'FORO_CREATE_FAIL'
export const FORO_CREATE_RESET = 'FORO_CREATE_RESET'

export const FORO_UPDATE_REQUEST = 'FORO_UPDATE_REQUEST'
export const FORO_UPDATE_SUCCESS = 'FORO_UPDATE_SUCCESS'
export const FORO_UPDATE_FAIL = 'FORO_UPDATE_FAIL'
export const FORO_UPDATE_RESET = 'FORO_UPDATE_RESET'
