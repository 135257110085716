import { 
    EDUCATION_LIST_REQUEST, 
    EDUCATION_LIST_SUCCESS, 
    EDUCATION_LIST_FAIL,
    EDUCATION_DETAILS_REQUEST,
    EDUCATION_DETAILS_SUCCESS,
    EDUCATION_DETAILS_FAIL,
    EDUCATION_DELETE_REQUEST, 
    EDUCATION_DELETE_SUCCESS, 
    EDUCATION_DELETE_FAIL,

    EDUCATION_CREATE_REQUEST, 
    EDUCATION_CREATE_SUCCESS, 
    EDUCATION_CREATE_FAIL,
    EDUCATION_CREATE_RESET,
    
    EDUCATION_UPDATE_REQUEST, 
    EDUCATION_UPDATE_SUCCESS, 
    EDUCATION_UPDATE_FAIL,
    EDUCATION_UPDATE_RESET,

} from '../constants/educationConstants'

export const educationListReducer = (state = {educations: []}, action) => {
    switch(action.type){
        case EDUCATION_LIST_REQUEST:
            return {loading: true, educations:[]}
        case EDUCATION_LIST_SUCCESS:
            return {loading: false, educations: action.payload}
        case EDUCATION_LIST_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const educationDetailsReducer = (state = {education: {reviews:[]}}, action) => {
    switch(action.type){
        case EDUCATION_DETAILS_REQUEST:
            return { loading: true, ...state }
        case EDUCATION_DETAILS_SUCCESS:
            return {loading: false, education: action.payload}
        case EDUCATION_DETAILS_FAIL:
            return {loading: false, error: action.payload}
        default:
            return state    
    }
}

export const educationCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case EDUCATION_CREATE_REQUEST:
            return { loading: true }
    
        case EDUCATION_CREATE_SUCCESS:
            return { loading: false, success: true, education: action.payload }
    
        case EDUCATION_CREATE_FAIL:
            return { loading: false, error: action.payload }
    
        case EDUCATION_CREATE_RESET:
                return {}            
    
        default:
            return state
    }
    }
    
    
    export const educationDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case EDUCATION_DELETE_REQUEST:
            return { loading: true }
    
        case EDUCATION_DELETE_SUCCESS:
            return { loading: false, success: true }
    
        case EDUCATION_DELETE_FAIL:
            return { loading: false, error: action.payload }
    
        default:
            return state
    }
    }
    
    
    export const educationUpdateReducer = (state = {education: {}}, action) => {
    switch (action.type) {
        case EDUCATION_UPDATE_REQUEST:
            return { loading: true }
    
        case EDUCATION_UPDATE_SUCCESS:
            return { loading: false, success: true, education: action.payload }
    
        case EDUCATION_UPDATE_FAIL:
            return { loading: false, error: action.payload }
    
        case EDUCATION_UPDATE_RESET:
                return { education: {} }            
    
        default:
            return state
        }
    }
