import React, {useState, useEffect} from 'react'
import image5 from '../assets/images/Lore23032023-1.jpg'
import image6 from '../assets/images/LOGOSOKLORENA.png'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form, Container } from 'react-bootstrap';
import Rating from '../components/Rating';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import SearchBoxPodcast from '../components/SearchBoxPodcast';
import { listPodcasts } from '../actions/podcastActions'
import Loader from '../components/Loader';
import Message from '../components/Message';
import Podcast from '../components/Podcast';
import Select from "react-select";

function PodcastScreen() {

  const [toggle, setToggle] = useState(null);
  const dispatch = useDispatch()

  const podcastList = useSelector(state => state.podcastList)
  const {error, loading, podcasts} = podcastList    

  const { id } = useParams();
  let navigate = useNavigate();
  let keyword = useLocation().search

  const [selected, setSelected] = useState("⬇️ Select a fruit ⬇️");

  
  let handleToggle=(id)=>{
      if(toggle===id){
          setToggle(null);
          return false
      }
     setToggle(id)
     
  }
  
  useEffect(() => {
    dispatch(listPodcasts(keyword))

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
  }, [dispatch, keyword])

  /*const submitHandler = (e) => {
      e.preventDefault()
      dispatch(createProductReview(id, {
          rating,
          comment
      }))
  }*/ 

  const opciones = podcastList.podcasts.map((podcast) => {
    return podcast.name
  })


  let handleChange = (e) => {
    console.log(e.target.value)
    navigate(`/podcasts/${e.target.value}`)
  }

  return (
    <div class='container'>
      <section className='p-1 text-center text-sm-start'>
          <Row className='mt-3'>
            <Col lg={4} className='d-flex justify-content-center align-items-center mt-2 mb-2'>
              <p className='fw-bold font-italic fst-italic' style={{color: '#596488', fontSize: 40}}>Pódcast</p>
            </Col>
            <Col lg={4} className="d-flex justify-content-center align-items-center mt-2">
              <SearchBoxPodcast />                  
            </Col>
            <Col md={4} className="p-1 mb-4 d-flex justify-content-center align-items-center mt-2">
                  <img class="img-fluid"
                  src={image5} style={{width: 230}}/>
            </Col>
        </Row>
        <Row className='my-4'>
            <Col md={3} className='d-flex justify-content-start align-items-center fs-3 fw-bolder'>
                <p>Seleccione la opción deseada</p>
            </Col>
            <Col md={7} className='d-flex justify-content-start aligm-item-center fs-3 fw-bolder'>
                <select onChange={handleChange} className='fs-3 fw-bold p-1'> 
                  <option value="⬇️ Select un podcast ⬇️"> -- Selecciona un podcast -- </option>
                    {/* Mapping through each fruit object in our fruits array
                      and returning an option element with the appropriate attributes / values.
                    */}
                  {podcasts.map((podcast) => <option className='fs-3 fw-bold' value={podcast._id} >{podcast.name}</option>)}
              </select>
            </Col>
            <Col md={2}>

            </Col>
        </Row>
          <hr className='text-info mt-0'/>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center'>
              <p className='colortextabout fs-3 fw-bolder' style={{lineHeight: '1.5'}}>En este espacio te iré llevando por mi viaje hasta el día de hoy.
              Te contare cada una de mis caídas, de mis llantos, mis miedos y más grandes temores, los aprendizajes, las tomas de decisiones que cambiaron radicalmente mi vida.
              <br />
              <br />
              Como he logrado “vencer” cada uno de ellos.
              Hablare de mis maestros, recordando que maestro es todo aquel que te enseña algo para tu vida; puede ser un amigo, tus padres, un vecino e inclusive un desconocido que te topaste en la fila del super.
              <br />
              <br />
              Podrás escucharlo o leerlo; a veces el tener una voz que escuchar hace que sientas lo mismo que la otra persona está sintiendo.
              </p>
            </Col>
          </Row>

          <div className='section1 container'>
              {loading ? 
                <Loader /> 
                : error ? <Message variant='danger'>{error}</Message>
                :
                <div>
                  <Row>
                  {podcasts.length === 0 ? <p className='fs-4 fw-bold'><b>No existen Pódcast actualmente</b></p> : ''}
                  {podcasts.map(podcast => (
                     <Col key={podcast._id} lg={4} className='my-2'>
                        <Podcast handleToggle={handleToggle} toggle={toggle} podcast={podcast} />
                     </Col>                        
                ))}
                  </Row>              
                </div>
              }
          </div>
          <Row className='container-fluid mt-1'>
          <Col md={12} className="p-1 mb-4 d-flex justify-content-end align-items-center">
          <Link to='/bio' className='text-decoration-none text-success'>
            <img class="img-fluid d-block"
            src={image6} style={{width: 160}}/>
          </Link>                                   
          </Col>
        </Row>
          </section>

    </div>
  )
}

export default PodcastScreen