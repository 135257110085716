import React, { useEffect } from 'react';
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Moment from 'react-moment';
import { listProfiles } from '../actions/profileActions'


function Podcast({ podcast, handleToggle, toggle }) {

  const id = podcast._id
  const dispatch = useDispatch()
   
  let keyword = useLocation().search

  useEffect(() => {
    dispatch(listProfiles(keyword))

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
}, [dispatch, keyword])

    return (
    <Card key={podcast._id} className='border border-success border-1 rounded mb-2 h-100'>
      <Card.Header 
        onClick={()=>handleToggle(podcast._id)} style={{cursor:"pointer"}}
      > 
        <Link to={`/podcasts/${podcast._id}`}>
          <Card.Img src={podcast.image} className="card-img-top my-2 img-fluid" alt="Imagen" />
        </Link>
        <p className='fs-3'>{(podcast._id===toggle)?'-':'+'} <strong>{podcast.name}</strong> </p>

      </Card.Header>
      {(podcast._id===toggle)?
        <Card.Body>
          <p className='text-success fs-3'><b>{podcast.comment}</b></p>
        </Card.Body> 
        : ''}
      <Card.Footer>
      <div class="d-flex flex-row bd-highlight justify-content-center align-items-center">
      <div class="p-2 bd-highlight align-items-center "><p className='text-center fs-4'><b>Creado por: {podcast.author}</b></p></div>

      <div class="p-2 bd-highlight align-items-center"><p className='text-center fs-4'><b><Moment format="DD/MM/YYYY hh:mm:ss">{podcast.createdAt}</Moment></b></p></div>
    </div>
      </Card.Footer>  
    </Card>
) 
}

export default Podcast