import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import image1 from '../assets/images/LALOLACOCINA.png'
import image2 from '../assets/images/LOGOLALOLANATURAL.png'
import image3 from '../assets/images/SERHOLISTICO.png'
import image5 from '../assets/images/LOGOSOKLORENA.png'
import image6 from '../assets/images/yoga5_315x295.jpg'
import image7 from '../assets/images/ACUPUNTURA_315x295.jpg'
import image8 from '../assets/images/BIOMAGNETISMO_315x295.jpg' 


function HomeScreen() {

  return (
    <div>
        <section id="features" className='mb-6 mx-2' style={{backgroundColor: '#e5e8f8'}}>
            <header class="header">
                    <div class="header__logo-box">
                        <img src={image5} alt="Logo" class="header__logo" />
                    </div>

                    <div class="header__text-box">
                        <h1 class="heading-primary">
                            <span class="heading-primary--main">Uno mas Uno es Tres</span>
                            <span class="heading-primary--sub mt-4">Bienvenidos</span>
                        </h1>

                        <a href="/#/porque" class="btn btn--white btn--animated text-dark fw-bolder">
                            Descubre porqué UNO MAS UNO ES TRES
                        </a>
                        <p className='text-light fs-4 textPos' style={{width: 120}}>Mirador 4 palos</p>
                    </div>
        
            </header>
       </section>
        <section id="features" className='mb-5 mx-2 ' style={{backgroundColor: '#e5e8f8'}}>
                        <div class="container-fluid">
                            <div class="row">
                            <div class="col-md text-center mt-5 mb-5">
                                <span className='fw-bold textam' style={{color: '#421d74'}}>Secciones</span>
                            </div>      
                            </div>
                        <div class="row">
                        <div class="col-md text-center">
                                <Link to='/cocina' className='text-decoration-none text-success'>
                                    <img className="fluid m-0"
                                    src={image1} />
                                </Link>
                                <p className='fs-4 text-center fw-bolder mt-3' style={{lineHeight: '1.5', color: '#421d74'}}><b>¿A quien no le gusta un buen platillo en su mesa? <br /> 
                                Aquí te llevare por el mundo de la gastronomía; hablaremos un poco de historia, ingredientes, técnicas y por su puesto recetas.
                                Cada uno de nosotros tenemos un platillo preferido y que somos expertos en hacerlo.
                                <br /> ! Buen provecho !</b>
                                </p>
                        </div>
                        <div class="col-md text-center">
                        <Link to='/holistic' className='text-decoration-none text-success'>
                            <img class="img-fluid "
                            src={image3} />
                            </Link>
                            <p className='fs-4 text-center fw-bolder' style={{lineHeight: '1.5', color: '#421d74'}}>Biomagnetismo, acupuntura, yoga y el enfoque del SER, es lo que yo hago todos los días con las personas que se acercan a consultarme.
                            <br />
                            El enfoque holístico se relaciona con el funcionamiento del ser humano de una manera integral, conociendo cada aspecto físico, emocional, mental y espiritual que forman parte de cada uno de nosotros. <br />
                            ¿Y la pregunta del millón, por donde empiezo?
                            </p>
                        </div>
                        <div class="col-md text-center">
                            <Link to='/natural' className='text-decoration-none text-success'>
                            <img class="img-fluid"
                            src={image2} />
                            </Link>
                            <p className='fs-4 text-center fw-bolder mt-3' style={{lineHeight: '1.5', color: '#421d74'}}><b>
                            ¿Que piensas cuando escuchas la palabra NATURAL? <br /> Hay tantos significados y connotaciones, he elegido la que más me identifica “De la naturaleza, relacionado con ella o producido por ella sin la intervención del hombre. <br /> El agua, el aire, la tierra, los minerales, las fuentes de energía, la plantas, la vida animal y el espacio”
                            Lo más importante es encontrar el equilibrio entre lo Natural y la modernidad. <br />
                            ! Vive y disfruta ! 
                            </b>
                            </p> 
                        </div>
                        </div>
                    </div>
            </section>
            <section className='mb-5'>
                <div class="container-fluid">
                    <div class="row my-4">
                        <div class="col-md text-center mt-5 mb-5">
                            <span className='fw-bold textam' style={{color: '#421d74'}}>Servicios que ofrezco</span>
                        </div>      
                    </div>
                </div>
            
            <div class="container-fluid">
            <div class="row">
                <div class="col-md text-center mb-2">
                    <img class="img-fluid"
                    src={image8}/>
                    <p className='fs-3 my-3'><b>Biomagnetismo</b></p>
                    <p className='fw-bold fs-4' style={{lineHeight: '1.5', color: '#421d74'}}>
                    El biomagnetismo es una terapia que trabaja con imanes y regula el PH de tu cuerpo para
                    estabilizarlo. Es una terapia que no se queda únicamente en el plano físico, trabaja también la
                    parte emocional y ayuda a mejorar el estado de ánimo y a eliminar estrés.
                    
                    Junto con la acupuntura son un gran aliado en tu salud. Pregunta por sesiones y costos.                    
                    </p>
                    <Link to='/contact' className='text-decoration-none text-success'>
                        <a href="#" class="btn btn-success fs-5 fw-bolder" style={{fontFamily: 'Quicksand'}}>Contáctame</a>
                    </Link>
                </div>
                <div class="col-md text-center mb-2">
                    <img class="img-fluid"
                    src={image6}/>
                    <p className='fs-4 my-3'><b>Yoga</b></p>
                    <p className='fw-bold fs-4' style={{lineHeight: '1.5', color: '#421d74'}}>El yoga es una práctica que conecta el cuerpo, la respiración y la mente. Esta práctica utiliza posturas físicas, ejercicios de respiración y meditación para mejorar la salud general. <br /> Pregunta por las clases individuales o en grupo.</p>
                    <Link to='/contact' className='text-decoration-none text-success'>
                        <a href="#" class="btn btn-success fs-5 fw-bolder" style={{fontFamily: 'Quicksand'}}>Contáctame</a>
                    </Link>                    
                </div>
                <div class="col-md text-center mb-2">
                    <img class="img-fluid"
                    src={image7}/>
                    <p className='fs-4 my-3'><b>Acupuntura</b></p>
                    <p className='fw-bold fs-4' style={{lineHeight: '1.5', color: '#421d74'}}>La acupuntura es la aplicación de agujas, calor, presión y otros tratamientos en puntos estratégicos del cuerpo.
                    La acupuntura se basa en el qi (la energía vital) fluye por el organismo a través de canales que se llaman meridianos. <br /> El qi afecta tanto el estado espiritual, emocional y mental como la salud física de una persona.
                   
                    Lleva un tratamiento alternativo a tu cuerpo. Pregunta por las sesiones y costos.
                    </p>
                    <Link to='/contact' className='text-decoration-none text-success'>
                        <a href="#" class="btn btn-success fs-5 fw-bolder" style={{fontFamily: 'Quicksand'}}>Contáctame</a>
                    </Link>                    
                </div>
            </div>
            </div>

        </section>
        <div class="clear"></div>
        <section className='container-fluid' style={{backgroundColor: '#e5e8f8'}}>
            <Row >
                <Col md={12} className='d-flex justify-content-center align-items-center mt-5 mb-3'>
                    <p style={{color: '#421d74'}} className='fw-bold mt-1 textam text-center'>Te gustaría leer o escuchar algo mas?</p>
                 </Col>
            </Row>
            <Row>
                <Col lg={12} className='d-flex justify-content-center align-items-center'>
                    <Link to='/contact' className='text-decoration-none text-success mt-4 mb-5'>
                        <a href="#" class="btn btn-success fs-5 fw-bolder" style={{fontFamily: 'Quicksand'}}>Dejame el tema de tu interes</a>            
                    </Link>
                </Col>
            </Row>
 
        </section>

</div>

  )
}

export default HomeScreen