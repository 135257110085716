import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, ListGroup, Button, Card, Form } from 'react-bootstrap';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { listServices } from '../actions/serviceActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import image5 from '../assets/images/LOGOSOKLORENA.png'
import Service from '../components/Service';
import SearchBoxService from '../components/SearchBoxService';

function ServiceArtScreen() {

  const dispatch = useDispatch()

  let navigate = useNavigate();
  let keyword = useLocation().search

  const serviceList = useSelector(state => state.serviceList)
  const { error, loading, services } = serviceList

  useEffect(() => {

     dispatch(listServices(keyword))

     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     
  }, [dispatch, keyword])

  let handleChange = (e) => {
    console.log(e.target.value)
    navigate(`/service/${e.target.value}`)
  }

  return (
    <div class='container'>
      <section className='p-1 text-center text-sm-start'>
          <Row className='align-items-center justify-content-around text-center mt-2'>
            <Col lg={4} className='d-flex justify-content-start align-items-center mt-1'>
              <p className='fs-1 fw-bolder fst-italic' style={{color: '#596488'}}>Artículos Servicios</p>
            </Col>
          <Col md={4} className="p-1 mb-2 d-flex justify-content-around align-items-center">
              <SearchBoxService />
          </Col>
          <Col md={4} className="p-1 mb-2 d-flex justify-content-end align-items-center">
            <Link to='/bio' className='text-decoration-none text-success'>
                <img class="img-fluid d-block"
                src={image5} style={{width: 160}}/>
            </Link>
          </Col>          
        </Row>
        <Row className='my-4'>
            <Col md={3} className='d-flex justify-content-start align-items-center fs-3 fw-bolder'>
                <p>Seleccione la opción deseada</p>
            </Col>
            <Col md={7} className='d-flex justify-content-start aligm-item-center fs-3 fw-bolder'>
                <select onChange={handleChange} className='fs-3 fw-bold p-1'> 
                  <option value="⬇️ Select un podcast ⬇️"> -- Selecciona un Artículo de Servicio -- </option>
                    {/* Mapping through each fruit object in our fruits array
                      and returning an option element with the appropriate attributes / values.
                    */}
                  {services.map((service) => <option className='fs-3 fw-bold' value={service._id} >{service.name}</option>)}
              </select>
            </Col>
            <Col md={2}>

            </Col>
        </Row>
          <hr className='text-info'/>
          <Row>
            <Col lg={12} className='d-flex justify-content-center align-items-center colorspan'>
              <p className='colortextabout text-initial fs-3 fw-bolder' style={{lineHeight: '1.5', color: '#421d74'}}>Un articulo es un texto escrito que tiene identidad propia.
              </p>
            </Col>
          </Row>
          {
            loading ? <Loader /> 
            : error ? <Message variant='danger'>{error}</Message>
              : <div>
              <Row className='mx-1'>
                {services.length === 0 ? <p className='fs-2 fw-bolder'><b>No existen Artículos actualmente</b></p> : ''}
                  {services.map(service => (
                    <Col key={service._id} md={12}>
                        <Service service={service} />
                    </Col>                        
                ))}
              </Row>
              </div>
        }
          </section>
    </div>
  )
}

export default ServiceArtScreen